import { FC } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Title } from "common/components/Title/Title";
import { SvgCreditCard } from "assets/icons/SvgCreditCard";
import { SvgCash } from "assets/icons/SvgCash";
import { PaymentMethod } from "common/models/types/PaymentMethod";
import { setPaymentMethod } from "features/ordering/orderingSlice";
import { SubTitle } from "common/components/Title/SubTitle";

export const PaymentMethodChoose: FC = () => {
  const dispatch = useAppDispatch();

  const paymentMethod = useAppSelector((state) => state.ordering.paymentMethod);
  const useBonuses = useAppSelector((state) => state.ordering.useBonuses);
  const price = useAppSelector((state) => state.ordering.price);
  const bonuses = useAppSelector((state) => state.user.current?.bonuses);

  const handleChangePaymentMethod = (method: PaymentMethod) => {
    dispatch(setPaymentMethod(method));
  };

  const isPayByCard = paymentMethod === "card";

  return (
    <div className="pointer-events-auto h-fit max-h-[100%] overflow-y-auto rounded bg-white p-[15px]">
      <div className="h-full overflow-y-scroll">
        <Title className="mb-[15px] text-center">Сменить способ оплаты</Title>
        <div className="mb-[10px] grid grid-cols-2 gap-[15px] text-lightGray">
          <button
            className={`relative flex h-[135px] flex-col items-center justify-center rounded border transition 
              ${
                isPayByCard
                  ? "border-primary bg-lightWhiteGray text-black"
                  : "border-lightGray"
              }`}
            onClick={() => handleChangePaymentMethod("card")}
          >
            <div
              className={`absolute top-[-7px] left-[50%] translate-x-[-50%] whitespace-nowrap rounded-[4px] border border-primary bg-lightWhiteGray px-[10px] text-xxs text-primary transition
                ${isPayByCard ? "opacity-100" : "opacity-0"}`}
            >
              + 20 баллов
            </div>
            <SvgCreditCard className="mb-[10px] h-[32px] w-[32px]" />
            <span className="text-center text-md font-medium transition">
              Оплата картой
            </span>
          </button>
          <button
            className={`flex h-[135px] flex-col items-center justify-center rounded border transition 
              ${
                !isPayByCard
                  ? "border-primary bg-lightWhiteGray text-black"
                  : "border-lightGray"
              }`}
            onClick={() => handleChangePaymentMethod("cash")}
          >
            <SvgCash className="mb-[10px] h-[32px] w-[32px]" />
            <span className="text-center text-md font-medium transition">
              Оплата наличными
            </span>
          </button>
        </div>
        <div className="grid gap-[10px]">
          {isPayByCard && (
            <>
              <SubTitle>Оплачивая картой вы получаете</SubTitle>
              <p className="mb-[10px] text-xs text-lightGray">
                Приятно, граждане, наблюдать, как представители современных
                социальных резервов призывают нас к новым свершениям, которые, в
                свою очередь, должны быть превращены в посмешище, хотя само их
                существование приносит несомненную пользу обществу.
              </p>
              <div className="grid gap-[10px] rounded bg-[rgba(255,163,33,0.2)] p-[15px]">
                <Title className="text-primary">+ 20 баллов</Title>
                <p className="mb-[10px] text-xs text-lightGray">
                  Приятно, граждане, наблюдать, как представители современных
                  социальных резервов призывают нас к новым свершениям, которые,
                  в свою очередь
                </p>
              </div>
            </>
          )}
          <p className="mb-[10px] text-xs text-lightGray">
            Если у вас есть сомнения с выбором техники или вы не можете
            определиться с параметрами заказа создайте заказ и с вами свяжется
            оператор
          </p>
        </div>
        {!!price && (
          <div className="mb-[10px] grid gap-[15px]">
            <div className="flex items-center justify-between">
              <div className="font-bold text-gray">Общая стоимость</div>
              <div className="text-md font-bold text-primary">{`${price} руб.`}</div>
            </div>
            {useBonuses && !!bonuses && (
              <>
                <div className="flex items-center justify-between">
                  <div className="font-bold text-gray">Бонусов</div>
                  <div className="text-md font-bold text-primary">
                  {`- ${bonuses} руб.`}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="font-bold text-gray">Итоговая стоимость</div>
                  <div className="text-md font-bold text-primary">
                  {`${price - bonuses} руб.`}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
