import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Container } from "../../../common/components/Container/Container";
import { OrdersFilter } from "../../../common/components/Filter/OrdersFilter";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { OrderList } from "../../../common/components/Order/OrderList";
import { Title } from "../../../common/components/Title/Title";
import { useGetOrderByIdQuery } from "../../../services/orderApi";
import {
  orderFiltersSelector,
  resetFilter,
  setActiveOrderId,
  setFilterPeriod,
  setFilterVehicles,
  setFilterTimeAt,
  setFilterTimeTo,
  setIsActiveOrders,
} from "./ordersSlice";
import { OrderInfo } from "./OrderInfo";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";
import { Tabs } from "common/components/Tabs/Tabs";
import { FilterButton } from "common/components/Button/FilterButton";
import { IOrder } from "common/models/order/IOrder";

export const OrdersPage: FC = withErrorBoundary(() => {
  const activeOrderId = useAppSelector(
    (state) => state.implementerCompanyManager.orders.activeOrderId
  );
  const isActiveOrders = useAppSelector(
    (state) => state.implementerCompanyManager.orders.isActiveOrders
  );
  const orderFilter = useAppSelector(
    (state) => state.implementerCompanyManager.orders.filters
  );

  const companyId = useAppSelector(
    (state) => state.user.current?.account.company?.id
  );

  const {
    data: order,
    isLoading: isOrderLoading,
    isFetching: isOrderFetching,
    error: orderError,
  } = useGetOrderByIdQuery(
    { id: activeOrderId || -1 },
    {
      skip: !activeOrderId,
    }
  );

  const dispatch = useAppDispatch();

  const handleOrderStatusChange = (type: boolean) => {
    dispatch(setIsActiveOrders(type));
  };

  const handleSetActiveOrder = (order: IOrder) => {
    dispatch(setActiveOrderId(order.id));
  };

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  return (
    <Container>
      {isFilterOpen && (
        <OrdersFilter
          isWide
          setIsFilterOpen={setIsFilterOpen}
          handleFilterVehiclesChange={setFilterVehicles}
          handleFilterReset={resetFilter}
          handlePeriodSet={setFilterPeriod}
          handleTimeAtSet={setFilterTimeAt}
          handleTimeToSet={setFilterTimeTo}
          stateSelector={orderFiltersSelector}
        />
      )}
      <div className="mr-[16px] grid grid-rows-[45px,auto] overflow-hidden">
        <Title className="mb-[12px]">Заказы</Title>

        <OrderList
          filter={{
            isActive: +isActiveOrders,
            statusId: orderFilter.period,
            vehicleCategoryId: orderFilter.vehicles,
            dateFrom: orderFilter.timeAt,
            dateTo: orderFilter.timeTo,
            executorCompanyId: companyId,
          }}
          withAddresses
          activeOrderId={activeOrderId}
          onItemClick={handleSetActiveOrder}
        />
      </div>
      <div className="grid grid-rows-[30px,auto] gap-[10px] overflow-hidden">
        <div className="flex justify-between">
          <Tabs
            className="mr-[10px] h-full w-[340px]"
            tabs={[
              { value: true, label: "Активные" },
              { value: false, label: "Завершённые" },
            ]}
            value={isActiveOrders}
            onTabClick={(value) => handleOrderStatusChange(!!value)}
          />
          <FilterButton onClick={() => setIsFilterOpen((prev) => !prev)} />
        </div>
        <QueryLayout
          isLoading={isOrderLoading}
          isFetching={isOrderFetching}
          error={orderError}
        >
          {order && activeOrderId ? (
            <OrderInfo order={order} />
          ) : (
            <InformationBlock />
          )}
        </QueryLayout>
      </div>
    </Container>
  );
});
