import { FC, memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { Title } from "../../../common/components/Title/Title";
import { MODAL_TYPES } from "../../../common/utils/consts";
import { InvoiceTabs } from "./InvoiceTabs";
import { InvoiceDetails } from "../../../common/components/invoice/InvoiceDetails";
import { CompanyEdit } from "../../../common/components/Company/CompanyEdit";
import { UserInformation } from "../../../common/components/User/UserInformation";
import { setAvtiveInvoiceId } from "./invoiceSlice";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";
import {
  useAcceptInvoiceMutation,
  useRejectInvoiceMutation,
} from "services/invoicesApi";
import { SvgSuccess } from "assets/icons/SvgSuccess";

export const InvoicePage: FC = withErrorBoundary(
  memo(() => {
    const activeInvoiceId = useAppSelector(
      (state) => state.platformManager.invoice.currentInvoice?.invoiceId
    );
    const companyId = useAppSelector(
      (state) => state.platformManager.invoice.currentInvoice?.companyId
    );
    const accountId = useAppSelector(
      (state) => state.platformManager.invoice.currentInvoice?.accountId
    );

    const dispatch = useAppDispatch();

    const [accept, { isLoading: isAccepting, isSuccess: isAcceptSuccess }] =
      useAcceptInvoiceMutation();
    const [reject, { isLoading: isRejecting, isSuccess: isRejectSuccess }] =
      useRejectInvoiceMutation();

    const handleCloseInvoice = () => {
      dispatch(setAvtiveInvoiceId(undefined));
    };

    const { showModal } = useGlobalModalContext();

    const showConfirmApproveModal = (id: number) => {
      showModal(MODAL_TYPES.CONFIRM_MODAL, {
        title: "Одобрить танзакцию",
        subTitle: "Транзакция будет выполнена",
        onConfirm: () => accept({ invoiceId: id }),
      });
    };

    const showConfirmRejectModal = (id: number) => {
      showModal(MODAL_TYPES.CONFIRM_MODAL, {
        title: "Отклонить танзакцию",
        subTitle: "Транхакция будет отклонена",
        onConfirm: () => reject({ invoiceId: id }),
      });
    };

    useEffect(() => {
      if (isAcceptSuccess) {
        handleCloseInvoice();
      }
      if (isRejectSuccess) {
        handleCloseInvoice();
      }
    }, [isAcceptSuccess, isRejectSuccess]);

    return (
      <>
        <div className="grid grid-rows-[35px,40px,1fr] gap-[10px] overflow-y-hidden">
          <InvoiceTabs />
        </div>
        {isAcceptSuccess && !activeInvoiceId && (
          <div className="col-span-2 grid grid-rows-[1fr,45px]">
            <div className="flex h-[200px] flex-col items-center justify-center text-primary">
              <SvgSuccess className="mb-[15px] h-[50px] w-[50px]" />
              <Title className="text-center">Успешно одобрено!</Title>
            </div>
          </div>
        )}
        {isRejectSuccess && !activeInvoiceId && (
          <div className="col-span-2 grid  grid-rows-[1fr,45px]">
            <div className="flex h-[200px] flex-col items-center justify-center text-primary">
              <SvgSuccess className="mb-[15px] h-[50px] w-[50px]" />
              <Title className="text-center">Успешно отклонено!</Title>
            </div>
          </div>
        )}
        {activeInvoiceId && (
          <>
            <div className="mb-[15px] grid grid-rows-[1fr,120px] overflow-hidden">
              <div className="overflow-y-scroll">
                <InvoiceDetails invoiceId={activeInvoiceId} />
                {companyId && <CompanyEdit readOnly companyId={companyId} />}
              </div>
              <div className="mt-[15px] grid gap-[15px]">
                <Button
                  className="w-full"
                  mode="darkBorder"
                  disabled={isAccepting || isRejecting}
                  onClick={() => showConfirmApproveModal(activeInvoiceId)}
                >
                  Одобрить
                </Button>
                <Button
                  className="w-full"
                  disabled={isAccepting || isRejecting}
                  onClick={() => showConfirmRejectModal(activeInvoiceId)}
                >
                  Отказать
                </Button>
              </div>
            </div>
            <div className="mb-[15px] grid grid-rows-[40px,90px,1fr,45px] overflow-hidden">
              <Title className="mb-[10px] flex items-center">
                Информация о пользователе
              </Title>
              {accountId && <UserInformation userId={accountId} />}
              <Button className="h-[45px] w-full" onClick={handleCloseInvoice}>
                Закрыть тикет
              </Button>
            </div>
          </>
        )}
        {!isAcceptSuccess && !isRejectSuccess && !activeInvoiceId && (
          <InformationBlock className="col-span-2 w-full" />
        )}
      </>
    );
  })
);
