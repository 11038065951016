import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { App } from "./app/App";
import { BrowserRouter } from "react-router-dom";
import { GlobalModal } from "./common/components/Modal/GlobalModal";
import ErrorBoundary from "common/components/ErrorBoundary/ErrorBoundary";

import "./index.css";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <GlobalModal>
            <App />
          </GlobalModal>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);
