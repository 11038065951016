import { FC, useState, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { SvgPlus } from "../../../assets/icons/SvgPlus";
import { SvgQuestionCircle } from "../../../assets/icons/SvgQuestionCircle";
import { Input } from "../../../common/components/Input/Input";
import { CustomSelect } from "../../../common/components/Select/CustomSelect";
import { Title } from "../../../common/components/Title/Title";
import { VehicleFormValues } from "../../models/forms/VehicleFormValues";
import { useGetSearchRadQuery } from "../../../services/appApi";
import { IVehicleMainCharacteristicsProps } from "common/models/components/vehicle/IVehicleMainCharacteristicsProps";
import { useSearchAddressesMutation } from "services/orderApi";
import { getNoOptionsMessage } from "common/utils/helpers";
import { SvgTrash } from "assets/icons/SvgTrash";
import { useGetKmuModelsQuery } from "services/vehicle/kmuApi";
import {
  useGetChassisModelsQuery,
  useGetChassisMarksQuery,
} from "services/vehicle/chassisApi";

export const VehicleMainCharacteristics: FC<
  IVehicleMainCharacteristicsProps
> = () => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<VehicleFormValues>();

  const currentChassisMark = useWatch({
    control,
    name: "main.chassisMark",
  });
  const currentSts = useWatch({
    control,
    name: "main.vrcPhoto",
  });
  const currentCategoryId = useWatch({
    control,
    name: "category",
  });
  const currentLocation = useWatch({
    control,
    name: "main.location",
  });

  const [isHelpPopupOpen, setIsHelpPopupOpen] = useState<boolean>(false);

  const { data: searchRad } = useGetSearchRadQuery();

  const { data: chassisMarks, isFetching: isChasisMarksFetching } =
    useGetChassisMarksQuery();

  const { data: kmuModels, isFetching: isKmuModelsFetching } =
    useGetKmuModelsQuery(
      {
        vehicleCategoryId: currentCategoryId || -1,
      },
      {
        skip: !currentCategoryId,
      }
    );
  const {
    data: chassisModels,
    isFetching: isChasisModelsFetching,
    isLoading: isChasisModelsLoading,
  } = useGetChassisModelsQuery([currentChassisMark?.value], {
    skip: !currentChassisMark?.value,
  });

  const [searchAddress, { data: addresses, isLoading: isAddressesLoading }] =
    useSearchAddressesMutation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentLocation?.length >= 3) {
        searchAddress({ query: currentLocation });
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [currentLocation]);

  const handleCloseHelpPopup = () => setIsHelpPopupOpen(false);
  const handleOpenHelpPopup = () => setIsHelpPopupOpen(true);

  const chassisMarksOptions = chassisMarks?.map((rad) => ({
    value: rad.id,
    label: rad.name,
  }));
  const kmuMarksOptions = kmuModels?.map((rad) => ({
    value: rad.id,
    label: `${rad.kmu_brand.name} ${rad.name}`,
  }));
  const chassisModelsOptions = chassisModels?.map((rad) => ({
    value: rad.id,
    label: rad.name,
  }));
  const searchRadOptions = searchRad?.map((rad) => ({
    value: rad.id,
    label: rad.name,
  }));
  const addressesOptions = addresses?.map((address) => ({
    label: address.name,
    value: address.name,
  }));

  return (
    <div>
      <div className="mb-[30px] grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-x-[15px] gap-y-[25px]">
        <div>
          <Controller
            control={control}
            name="main.chassisMark.value"
            render={(props) => {
              return (
                <CustomSelect
                  className="mb-[25px] w-full"
                  label="Марка шасси *"
                  isSearchable={false}
                  invalid={!!errors.main?.chassisMark}
                  invalidMessage={errors.main?.chassisMark?.value?.message}
                  reference={props.field.ref}
                  options={chassisMarksOptions}
                  noOptionsMessage={() =>
                    `${
                      isChasisMarksFetching
                        ? "Загрузка..."
                        : "Нет подходящих опций"
                    }`
                  }
                  placeholder="Выберите опцию..."
                  value={chassisMarksOptions?.find(
                    (c) => c.value === props.field.value
                  )}
                  onChange={(val) => {
                    props.field.onChange(val.value);
                    setValue("main.chassisModel", undefined);
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="main.kmuModel.value"
            render={(props) => {
              return (
                <CustomSelect
                  className="mb-[25px] w-full"
                  options={kmuMarksOptions}
                  isSearchable={false}
                  noOptionsMessage={() =>
                    `${
                      isKmuModelsFetching
                        ? "Загрузка..."
                        : "Нет подходящих опций"
                    }`
                  }
                  label="Марка КМУ *"
                  invalid={!!errors.main?.kmuModel}
                  invalidMessage={errors.main?.kmuModel?.value?.message}
                  reference={props.field.ref}
                  placeholder="Выберите опцию..."
                  value={kmuMarksOptions?.find(
                    (c) => c.value === props.field.value
                  )}
                  onChange={(val) => {
                    props.field.onChange(val.value);
                    const currentkmu = kmuModels?.find(
                      (kmu) => kmu.id === val.value
                    );
                    if (currentkmu) {
                      setValue(
                        "specifications.boomCapacity",
                        currentkmu.arrow_carrying_capacity
                      );
                      setValue(
                        "specifications.boomLength",
                        currentkmu.arrow_length
                      );
                      setValue(
                        "specifications.sideLoadCapacity",
                        currentkmu.lifting_height
                      );
                    }
                  }}
                />
              );
            }}
          />
          <Input
            {...register("main.governmentNumber")}
            invalid={!!errors.main?.governmentNumber}
            invalidMessage={errors.main?.governmentNumber?.message}
            className="w-full"
            label="Гос номер *"
          />
        </div>
        <div className="">
          <Controller
            control={control}
            name="main.chassisModel.value"
            render={(props) => {
              return (
                <CustomSelect
                  className="mb-[25px] w-full"
                  isSearchable={false}
                  options={chassisModelsOptions}
                  noOptionsMessage={(text) =>
                    `${
                      isChasisModelsFetching
                        ? "Загрузка..."
                        : "Нет подходящих опций"
                    }`
                  }
                  label="Модель шасси *"
                  invalid={!!errors.main?.chassisModel}
                  invalidMessage={errors.main?.chassisModel?.value?.message}
                  reference={props.field.ref}
                  isDisabled={
                    !currentChassisMark ||
                    isChasisMarksFetching ||
                    isChasisModelsLoading
                  }
                  placeholder={
                    !currentChassisMark
                      ? "Введите марку шасси"
                      : "Выберите опцию..."
                  }
                  value={chassisModelsOptions?.find(
                    (c) => c.value === props.field.value
                  )}
                  onChange={(val) => {
                    props.field.onChange(val.value);
                  }}
                />
              );
            }}
          />
          <Input
            {...register("main.vrcNumber")}
            invalid={!!errors.main?.vrcNumber}
            invalidMessage={errors.main?.vrcNumber?.message}
            className="w-full"
            type="number"
            label="Номер СТС *"
          />
        </div>
      </div>
      <div className="mb-[30px]">
        <span
          className={`mb-[12px] block ${
            !!errors.main?.vrcPhoto ? "text-red" : "text-gray"
          } text-sm font-semibold`}
        >
          Фото СТС *
        </span>
        <span className={`mb-[12px] block text-sm text-lightGray`}>
          Перетяните фото для загрузки
        </span>

        <div
          className={`relative w-fit rounded border-[2px] border-dashed bg-whiteGray transition focus-within:border-primary ${
            !!errors.main?.vrcPhoto ? "border-red" : "border-grablacky"
          }`}
        >
          <Controller
            control={control}
            name="main.vrcPhoto"
            render={(props) => {
              return (
                <input
                  {...props}
                  className={`h-[222px] w-[230px] cursor-pointer rounded bg-lightWhiteGray px-[20px] 
            py-[15px] text-[13px] leading-[16px] tracking-wider opacity-0
            outline-none transition placeholder:text-[13px]`}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    props.field.onChange(e.target.files);
                    setValue("main.vrcPhoto", e.target.files || undefined);
                  }}
                />
              );
            }}
          />

          {currentSts ? (
            <img
              className="pointer-events-none absolute top-0 left-0 right-0 bottom-0 h-full w-full object-cover"
              src={
                currentSts
                  ? typeof currentSts[0] === "object"
                    ? URL.createObjectURL(currentSts[0])
                    : typeof currentSts === "string"
                    ? currentSts
                    : undefined
                  : undefined
              }
              alt="Preview"
            />
          ) : (
            <SvgPlus className="pointer-events-none absolute left-[50%] top-[50%] h-[30px] w-[30px] translate-x-[-50%] translate-y-[-50%] text-primary" />
          )}
          {currentSts && (
            <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
              <button
                type="button"
                className="rounded bg-primary p-[8px] text-white transition
                      hover:bg-white hover:text-red focus:bg-white focus:text-red active:bg-primary active:text-white"
                onClick={() => {
                  setValue("main.vrcPhoto", undefined);
                }}
              >
                <SvgTrash className="h-[30px] w-[30px]" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mb-[30px]" onMouseLeave={handleCloseHelpPopup}>
        <Title className="mb-[25px]">Место бронирования техники</Title>
        <div className="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-x-[15px] gap-y-[25px]">
          <Controller
            control={control}
            name="main.location"
            render={(props) => {
              return (
                <CustomSelect
                  label="Локация *"
                  menuPlacement="top"
                  className="w-full"
                  placeholder="Введите локацию..."
                  invalid={!!errors.main?.location}
                  invalidMessage={errors.main?.location?.message}
                  reference={props.field.ref}
                  options={addressesOptions}
                  filterOption={() => true}
                  noOptionsMessage={() =>
                    getNoOptionsMessage({
                      minLength: 5,
                      string: currentLocation,
                      isLoading: isAddressesLoading,
                    })
                  }
                  onChange={(val) => {
                    props.field.onChange(val.value);
                  }}
                  onInputChange={(str, action) => {
                    if (action.action === "input-change")
                      setValue("main.location", str);
                  }}
                  inputValue={props.field.value}
                  value={props.field.value}
                />
              );
            }}
          />
          <div className="relative">
            <Controller
              control={control}
              name="main.coverageRadius"
              render={(props) => {
                return (
                  <CustomSelect
                    className="w-full"
                    label="Радиус охвата *"
                    menuPlacement="top"
                    isSearchable={false}
                    invalid={!!errors.main?.coverageRadius}
                    invalidMessage={errors.main?.coverageRadius?.message}
                    reference={props.field.ref}
                    options={searchRadOptions}
                    placeholder="Выберите опцию..."
                    value={searchRadOptions?.find(
                      (c) => c.value === props.field.value
                    )}
                    onChange={(val) => {
                      props.field.onChange(val.value);
                    }}
                  />
                );
              }}
            />
            <button
              className={`absolute top-[-7px] right-0 outline-none ${
                isHelpPopupOpen ? "text-primary" : "text-black"
              } transition hover:text-primary focus:text-primary active:text-black`}
              onClick={handleOpenHelpPopup}
              onFocus={handleOpenHelpPopup}
              onBlur={handleCloseHelpPopup}
              onMouseEnter={handleOpenHelpPopup}
              type="button"
            >
              <SvgQuestionCircle className="h-[32px] w-[32px]" />
            </button>
            {isHelpPopupOpen && (
              <div className="absolute top-[-200px] right-0 z-10 w-[325px] rounded bg-white p-[25px] text-center font-medium shadow">
                <h3 className="mb-[25px] text-lg">Радиус охвата</h3>
                <p className="text-base">
                  Параметр указывает радиус охвата вашей техники от вашего места
                  базирования
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
