import { FC } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { CustomInput } from "common/components/Input/CustomInput";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { List } from "common/components/List/List";
import { VehicleListItem } from "common/components/Vehicle/VehicleListItem";
import {
  nextOrderStep,
  OrderingModeType,
  setOrderingMode,
  setOrderPrice,
  setVehicleTypeId,
} from "features/ordering/orderingSlice";
import { useGetVehicleTypesQuery } from "services/vehicle/vehicleTypesApi";
import { Button } from "common/components/Button/Button";
import { Switch } from "common/components/Checkbox/Switch";
import { Title } from "common/components/Title/Title";
import { IOrderTypeChooseProps } from "common/models/ordering/IOrderTypeChooseProps";

export const TypeChoose: FC<IOrderTypeChooseProps> = ({ categoryId }) => {
  const dispatch = useAppDispatch();

  const vehicleTypeId = useAppSelector((state) => state.ordering.vehicleTypeId);
  const orderingMode = useAppSelector((state) => state.ordering.orderingMode);
  const price = useAppSelector((state) => state.ordering.price);

  const {
    data: vehicleTypes,
    isLoading,
    error,
  } = useGetVehicleTypesQuery([categoryId]);

  const handleVehicleTypeIdChange = (id: Undefinable<number>) => {
    dispatch(setVehicleTypeId(id));
  };

  const handlePriceChange = (price: number) => {
    dispatch(setOrderPrice(price));
  };

  const handleNextOrderState = () => {
    dispatch(nextOrderStep());
  };

  const handleSetIsVehicleTypeChooseManual = (mode: OrderingModeType) => {
    dispatch(setOrderingMode(mode));
  };

  return (
    <>
      <QueryLayout isLoading={isLoading} error={error}>
        <List>
          {vehicleTypes &&
            vehicleTypes.map((vehicle) => (
              <li key={vehicle.id} className="relative">
                <CustomInput
                  disabled={orderingMode !== "manual"}
                  className={`${orderingMode !== "manual" ? "opacity-50" : ""}`}
                  value={vehicle.id}
                  checked={
                    vehicleTypeId === vehicle.id && orderingMode === "manual"
                  }
                  onChange={() => {
                    handleVehicleTypeIdChange(
                      vehicleTypeId === vehicle.id ? undefined : vehicle.id
                    );
                    handlePriceChange(vehicle.price);
                  }}
                  type="checkbox"
                  innerelement={
                    <VehicleListItem
                      mode="short"
                      name={vehicle.name}
                      img={vehicle.category.icon.path}
                    />
                  }
                />
              </li>
            ))}
        </List>
      </QueryLayout>
      <Button
        mode="darkBorder"
        disabled={orderingMode === "manager"}
        className="my-[25px] w-full"
        onClick={() =>
          orderingMode === "manualVehicleChoosing"
            ? handleSetIsVehicleTypeChooseManual("manual")
            : handleSetIsVehicleTypeChooseManual("manualVehicleChoosing")
        }
      >
        Подобрать вручную
      </Button>
      <label className="mb-[10px] flex items-center justify-between text-sm">
        Мне нужна помощь с подбором техники
        <Switch
          onChange={() =>
            orderingMode === "manager"
              ? handleSetIsVehicleTypeChooseManual("manual")
              : handleSetIsVehicleTypeChooseManual("manager")
          }
          checked={orderingMode === "manager"}
        />
      </label>
      <p className="mb-[25px] text-sm text-lightGray">
        Если у вас есть сомнения с выбором техники или вы не можете определиться
        с параметрами заказа создайте заказ и с вами свяжется оператор
      </p>
      <div className="grid grid-cols-2 items-center gap-[15px] ">
        {price && <Title className="text-xl">{`${price} руб.`}</Title>}
        <Button
          className="w-full"
          onClick={handleNextOrderState}
          disabled={!vehicleTypeId && orderingMode !== "manager"}
        >
          Продолжить
        </Button>
      </div>
    </>
  );
};
