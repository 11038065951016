import { FC } from "react";
import { Button } from "common/components/Button/Button";
import { useAppSelector } from "app/hooks";
import { CompanyProfileCard } from "common/components/Profile/CompanyProfileCard";

export const CustomerCompanyPayment: FC<{
  setIsCreatingInvoice: (status: boolean) => void;
  isCreatinginvoice: boolean;
}> = ({ setIsCreatingInvoice, isCreatinginvoice }) => {
  const orderPrice = useAppSelector((state) => state.ordering.price);
  const orderUseBonuses = useAppSelector((state) => state.ordering.useBonuses);
  const userBonuses = useAppSelector((state) => state.user.current?.bonuses);
  const currentCompany = useAppSelector(
    (state) => state.user.current?.account.company
  );

  const finalOrderPrice =
    (orderPrice || 0) - (orderUseBonuses ? (userBonuses ? userBonuses : 0) : 0);

  return (
    <div className="grid gap-[15px]">
      {currentCompany && (
        <>
          <CompanyProfileCard company={currentCompany} />
          {currentCompany.balance < finalOrderPrice && (
            <div className="text-center text-red">Недостаточно средств</div>
          )}
        </>
      )}

      <Button
        mode="darkBorder"
        type="button"
        onClick={() => setIsCreatingInvoice(!isCreatinginvoice)}
      >
        Пополнить счет
      </Button>
    </div>
  );
};
