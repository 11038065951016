import { FC, memo } from "react";
import { ProfileCard } from "../Profile/ProfileCard";
import { IProfileButtonProps } from "common/models/components/ui/IButtonProps";

export const ProfileButton: FC<IProfileButtonProps> = memo((props) => {
  const userClasses = props.className || "";

  const isShortMode = props.mode === "short";

  return (
    <button
      {...props}
      className={`flex min-h-[57px] rounded border border-whiteGray p-[10px] outline-none
      transition hover:border-primary focus:border-primary disabled:border-whiteGray disabled:opacity-70 ${userClasses}`}
    >
      <ProfileCard
        isShortMode={isShortMode}
        avatarurl={props.avatarurl}
        primary={props.primary}
        name={props.name}
        subname={props.subname}
        balance={props.balance}
        rating={props.rating}
      />
    </button>
  );
});
