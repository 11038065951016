import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { List } from "../../../common/components/List/List";
import { OrderList } from "../../../common/components/Order/OrderList";
import {
  setAvtiveOrderId,
  setDateFrom,
  setDateTo,
} from "./finishedOrderModerationSlice";
import { ORDER_STATUS } from "common/utils/consts";
import { IOrder } from "common/models/order/IOrder";

export const FinishedOrdersList: FC = () => {
  const activeOrderId = useAppSelector(
    (state) => state.platformManager.finishedOrderModeration.activeOrderId
  );
  const finishedOrderDateFrom = useAppSelector(
    (state) =>
      state.platformManager.finishedOrderModeration.finishedOrderDateFrom
  );
  const finishedOrdersDateTo = useAppSelector(
    (state) => state.platformManager.finishedOrderModeration.finishedOrderDateTo
  );

  const dispatch = useAppDispatch();

  const handleSetActiveDisputeId = (order: IOrder) => {
    dispatch(setAvtiveOrderId(order.id));
  };

  const handleDisputesDateFromChange = (date: Nullable<Date>) => {
    dispatch(setDateFrom(date?.toISOString() || undefined));
  };

  const handleDisputesDateToChange = (date: Nullable<Date>) => {
    dispatch(setDateTo(date?.toISOString() || undefined));
  };

  return (
    <>
      <div>
        <DatePicker
          onStartDateChange={handleDisputesDateFromChange}
          onEndDateChange={handleDisputesDateToChange}
          placeholderText="Введите промежуток дат"
          isClearable={true}
        />
      </div>

      <List className="mt-[10px] overflow-y-scroll">
        <OrderList
          filter={{
            statusId: [ORDER_STATUS.FINISHED],
            dateFrom: finishedOrderDateFrom,
            dateTo: finishedOrdersDateTo,
          }}
          activeOrderId={activeOrderId}
          onItemClick={handleSetActiveDisputeId}
          withAddresses
        />
      </List>
    </>
  );
};
