import { SOCKET_EVENT } from "./../common/utils/consts";
import { IGetOrdersInputs } from "./../common/models/api/IGetOrdersInputs";
import { IDisputeInputs } from "./../common/models/api/IDisputeInputs";
import { IApiMessage } from "./../common/models/api/IApiMessage";
import { IOrderCreateInputs } from "./../common/models/api/IOrderCreateInputs";
import { IAddress } from "./../common/models/order/IAddress";
import { IDispute } from "./../common/models/order/IDIspute";
import { IOrder } from "../common/models/order/IOrder";
import { getFilterParameters } from "../common/utils/helpers";
import { api } from "./api";
import { ICancelReason } from "common/models/order/ICancelReason";
import Pusher from "pusher-js";

const orderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrders: builder.query<IOrder[], Undefinable<IGetOrdersInputs>>({
      query(props) {
        return {
          url: `/orders${getFilterParameters([
            { paramName: "status_id[]", params: props?.statusId },
            {
              paramName: "vehicle_category_id[]",
              params: props?.vehicleCategoryId,
            },
          ])}`,
          params: {
            is_active: props?.isActive,
            company_id: props?.companyId,
            executor_company_id: props?.executorCompanyId,
            date_from: props?.dateFrom,
            date_to: props?.dateTo,
            user_id: props?.userId,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Order", id } as const)),
              { type: "Order", id: "LIST" },
              "Auth",
            ]
          : [{ type: "Order", id: "LIST" }],
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheEntryRemoved, cacheDataLoaded }
      ) {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
          cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
          authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
          auth: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        });
        await cacheDataLoaded;
        const channel = pusher.subscribe(`private-orders`);
        channel.bind(SOCKET_EVENT.ORDER_UPDATED, (data: { order: IOrder }) => {
          updateCachedData((draft) => {
            console.log("updated list", data);
            if (arg?.statusId) {
              if (arg.statusId.includes(data.order.status.id)) {
                return draft.map((orderItem) => {
                  if (orderItem.id === data.order.id) {
                    return data.order;
                  } else {
                    return orderItem;
                  }
                });
              } else {
                return draft.filter(
                  (orderItem) => orderItem.id !== data.order.id
                );
              }
            } else {
              return draft.map((orderItem) => {
                if (orderItem.id === data.order.id) {
                  return data.order;
                } else {
                  return orderItem;
                }
              });
            }
          });
        });
        channel.bind(SOCKET_EVENT.ORDER_DELETED, (data: { order: IOrder }) => {
          console.log("deleted", data);
          updateCachedData((draft) =>
            draft.filter((orderItem) => orderItem.id !== data.order.id)
          );
        });
        channel.bind(SOCKET_EVENT.ORDER_CREATED, (data: { order: IOrder }) => {
          updateCachedData((draft) => {
            console.log("created", data);
            if (arg?.statusId) {
              if (arg.statusId.includes(data.order.status.id)) {
                return [data.order, ...draft];
              }
            } else {
              return [data.order, ...draft];
            }
          });
        });
        await cacheEntryRemoved;
        pusher.unsubscribe(`private-orders`);
      },
      transformResponse: (result: { orders: IOrder[] }) =>
        Array.isArray(result.orders)
          ? result.orders.sort((a, b) =>
              new Date(a.datetime) < new Date(b.datetime) ? 1 : -1
            )
          : result.orders,
    }),
    getOrderById: builder.query<
      IOrder,
      {
        id: number;
      }
    >({
      query({ id }) {
        return {
          url: `/orders/${id}`,
        };
      },
      providesTags: (result, error, { id }) => [{ type: "Order", id }],
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheEntryRemoved, cacheDataLoaded }
      ) {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
          cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
          authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
          auth: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        });
        await cacheDataLoaded;
        const channel = pusher.subscribe(`private-orders.${arg.id}`);
        channel.bind(
          SOCKET_EVENT.ORDER_ITEM_UPDATED,
          (data: { order: IOrder }) => {
            updateCachedData((draft) => data.order);
          }
        );
        await cacheEntryRemoved;
        pusher.unsubscribe(`private-orders.${arg.id}`);
      },
      transformResponse: (result: { order: IOrder }) => result.order,
    }),
    attachOrderVehicle: builder.mutation<
      IApiMessage,
      {
        order_id: number;
        vehicle_id: Nullable<number>;
        driver_id: Nullable<number>;
      }
    >({
      query({ order_id, ...body }) {
        return {
          url: `/orders/${order_id}/company/drivers/attach`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, { order_id }) =>
        !error?.status ? [{ type: "Order", id: order_id }] : [],
    }),
    attachOrderToCompany: builder.mutation<IApiMessage, number>({
      query(orderId) {
        return {
          url: `/orders/${orderId}/company/attach`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, orderId) =>
        !error?.status ? [{ type: "Order", id: orderId }] : [],
    }),
    detachOrderCompany: builder.mutation<IApiMessage, number>({
      query(orderId) {
        return {
          url: `/orders/${orderId}/company/detach`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, orderId) =>
        !error?.status ? [{ type: "Order", id: orderId }] : [],
    }),
    detachOrderVehicle: builder.mutation<
      IApiMessage,
      {
        orderId: number;
      }
    >({
      query({ orderId }) {
        return {
          url: `/orders/${orderId}/company/drivers/detach`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, { orderId }) =>
        !error?.status ? [{ type: "Order", id: orderId }] : [],
    }),
    createOrder: builder.mutation<IOrder, IOrderCreateInputs>({
      query(body) {
        return {
          url: `/orders`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error) =>
        result ? [{ type: "Order", id: "LIST" }] : [],
      transformResponse: (result: { order: IOrder }) => result.order,
    }),
    confirmOrder: builder.mutation<IOrder, number>({
      query(orderId) {
        return {
          url: `/orders/${orderId}/confirm`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, arg) =>
        !error?.status ? [{ type: "Order", id: arg }] : [],
      transformResponse: (result: { orders: IOrder }) => result.orders,
    }),
    cancelOrder: builder.mutation<IApiMessage, IDisputeInputs>({
      query({ order_id, ...body }) {
        return {
          url: `/orders/${order_id}/cancel`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, arg) =>
        !error?.status ? [{ type: "Order", id: arg.order_id }] : [],
    }),
    createDispute: builder.mutation<IDispute, IDisputeInputs>({
      query({ order_id, ...body }) {
        return {
          url: `/orders/${order_id}/disputes`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, orderId) =>
        !error?.status ? [{ type: "Order", id: "LIST" }] : [],
    }),
    updateOrder: builder.mutation<
      IOrder,
      {
        orderId: number;
        vehicleTypeId?: number;
        cityId?: number;
        options?: number[];
        searchRadiusId?: number;
        rate?: number;
      }
    >({
      query({ orderId, ...body }) {
        return {
          url: `/orders/${orderId}`,
          method: "PUT",
          body: {
            order_id: orderId,
            vehicle_type_id: body.vehicleTypeId,
            city_id: body.cityId,
            options: body.options,
            search_radius_id: body.searchRadiusId,
            rate: body.rate,
          },
        };
      },
      invalidatesTags: (result, error, { orderId }) =>
        !error?.status ? [{ type: "Order", id: orderId }] : [],
      transformResponse: (result: { order: IOrder }) => result.order,
    }),
    payOrder: builder.mutation<IApiMessage, number>({
      query(orderId) {
        return {
          url: `/orders/${orderId}/pay`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, id) =>
        !error?.status ? [{ type: "Order", id }] : [],
    }),
    uploadOrderFiles: builder.mutation<
      IOrder,
      {
        order_id: number;
        photos: number[];
      }
    >({
      query({ order_id, ...body }) {
        return {
          url: `/orders/${order_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { order_id }) =>
        !error?.status ? [{ type: "Order", id: order_id }] : [],
      transformResponse: (result: { order: IOrder }) => result.order,
    }),
    getDisputes: builder.query<
      IDispute[],
      {
        dateFrom?: string;
        dateTo?: string;
        userId?: number;
        orderId?: number;
      }
    >({
      query({ userId, orderId, dateFrom, dateTo }) {
        return {
          url: `/disputes`,
          params: {
            user_id: userId,
            order_id: orderId,
            date_from: dateFrom,
            date_to: dateTo,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Order", id } as const)),
              { type: "Order", id: "LIST" },
            ]
          : [{ type: "Order", id: "LIST" }],
      transformResponse: (result: { disputes: IDispute[] }) => result.disputes,
    }),
    searchAddresses: builder.mutation<
      IAddress[],
      {
        city_id?: number;
        query: string;
      }
    >({
      query(body) {
        return {
          url: `/orders/search_address`,
          method: "POST",
          body,
        };
      },
      transformResponse: (result: { addresses: IAddress[] }) =>
        result.addresses,
    }),
    getOrderCancelReasons: builder.query<ICancelReason[], void>({
      query() {
        return {
          url: `/cancel_reasons`,
        };
      },
      transformResponse: (result: { items: ICancelReason[] }) => result.items,
    }),
  }),
});

export const {
  useAttachOrderToCompanyMutation,
  useDetachOrderCompanyMutation,
  useDetachOrderVehicleMutation,
  useConfirmOrderMutation,
  useSearchAddressesMutation,
  useCreateOrderMutation,
  usePayOrderMutation,
  useUploadOrderFilesMutation,
  useGetAllOrdersQuery,
  useGetOrderByIdQuery,
  useAttachOrderVehicleMutation,
  useGetDisputesQuery,
  useCreateDisputeMutation,
  useCancelOrderMutation,
  useUpdateOrderMutation,
  useGetOrderCancelReasonsQuery,
} = orderApi;
