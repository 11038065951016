import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgListDashes } from "../../../assets/icons/SvgListDashes";
import { CustomLineChart } from "../../../common/components/Chart/LineChart";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { Tabs } from "../../../common/components/Tabs/Tabs";
import { Route, Routes } from "react-router-dom";
import { AdminWorkTable } from "../tables/adminWork/AdminWorkTable";

export const AdminWorkPage: FC = () => {
  const filters = useAppSelector((state) => state.administrator.orders.filters);

  const dispatch = useAppDispatch();

  const handleSetActiveTab = (period: number) => {
    // dispatch(setFilterPeriod(period))
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex h-[35px]">
          <div className="mr-[15px]">
            <Tabs
              className="h-full"
              tabs={[
                { label: "Сегодня", value: 24 * 60 * 60 * 1000 },
                { label: "Вчера", value: -24 * 60 * 60 * 1000 },
                { label: "Неделя", value: 24 * 60 * 60 * 1000 * 7 },
                { label: "Месяц", value: 24 * 60 * 60 * 1000 * 30 },
                { label: "Квартал", value: 24 * 60 * 60 * 1000 * 90 },
                { label: "Год", value: 24 * 60 * 60 * 1000 * 365 },
              ]}
              value={filters.period}
              onTabClick={handleSetActiveTab}
            />
          </div>
          <div>
            <DatePicker className="h-[35px]" />
          </div>
        </div>
        <div>
          <button
            className="flex h-[35px] items-center justify-center rounded border border-transparent bg-lightWhiteGray px-[8px] text-sm font-medium
        outline-none transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary active:text-black"
          >
            <SvgListDashes className="mr-[5px] h-[16px] w-[16px]" />
            Фильтр
          </button>
        </div>
      </div>
      <div className="px-[5px]">
        <div className="mb-[20px]">{/* <CustomLineChart /> */}</div>
        <Routes>
          <Route path="/*" element={<AdminWorkTable />} />
        </Routes>
      </div>
    </>
  );
};
