import { FC, useEffect } from "react";
import {
  createSearchParams,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { PlatformManagerSidebar } from "../../features/platformManager/sidebar/PlatformManagerSidebar";
import { DesktopPage } from "../../features/platformManager/desktop/DesktopPage";
import { IconInput } from "../../common/components/Input/IconInput";
import {
  CustomSelect,
  UserTypeSingleValue,
} from "../../common/components/Select/CustomSelect";
import { SvgMap } from "../../assets/icons/SvgMap";
import {
  setSearchText,
  setUserType,
} from "../../features/platformManager/search/searchSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ChooseVehiclePage } from "../../features/platformManager/chooseVehicle/ChooseVehiclePage";
import { SearchPage } from "../../features/platformManager/search/SearchPage";
import { DisputePage } from "../../features/platformManager/dispute/DisputePage";
import { CancelOrdersPage } from "../../features/platformManager/canceldOrder/CancelOrdersPage";
import { InvoicePage } from "../../features/platformManager/invoice/InvoicePage";
import { CompanyModerationPage } from "../../features/platformManager/companyModeration/CompanyModerationPage";
import { VehicleModerationPage } from "../../features/platformManager/vehicleModeration/VehicleModerationPage";
import { FinishedOrderModerationPage } from "../../features/platformManager/finishedOrderModeration/FinishedOrderModerationPage";
import { PlatformManagerHeader } from "../../features/platformManager/header/PlatformManagerHeader";

const options = [
  { value: "Контрагент", label: "Контрагент" },
  { value: "Агент", label: "Агент" },
];

export const PlatformManagerPage: FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const searchText = useAppSelector(
    (state) => state.platformManager.search.searchText
  );
  const userType = useAppSelector(
    (state) => state.platformManager.search.userType
  );

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchText(e.target.value));
  };

  const handleUserTypeChange = (type: "Контрагент" | "Агент") => {
    dispatch(setUserType(type));
  };

  return (
    <>
      <PlatformManagerSidebar />
      <div className="grid w-full grid-rows-[105px,calc(100vh-105px)]">
        <PlatformManagerHeader />
        <main className="grid h-full grid-rows-[50px,1fr] gap-[15px] overflow-y-hidden px-[15px]">
          <form
            onKeyDown={(event) => {
              if (event.key === "Enter" && searchText.trim()) {
                navigate({
                  pathname: "/search",
                  search: createSearchParams({ text: searchText }).toString(),
                });
              }
            }}
            className="grid grid-cols-[.67fr,.33fr] gap-[15px]"
          >
            <IconInput
              className="w-full tracking-normal"
              value={searchText}
              onChange={handleSearchTextChange}
              type="search"
              placeholder="Поиск по пользователям / номер телефона"
              icon={<SvgMap className="h-[20px] w-[20px] text-gray" />}
            />
            <CustomSelect
              options={options}
              placeholder="Выберите опцию..."
              components={{ SingleValue: UserTypeSingleValue }}
              value={options.find((option) => option.value === userType)}
              onChange={(val) => handleUserTypeChange(val?.value)}
            />
          </form>
          <div className="grid grid-cols-[335px,.58fr,.42fr] gap-[15px] overflow-y-hidden">
            <Routes>
              <Route path="/desktop" element={<DesktopPage />} />
              <Route path="/" element={<ChooseVehiclePage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/dispute" element={<DisputePage />} />
              <Route path="/canceld-orders" element={<CancelOrdersPage />} />
              <Route path="/invoice" element={<InvoicePage />} />
              {/* <Route path="/guest-orders" element={<GuestOrdersPage />} /> */}
              <Route
                path="/companies-moderation"
                element={<CompanyModerationPage />}
              />
              <Route
                path="/vehicle-moderation"
                element={<VehicleModerationPage />}
              />
              <Route
                path="/finished-order-moderation"
                element={<FinishedOrderModerationPage />}
              />
              <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </main>
      </div>
    </>
  );
};
