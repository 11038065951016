import { IStatus } from "./../common/models/commonModels/IStatus";
import { IFullAppeal } from "./../common/models/appeals/IFullAppeal";
import { IMessage } from "./../common/models/commonModels/IMessage";
import { IAppeal } from "../common/models/appeals/IAppeal";
import { api } from "./api";

const appealApi = api
  .enhanceEndpoints({ addTagTypes: ["Appeal"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllAppeals: build.query<
        IAppeal[],
        {
          dateFrom?: string;
          dateTo?: string;
          userId?: number;
          statusId?: number;
        }
      >({
        query({ dateFrom, dateTo, userId, statusId }) {
          return {
            url: `/tickets`,
            params: {
              date_from: dateFrom,
              date_to: dateTo,
              user_id: userId,
              ticket_status_id: statusId,
            },
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Appeal", id } as const)),
                { type: "Appeal", id: "LIST" },
              ]
            : [{ type: "Appeal", id: "LIST" }],
        transformResponse: (result: { tickets: IAppeal[] }) => result.tickets,
      }),
      getAppealStatuses: build.query<IStatus[], void>({
        query() {
          return {
            url: `/ticket_statuses`,
          };
        },
        transformResponse: (result: { items: IStatus[] }) => result.items,
      }),
      getAppealById: build.query<IFullAppeal, number>({
        query(id) {
          return {
            url: `/tickets/${id}`,
          };
        },
        providesTags: (result) =>
          result ? [{ type: "Appeal", id: result.id }] : [],
        transformResponse: (result: { ticket: IFullAppeal }) => result.ticket,
      }),
      getAppealMessages: build.query<IMessage[], number>({
        query(id) {
          return {
            url: `/tickets/${id}/messages`,
          };
        },
        transformResponse: (result: { ticketMessages: IMessage[] }) =>
          result.ticketMessages,
      }),
      createAppeal: build.mutation<
        IFullAppeal,
        { title: string; text: string }
      >({
        query(body) {
          return {
            url: `/tickets`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Appeal", id: "LIST" }] : [],
        transformResponse: (result: { ticket: IFullAppeal }) => result.ticket,
      }),
      sendMessage: build.mutation<IMessage, { message: string; id: number }>({
        query({ id, ...body }) {
          return {
            url: `/tickets/${id}/messages`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Appeal", id: "LIST" }] : [],
        transformResponse: (result: { ticket_message: IMessage }) =>
          result.ticket_message,
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetAllAppealsQuery,
  useGetAppealByIdQuery,
  useGetAppealMessagesQuery,
  useGetAppealStatusesQuery,
  useCreateAppealMutation,
  useSendMessageMutation,
} = appealApi;
