import { FC, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ChooseDriver } from "./ChooseDriver";
import { ChooseVehicle } from "./ChooseVehicle";
import { IAssignmentVehicleProps } from "common/models/components/IAssignmentVehicleProps";

export const AssignmentVehicle: FC<IAssignmentVehicleProps> = ({
  handleAssignReset,
  handleAssignVehicles,
  handleAssignDriver,
  assignmentStateSelector,
  companyId,
}) => {
  const dispatch = useAppDispatch();
  const assignmentState = useAppSelector((state) =>
    assignmentStateSelector(state)
  );

  const [isDriverChoosing, setIsDriverChoosing] = useState<boolean>(false);

  useEffect(() => {
    setIsDriverChoosing(false);
  }, [assignmentState.activeOrderId]);

  const resetAssignment = () => {
    dispatch(handleAssignReset());
  };

  const setAssignedDriver = (id: Undefinable<number>) => {
    dispatch(handleAssignDriver(id));
  };

  const setAssignedVehicle = (id: number) => {
    dispatch(handleAssignVehicles(id));
  };

  const handleDriverChoosingOpen = () => {
    setIsDriverChoosing(true);
  };

  const handleDriverChoosingClose = () => {
    setIsDriverChoosing(false);
  };

  return (
    <div className="z-10 grid h-full w-full grid-rows-[minmax(125px,5%),minmax(100px,90%),minmax(75px,5%)] overflow-hidden rounded bg-white">
      {assignmentState.assignedVehicleTypeId &&
        (!isDriverChoosing ? (
          <ChooseVehicle
            driverChoosingOpen={handleDriverChoosingOpen}
            setAssignedVehicle={setAssignedVehicle}
            resetAssignment={resetAssignment}
            companyId={companyId}
            assignedVehicleTypeId={assignmentState.assignedVehicleTypeId}
            assignedVehicleId={assignmentState.assignedVehicleId}
          />
        ) : (
          <ChooseDriver
            driverChoosingClose={handleDriverChoosingClose}
            setAssignedDriver={setAssignedDriver}
            resetAssignment={resetAssignment}
            orderId={assignmentState.activeOrderId}
            companyId={companyId}
            assignedVehicleId={assignmentState.assignedVehicleId}
            assignedDriverId={assignmentState.assignedDriverId}
          />
        ))}
    </div>
  );
};
