import { FC, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Spinner } from "../common/components/Spinner/Spinner";
import { guestLogin, login } from "../features/user/userSlice";
import { AuthorizationPage } from "../pages/AuthorizationPage";
import { MainPage } from "../pages/MainPage";
import { useAuthUserQuery } from "../services/authApi";
import { useAppDispatch, useAppSelector } from "./hooks";

export const App: FC = () => {
  const { data, isLoading } = useAuthUserQuery();
  const dispatch = useAppDispatch();

  const isReady = useAppSelector((state) => state.user.isReady);

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        dispatch(login(data));
      } else {
        dispatch(guestLogin());
      }
    }
  }, [data, dispatch, isLoading]);

  if (!isReady)
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner className="h-[100px] w-[100px]" />
      </div>
    );

  return (
    <div className="min-h-screen overflow-hidden">
      <Routes>
        <Route path="*" element={<MainPage />} />
        <Route path="auth/*" element={<AuthorizationPage />} />
      </Routes>
    </div>
  );
};
