import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { Container } from "../../../common/components/Container/Container";
import { ProfileCard } from "../../../common/components/Profile/ProfileCard";
import { Title } from "../../../common/components/Title/Title";
import { InvoiceCard } from "../../../common/components/invoice/InvoiceCard";
import { NotFoundTitle } from "../../../common/components/Title/NotFoundTitle";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { setActiveInvoiceId, setInvoiceStatus } from "./invoicesSlice";
import {
  useGetAllInvoicesQuery,
  useGetInvoiceStatusesQuery,
} from "services/invoicesApi";
import { Tabs } from "common/components/Tabs/Tabs";
import { InvoiceForm } from "../../../common/components/invoice/InvoiceForm";
import { InvoiceInfo } from "common/components/invoice/InvoiceInfo";
import { Dropdown } from "common/components/Dropdown/Dropdown";
import { ShadowContainer } from "common/components/Container/ShadowContainer";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const InvoicesPage: FC = withErrorBoundary(() => {
  const currentBalance = useAppSelector(
    (state) => state.user.current?.account?.company?.balance
  );
  const currentCompany = useAppSelector(
    (state) => state.user.current?.account?.company
  );
  const activeInvoiceId = useAppSelector(
    (state) => state.customerCompanyManager.invoices.activeInvoiceId
  );
  const invoiceStatus = useAppSelector(
    (state) => state.customerCompanyManager.invoices.invoiceStatus
  );
  const {
    data: invoices,
    isLoading: isInvoicesLoading,
    isFetching: isInvoicesFetching,
    error: invoicesError,
  } = useGetAllInvoicesQuery({ statusId: [invoiceStatus] });

  const { data: invoiceStatuses } = useGetInvoiceStatusesQuery();

  const dispatch = useAppDispatch();

  const handlerSetActiveTransactionId = (id: Undefinable<number>) => {
    dispatch(setActiveInvoiceId(id));
  };

  const handleSetInvoiceStatus = (id: number) => {
    dispatch(setInvoiceStatus(id));
  };

  const invoiceStatusesArr = invoiceStatuses?.map((status) => ({
    value: status.id,
    label: status.name,
  }));

  return (
    <Container>
      <div className="grid h-full items-baseline gap-[12px] overflow-hidden">
        <div className="h-full overflow-y-scroll">
          <Title className="mb-[15px]">Счета</Title>
          <QueryLayout
            isLoading={isInvoicesLoading}
            isFetching={isInvoicesFetching}
            error={invoicesError}
          >
            <div className="grid gap-[15px]">
              {invoices?.length ? (
                invoices.map((invoice) => (
                  <button
                    key={invoice.id}
                    className={`rounded border text-left ${
                      activeInvoiceId === invoice.id
                        ? "border-primary"
                        : "border-transparent"
                    } transition hover:border-primary focus:border-primary active:border-transparent`}
                    onClick={() => handlerSetActiveTransactionId(invoice.id)}
                  >
                    <InvoiceCard
                      transactionId={invoice.id}
                      status={invoice.status.name}
                      desc={"описание"}
                      orderId={80}
                      amount={invoice.amount}
                      time={new Date(invoice.created_at)}
                    />
                  </button>
                ))
              ) : (
                <NotFoundTitle />
              )}
            </div>
          </QueryLayout>
        </div>
      </div>

      <div className="overflow-hidden">
        <div className="grid h-full grid-rows-[30px,1fr] gap-[15px] overflow-y-scroll">
          <div className="grid grid-cols-2 items-center">
            {invoiceStatusesArr && (
              <Tabs
                className="whitespace-nowrap"
                tabs={[{ value: 0, label: "Все" }, ...invoiceStatusesArr]}
                value={invoiceStatus}
                onTabClick={(value) => handleSetInvoiceStatus(value)}
              />
            )}

            <div className="ml-auto text-lg font-semibold text-primary">
              {`${currentBalance} руб.`}
            </div>
          </div>
          {!activeInvoiceId ? (
            <div>
              {currentCompany && (
                <div className="mb-[10px] rounded border border-whiteGray p-[12px]">
                  <ProfileCard
                    className="mb-[10px]"
                    isShortMode
                    name={currentCompany.name}
                    subname={currentCompany.category.name}
                    avatarurl={currentCompany.logotype?.path}
                  />
                  <div className="flex items-center justify-between">
                    <div className="text-md font-semibold">
                      {`${currentCompany.balance} руб.`}
                    </div>
                    <Button
                      className="h-[30px] py-[8px] text-xs font-medium leading-[12px]"
                      mode="yellow"
                    >
                      Транзакции
                    </Button>
                  </div>
                </div>
              )}
              <ShadowContainer>
                <Dropdown title="Пополнить счёт">
                  <InvoiceForm />
                </Dropdown>
              </ShadowContainer>
              <ShadowContainer>
                <Dropdown title="Вывести средства">
                  <InvoiceForm isWithdrawals />
                </Dropdown>
              </ShadowContainer>
            </div>
          ) : (
            <InvoiceInfo
              invoiceId={activeInvoiceId}
              onDelete={() => handlerSetActiveTransactionId(undefined)}
            />
          )}
        </div>
      </div>
    </Container>
  );
});
