import { FC, forwardRef } from "react";
import { SvgCheck } from "../../../assets/icons/SvgCheck";
import { CustomInputProps } from "../../models/components/input/ICustomInputProps";

export const CustomInput: FC<CustomInputProps> = forwardRef<
  HTMLInputElement,
  CustomInputProps
>((props, ref) => {
  const isActive = props.checked;

  const userClasses = props.className || "";

  const isInnerString = typeof props.innerelement === "string";

  return (
    <label
      className={`grid ${
        isActive ? "grid-cols-[0,1fr,30px]" : "grid-cols-[0px,1fr,0]"
      } cursor-pointer items-center rounded p-[15px] 
      ${
        isActive ? "border-primary" : "border-lightWhiteGray"
      } border-[1px] outline-none
      transition focus-within:border-primary  ${userClasses}`}
    >
      <input
        {...props}
        ref={ref}
        className="appearance-none focus:outline-none"
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event);
          }
        }}
      />
      {isInnerString ? (
        <span className="text-base font-semibold text-gray">
          {props.innerelement}
        </span>
      ) : (
        props.innerelement
      )}
      {isActive && (
        <SvgCheck
          className={`ml-auto h-[9px] w-[12px] text-primary transition`}
        />
      )}
    </label>
  );
});
