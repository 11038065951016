import { QueryLayout } from "common/components/Layout/QueryLayout";
import { Title } from "common/components/Title/Title";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useGetFaqsQuery } from "services/appApi";

export const FaqPage: FC<{ applicationId: number }> = ({ applicationId }) => {
  const { data, isLoading, error, isFetching } = useGetFaqsQuery(applicationId);

  return (
    <section className="absolute top-0 bottom-0 left-0 right-0 z-10 flex h-full flex-col bg-white py-[20px] px-[15px]">
      <Title>Вопросы</Title>
      <div className="mb-[15px] text-sm text-gray">
        <NavLink to="/">Главная</NavLink> / Вопросы
      </div>
      <div className="grid gap-[15px] rounded border border-lightWhiteGray p-[15px] leading-[17px] text-lightGray">
        <QueryLayout
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
        >
          {data?.map((question) => (
            <div key={question.id}>
              <p className="mb-[10px] font-medium text-black">
                {question.question}
              </p>
              <p>{question.answer}</p>
            </div>
          ))}
        </QueryLayout>
      </div>
    </section>
  );
};
