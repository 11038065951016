import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { KmuMarkFormInputs } from "./KmuMarkFormInputs";
import { Button } from "../../../../../common/components/Button/Button";
import { useParams } from "react-router-dom";
import {
  useGetKmuMarkByIdQuery,
  useUpdateKmuMarkMutation,
} from "services/vehicle/kmuApi";
import { getErrorMessage } from "common/utils/helpers";
import { Error } from "common/components/Message/Error/Error";
import { Success } from "common/components/Message/Success/Success";

export const KmuMarkEdit: FC = () => {
  const { id } = useParams();

  const {
    data: mark,
    isLoading: isMarkLoading,
    error: markError,
  } = useGetKmuMarkByIdQuery(id ? +id : -1, { skip: !id });

  const [update, { isLoading: isUpdating, error: updateError, isSuccess }] =
    useUpdateKmuMarkMutation();

  const methods = useForm<{
    name: string;
    id: number;
  }>();

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (mark) {
      reset(mark);
    }
  }, [mark]);

  const onSubmit = handleSubmit((data) => {
    update(data);
  });

  if (markError || updateError) {
    return (
      <Error>{getErrorMessage(markError || updateError)?.data.message}</Error>
    );
  }

  if (isSuccess) {
    return <Success>Марка успешно отредактирована!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <KmuMarkFormInputs />
      </FormProvider>
      <Button
        className="w-fit"
        disabled={!isDirty || isUpdating || isMarkLoading}
      >
        Изменить
      </Button>
    </form>
  );
};
