import { useForm } from "react-hook-form";
import { MODAL_TYPES } from "../../utils/consts";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import { useGlobalModalContext } from "./GlobalModal";
import { useGiveBonusesMutation } from "../../../services/userApi";
import { getErrorMessage } from "../../utils/helpers";

export const BonusesModal = () => {
  const [giveBonuses, { isLoading }] = useGiveBonusesMutation();

  const { showModal, hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { userId } = modalProps;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ bonuses: number; comment: string }>();

  const handleModalToggle = () => {
    hideModal();
  };

  const onSubmit = handleSubmit(async (data) => {
    await giveBonuses({ userId, value: data.bonuses, comment: data.comment })
      .unwrap()
      .then(() => {
        handleModalToggle();
        showModal(MODAL_TYPES.SUCCESS_MODAL, {
          title: "Пользователь успешно начисленны баллы",
        });
      })
      .catch((err) => {
        handleModalToggle();
        showModal(MODAL_TYPES.ERROR_MODAL, {
          title: getErrorMessage(err)?.data.message,
          subTitle: "Ошибка при зачислении баллов, повторите попытку позже",
        });
      });
  });

  return (
    <div className="min-w-[325px] rounded bg-white p-[25px] text-center">
      <div className="mb-[25px] text-lg font-medium">Начисление бонусов</div>
      <p className="m-auto mb-[25px] text-sm font-semibold text-lightGray">
        Укажите количество бонусов для начисления
      </p>
      <form className="grid gap-[15px] text-left" onSubmit={onSubmit}>
        <Input
          {...register("bonuses", { required: true, valueAsNumber: true })}
          className="w-full"
          invalid={!!errors.bonuses}
          placeholder="Кол-во бонусов"
          type="number"
        />
        <Input
          {...register("comment", { required: true })}
          className="w-full"
          invalid={!!errors.comment}
          label="Комментарий *"
          placeholder="Комментарий"
          type="text"
        />
        <div className="mt-[10px] grid grid-cols-2 gap-[15px]">
          <Button
            type="button"
            className="text-base font-medium tracking-wider"
            mode="darkBorder"
            onClick={handleModalToggle}
          >
            Отмена
          </Button>
          <Button
            type="submit"
            className="text-base font-medium tracking-wider"
            disabled={isLoading}
          >
            Ок
          </Button>
        </div>
      </form>
    </div>
  );
};
