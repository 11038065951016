import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgListDashes } from "../../../assets/icons/SvgListDashes";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { Route, Routes } from "react-router-dom";
import { PayoutsTable } from "../tables/finance/PayoutsTable";
import { CreditTable } from "../tables/finance/CreditTable";
import { SvgLoupe } from "../../../assets/icons/SvgLoupe";
import { IconInput } from "../../../common/components/Input/IconInput";

export const FinancePage: FC = () => {
  const filters = useAppSelector((state) => state.administrator.orders.filters);

  const dispatch = useAppDispatch();

  return (
    <>
      <div className="grid w-full grid-cols-[1fr,245px,100px] gap-[10px]">
        <IconInput
          className="h-[35px] w-full"
          placeholder="Поиск"
          icon={<SvgLoupe className="h-[15px] w-[15px] text-gray" />}
        />
        <div>
          <DatePicker className="h-[35px] w-full" />
        </div>

        <button
          className="flex h-[35px] items-center justify-center rounded border border-transparent bg-lightWhiteGray px-[8px] text-sm font-medium
        outline-none transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary active:text-black"
        >
          <SvgListDashes className="mr-[5px] h-[16px] w-[16px]" />
          Фильтр
        </button>
      </div>
      <div className="px-[5px]">
        <Routes>
          <Route path="/credit" element={<CreditTable />} />
          <Route path="/*" element={<PayoutsTable />} />
        </Routes>
      </div>
    </>
  );
};
