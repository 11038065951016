import React, { useState, createContext, useContext } from "react";
import { ConfirmModal } from "./ConfirmModal";
import { SuccessModal } from "./SuccessModal";
import { ErrorModal } from "./ErrorModal";
import { OrderDetailsModal } from "./OrderDetailsModal";
import { BanModal } from "./BanModal";
import { BonusesModal } from "./BonusesModal";
import { MODAL_TYPES } from "../../utils/consts";
import { VehicleSelectionModal } from "./VehicleSelectionModal";
import { ReportModal } from "./ReportModal";
import { OrderCancelationModal } from "./OrderCancelationModal";
import { DisputeModal } from "./DisputeModal";
import { OrderPauseModal } from "./OrderPauseModal";
import { PaymentModal } from "./PaymentModal";
import { ImagesSliderModal } from "./ImagesSliderModal";

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.CONFIRM_MODAL]: ConfirmModal,
  [MODAL_TYPES.SUCCESS_MODAL]: SuccessModal,
  [MODAL_TYPES.ERROR_MODAL]: ErrorModal,
  [MODAL_TYPES.ORDER_DETAILS_MODAL]: OrderDetailsModal,
  [MODAL_TYPES.BAN_MODAL]: BanModal,
  [MODAL_TYPES.BONUSES_MODAL]: BonusesModal,
  [MODAL_TYPES.VEHICLE_SELECTION_MODAL]: VehicleSelectionModal,
  [MODAL_TYPES.REPORT_MODAL]: ReportModal,
  [MODAL_TYPES.ORDER_CANCELATION_MODAL]: OrderCancelationModal,
  [MODAL_TYPES.DISPUTE_MODAL]: DisputeModal,
  [MODAL_TYPES.ORDER_PAUSE_MODAL]: OrderPauseModal,
  [MODAL_TYPES.PAYMENT_MODAL]: PaymentModal,
  [MODAL_TYPES.IMAGES_SLIDER_MODAL]: ImagesSliderModal,
};

type GlobalModalContextType = {
  showModal: (modalType: string, modalProps?: any) => void;
  hideModal: () => void;
  store: any;
};

const initalState: GlobalModalContextType = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
};

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [store, setStore] = useState<{
    modalType?: string;
    modalProps?: Object;
  }>({ modalProps: {}, modalType: undefined });

  const modalType = store?.modalType;
  const modalProps = store?.modalProps;

  const showModal = (modalType: string, modalProps: any = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: undefined,
      modalProps: {},
    });
  };

  const renderComponent = () => {
    const ModalComponent = modalType ? MODAL_COMPONENTS[modalType] : null;
    if (!modalType || !ModalComponent) {
      return null;
    }
    return (
      <section
        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex cursor-pointer items-center justify-center bg-[rgba(0,0,0,.2)]"
        onClick={hideModal}
      >
        <div
          className="cursor-auto rounded"
          onClick={(e) => e.stopPropagation()}
        >
          <ModalComponent id="global-modal" {...modalProps} />
        </div>
      </section>
    );
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
