import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Container } from "../../../common/components/Container/Container";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import {
  setActiveCompany,
  setCompanySearchText,
  setIsAddingCompany,
} from "./companiesSlice";
import { CompanyAdd } from "../../../common/components/Company/CompanyAdd";
import { ShadowContainer } from "../../../common/components/Container/ShadowContainer";
import { CompanyEdit } from "../../../common/components/Company/CompanyEdit";
import { CompaniesList } from "common/components/Company/CompaniesList";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const CompaniesPage: FC = withErrorBoundary(() => {
  const activeCompanyId = useAppSelector(
    (state) => state.individual.companies.activeCompanyId
  );
  const isAddingCompany = useAppSelector(
    (state) => state.individual.companies.isAddingCompany
  );
  const searchText = useAppSelector(
    (state) => state.individual.companies.searchText
  );

  const dispatch = useAppDispatch();

  const handlerSetActiveEmployee = (id: number) => {
    dispatch(setActiveCompany(id));
  };

  const handleSearchText = (text: string) => {
    dispatch(setCompanySearchText(text));
  };

  const handlerSetIsAddingEmployee = () => {
    dispatch(setIsAddingCompany(!isAddingCompany));
  };

  return (
    <Container>
      <div className="overflow-y-scroll">
        <CompaniesList
          onAdd={handlerSetIsAddingEmployee}
          onItemClick={handlerSetActiveEmployee}
          onSearch={handleSearchText}
          searchText={searchText}
          activeItemId={activeCompanyId}
        />
      </div>
      <div className="overflow-y-scroll">
        {isAddingCompany && (
          <ShadowContainer>
            <CompanyAdd />
          </ShadowContainer>
        )}
        {activeCompanyId && <CompanyEdit companyId={activeCompanyId} isOpen />}
        {!isAddingCompany && !activeCompanyId && <InformationBlock />}
      </div>
    </Container>
  );
});
