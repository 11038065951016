import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { Container } from "../../../common/components/Container/Container";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { ProfileCard } from "../../../common/components/Profile/ProfileCard";
import { Title } from "../../../common/components/Title/Title";
import { InvoiceCard } from "../../../common/components/invoice/InvoiceCard";
import { TransactionInfo } from "../../../common/components/Transaction/TransactionInfo";
import { setActiveTransactionId } from "./transactionsSlice";
import { useGetAllTransactionsQuery } from "../../../services/transactionApi";
import { NotFoundTitle } from "../../../common/components/Title/NotFoundTitle";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";
import { TransactionCard } from "common/components/Transaction/TransactionCard";

export const TransactionsPage: FC = withErrorBoundary(() => {
  const currentBalance = useAppSelector(
    (state) => state.user.current?.account?.company?.balance
  );
  const currentCompany = useAppSelector(
    (state) => state.user.current?.account?.company
  );
  const activeTransactionId = useAppSelector(
    (state) => state.individual.transactions.activeTransactionId
  );

  const {
    data: transactions,
    isLoading,
    isFetching,
    error,
  } = useGetAllTransactionsQuery({});

  const dispatch = useAppDispatch();

  const handlerSetActiveTransactionId = (id: number) => {
    dispatch(setActiveTransactionId(id));
  };

  return (
    <Container>
      <div className="grid h-full items-baseline gap-[12px] overflow-hidden">
        <div className="h-full overflow-y-scroll">
          <Title className="mb-[15px]">Транзакции</Title>
          <QueryLayout
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
          >
            <div className="grid gap-[15px]">
              {transactions?.length ? (
                transactions.map((transaction) => (
                  <button
                    key={transaction.id}
                    className={`rounded border text-left ${
                      activeTransactionId === transaction.id
                        ? "border-primary"
                        : "border-transparent"
                    } transition hover:border-primary focus:border-primary active:border-transparent`}
                    onClick={() =>
                      handlerSetActiveTransactionId(transaction.id)
                    }
                  >
                    <TransactionCard
                      transactionId={transaction.id}
                      desc={transaction.description}
                      orderId={transaction.order?.id}
                      amount={transaction.credit}
                      time={new Date(transaction.created_at)}
                    />
                  </button>
                ))
              ) : (
                <NotFoundTitle />
              )}
            </div>
          </QueryLayout>
        </div>
      </div>

      <div className="overflow-hidden">
        {currentBalance !== undefined && (
          <div className="mb-[15px] flex items-center justify-end">
            <div className="mr-[10px] font-semibold text-primary">
              {`Баланс: ${currentBalance} руб.`}
            </div>
            <Button
              className="h-[30px] py-[8px] text-xs font-medium leading-[12px]"
              mode="yellow"
            >
              Вывести средства
            </Button>
          </div>
        )}

        <div className="grid h-full overflow-y-scroll">
          {!activeTransactionId ? (
            <div>
              {currentCompany && (
                <div className="rounded border border-whiteGray p-[12px]">
                  <ProfileCard
                    className="mb-[10px]"
                    isShortMode
                    name={currentCompany.name}
                    subname={currentCompany.category.name}
                    avatarurl={currentCompany.logotype?.path}
                  />
                  <div className="flex items-center justify-between">
                    <div className="text-md font-semibold">
                      <span className="mr-[3px]">{currentCompany.balance}</span>
                      руб
                    </div>
                    <Button
                      className="h-[30px] py-[8px] text-xs font-medium leading-[12px]"
                      mode="yellow"
                    >
                      Транзакции
                    </Button>
                  </div>
                </div>
              )}
              <InformationBlock className="mt-[10px]" />
            </div>
          ) : (
            <TransactionInfo transactionId={activeTransactionId} />
          )}
        </div>
      </div>
    </Container>
  );
});
