import { FC } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { SubTitle } from "../../../common/components/Title/SubTitle";
import { SvgPlus } from "../../../assets/icons/SvgPlus";
import { CounterAgentPage } from "./counterAgent/CounterAgentPage";
import { SvgArrowLeft } from "../../../assets/icons/SvgArrowLeft";
import { ChassisMarkPage } from "./vehicles/chassisMarks/ChassisMarkPage";
import { ManipulatorsPage } from "./vehicles/vehicleType/manipulators/ManipulatorsPage";
import { TariffsPage } from "./tariffs/TariffsPage";
import { CransPage } from "./vehicles/vehicleType/crans/CransPage";
import { AerialPlatformPage } from "./vehicles/vehicleType/aerialPlatforms/AerialPlatformPage";
import { ChassisModelPage } from "./vehicles/chassisModels/ChassisModelPage";
import { KmuMarkPage } from "./vehicles/kmuMarksPage/KmuMarkPage";

export const DirectoryPage: FC = () => {
  const location = useLocation();

  return (
    <>
      <div className="flex w-full justify-between">
        {location.pathname.match(/add|\d/) ? (
          <Link
            className="flex items-center text-sm text-primary transition hover:text-black focus:text-black active:text-primary"
            to=".."
            relative="path"
          >
            <SvgArrowLeft className="mr-[10px] h-[15px] w-[15px]" />
            Назад
          </Link>
        ) : (
          <>
            <SubTitle>Справчоник</SubTitle>
            <Link
              className="text-primary transition hover:text-black focus:text-black active:text-primary"
              to={`${location.pathname}/add`}
            >
              <SvgPlus className="h-[15px] w-[15px]" />
            </Link>
          </>
        )}
      </div>
      <div className="px-[5px]">
        <Routes>
          <Route path="/counter-agents/*" element={<CounterAgentPage />} />
          <Route path="/vehicles/kmu-marks/*" element={<KmuMarkPage />} />
          <Route path="/vehicles/ts-marks/*" element={<ChassisMarkPage />} />
          <Route path="/vehicles/ts-models/*" element={<ChassisModelPage />} />
          <Route
            path="/vehicles/all/manipulators/*"
            element={<ManipulatorsPage />}
          />
          <Route path="/vehicles/all/crans/*" element={<CransPage />} />
          <Route
            path="/vehicles/all/aerial-platforms/*"
            element={<AerialPlatformPage />}
          />
          <Route path="/tariffs/*" element={<TariffsPage />} />
        </Routes>
      </div>
    </>
  );
};
