import { FC, useEffect } from "react";
import { Title } from "common/components/Title/Title";
import { Button } from "common/components/Button/Button";
import { BankCardsList } from "common/components/BankCard/BankCardsList";
import { useAddBankCardMutation } from "services/userApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setCardId } from "./orderingSlice";

export const CardsList: FC<{
  activeCardId?: number;
  handleSetActiveCardId: (id: number) => void;
}> = ({ activeCardId, handleSetActiveCardId }) => {
  const dispatch = useAppDispatch();

  const [createBankCard, { isLoading, data, error, isSuccess, reset }] =
    useAddBankCardMutation();

  const defaultCardId = useAppSelector(
    (state) => state.user.current?.default_bank_card?.id
  );
  const userId = useAppSelector((state) => state.user.current?.id);

  useEffect(() => {
    if (defaultCardId) {
      dispatch(setCardId(defaultCardId));
    }
  }, []);

  if (isSuccess && data)
    return (
      <div className="pointer-events-auto grid h-fit gap-[15px] overflow-hidden rounded bg-white p-[15px]">
        <p className="text-sm text-lightGray">
          Для того чтобы добавить новую карту, вам необходимо подтвердить что
          это ваша карта. Для этого нужно перейти по ссылке и произвести
          маленькую оплату.
        </p>
        <a
          href={data.redirect_link}
          target="_blank"
          onClick={reset}
          rel="noreferrer"
          className="w-full cursor-pointer rounded border border-black bg-black px-[23px] py-[10px] text-center text-base text-white outline-none transition hover:bg-white hover:text-black focus:bg-white focus:text-black active:bg-black active:text-white"
        >
          Перейти
        </a>
      </div>
    );

  return (
    <div className="pointer-events-auto h-fit overflow-hidden rounded bg-white p-[15px]">
      <div className="h-full overflow-y-scroll">
        <Title className="mb-[15px] text-center">Мои карты</Title>
        <QueryLayout isLoading={isLoading} error={error}>
          {userId && (
            <BankCardsList
              userId={userId}
              activeCardId={activeCardId}
              handleSetActiveCardId={handleSetActiveCardId}
            />
          )}
        </QueryLayout>
        <Button
          className="w-full"
          disabled={isLoading}
          onClick={() => createBankCard()}
        >
          Добавить новую карту
        </Button>
      </div>
    </div>
  );
};
