import { useEffect, FC } from "react";
import manipulator from "../../../assets/images/manipulator.png";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Switch } from "../Checkbox/Switch";
import { Title } from "../Title/Title";
import { List } from "../List/List";
import { useGetVehicleParametersQuery } from "../../../services/vehicle/vehiclesApi";
import { Button } from "../Button/Button";
import { IVehicleTypeSelectionProps } from "common/models/components/vehicle/IVehicleTypeSelectionProps";
import { Spinner } from "../Spinner/Spinner";
import { CustomSelect } from "../Select/CustomSelect";
import { getNoOptionsMessage } from "common/utils/helpers";

export const VehicleTypeSelection: FC<IVehicleTypeSelectionProps> = ({
  vehicleCategoryId,
  isDisabled,
  defaultValues,
  onSubmit,
  onClose,
}) => {
  const { data: parameters } = useGetVehicleParametersQuery(vehicleCategoryId);

  const methods = useForm<{
    boomCapacity: number;
    sideLoadCapacity: number;
    boomLength: number;
    optionalEquipment: string[];
  }>({
    defaultValues: {
      ...defaultValues,
      optionalEquipment: defaultValues?.optionalEquipment?.map((el) => el.id),
    },
  });

  const {
    register,
    setFocus,
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const currentOptions = useWatch({
    control,
    name: "optionalEquipment",
  });

  useEffect(() => {
    setFocus("boomCapacity");
  }, []);

  const onFormSubmit = handleSubmit((data) => {
    onSubmit({
      ...data,
      optionalEquipment: data.optionalEquipment
        .filter((el) => el)
        .map((option) => ({
          id: option,
          price:
            parameters?.options.find((opt) => String(opt.id) === option)
              ?.price || 0,
        })),
    });
  });

  if (!parameters) return <Spinner />;

  const boomCapacityOptins = [
    ...new Set(
      parameters.vehicleTypes.map((type) => type.arrow_carrying_capacity)
    ),
  ]
    .map((el) => ({
      value: el,
      label: el,
    }))
    .filter((item) => item.value);

  const sideLoadCapacityOptins = [
    ...new Set(
      parameters.vehicleTypes.map((type) => type.board_carrying_capacity)
    ),
  ]
    .map((el) => ({
      value: el,
      label: el,
    }))
    .filter((item) => item.value);

  const boomLengthOptins = [
    ...new Set(parameters.vehicleTypes.map((type) => type.lifting_height)),
  ]
    .map((el) => ({
      value: el,
      label: el,
    }))
    .filter((item) => item.value);

  return (
    <form className="grid grid-cols-2 gap-[15px]" onSubmit={onFormSubmit}>
      <div>
        <Title className="mb-[15px] text-center">Манипулятор</Title>
        <p className="mb-[25px] text-center text-sm font-medium text-lightGray">
          Параметры техники и груза для:
        </p>
        <div className="mb-[25px] flex justify-center">
          <img width={242} height={163} src={manipulator} alt="Manipulator" />
        </div>
        <div className="grid gap-[25px]">
          {boomCapacityOptins.length > 0 && (
            <Controller
              control={control}
              rules={{ required: true }}
              defaultValue={boomCapacityOptins[0].value}
              name="boomCapacity"
              render={(props) => {
                return (
                  <CustomSelect
                    label="Грузоподъёмность стрелы *"
                    placeholder="Грузоподъёмность стрелы "
                    className="w-full"
                    menuPlacement="top"
                    invalid={!!errors.boomCapacity}
                    reference={props.field.ref}
                    noOptionsMessage={(text) =>
                      getNoOptionsMessage({
                        string: text.inputValue,
                      })
                    }
                    options={boomCapacityOptins}
                    value={boomCapacityOptins.find(
                      (c) => c.value === props.field.value
                    )}
                    onChange={(val) => props.field.onChange(val?.value)}
                  />
                );
              }}
            />
          )}
          {boomLengthOptins.length > 0 && (
            <Controller
              control={control}
              rules={{ required: true }}
              defaultValue={boomLengthOptins[0].value}
              name="boomLength"
              render={(props) => {
                return (
                  <CustomSelect
                    label="Длинна стрелы *"
                    placeholder="Длинна стрелы"
                    className="w-full"
                    menuPlacement="top"
                    invalid={!!errors.boomLength}
                    reference={props.field.ref}
                    options={boomLengthOptins}
                    value={boomLengthOptins.find(
                      (c) => c.value === props.field.value
                    )}
                    onChange={(val) => props.field.onChange(val?.value)}
                  />
                );
              }}
            />
          )}
          {sideLoadCapacityOptins.length > 0 && (
            <Controller
              control={control}
              rules={{ required: true }}
              defaultValue={sideLoadCapacityOptins[0].value}
              name="sideLoadCapacity"
              render={(props) => {
                return (
                  <CustomSelect
                    reference={props.field.ref}
                    label="Грузопдъёмность борта *"
                    placeholder="Грузопдъёмность борта"
                    className="w-full"
                    menuPlacement="top"
                    invalid={!!errors.sideLoadCapacity}
                    options={sideLoadCapacityOptins}
                    value={sideLoadCapacityOptins.find(
                      (c) => c.value === props.field.value
                    )}
                    onChange={(val) => props.field.onChange(val?.value)}
                  />
                );
              }}
            />
          )}
        </div>
      </div>
      <div>
        <div className="mb-[30px]">
          <Title className="mb-[15px] text-center">
            Дополнительное оборудование
          </Title>

          <List className="mb-[30px] gap-[15px]">
            {parameters.options?.map((option, i) => (
              <li key={option.id}>
                <label className="flex items-center justify-between text-sm font-semibold">
                  {option.name}

                  <Switch
                    value={option.id}
                    checked={
                      !!currentOptions?.find(
                        (currOption) => +currOption === +option.id
                      )
                    }
                    {...register(`optionalEquipment.${i}`)}
                    className={errors.optionalEquipment?.[i] ? "error" : ""}
                  />
                </label>
              </li>
            ))}
          </List>
        </div>
        <div className="mt-[25px] grid gap-[15px]">
          <Button
            className="text-base font-medium tracking-wider"
            type="submit"
            disabled={isDisabled}
          >
            Применить
          </Button>
          <Button
            className="text-base font-medium tracking-wider"
            mode="darkBorder"
            onClick={onClose}
            type="button"
          >
            Назад
          </Button>
        </div>
      </div>
    </form>
  );
};
