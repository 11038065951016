import { FC, useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Button } from "common/components/Button/Button";
import { Input } from "common/components/Input/Input";
import {
  setFirstName,
  setLastName,
  setMiddleName,
  setPhone,
  setUseBonuses,
  setIsCodeSended,
} from "features/ordering/orderingSlice";
import { PhoneVerificationInput } from "common/components/Input/PhoneVerificationInput";
import { Title } from "common/components/Title/Title";
import { IconButton } from "common/components/Button/IconButton";
import { SvgCreditCard } from "assets/icons/SvgCreditCard";
import { Switch } from "common/components/Checkbox/Switch";
import { SvgCash } from "assets/icons/SvgCash";
import { IOrderContactInformationProps } from "common/models/ordering/IOrderContactInformationProps";
import { COMPANY_CATEGORY } from "common/utils/consts";
import { MaskedInput } from "common/components/Input/MaskedInput";
import { phoneStrTransform, timeFormatter } from "common/utils/helpers";
import { useLoginUserMutation, useSendCodeMutation } from "services/authApi";
import { CustomerCompanyPayment } from "./CustomerCompanyPayment";
import { login as setUser } from "features/user/userSlice";
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";

export const ContactInformation: FC<IOrderContactInformationProps> = ({
  currentCompany,
  isChangingPaymentMethod,
  isCreatingInvoice,
  isLoading,
  setIsChangingPaymentMethod,
  setIsCreatingInvoice,
  createOrder,
}) => {
  const dispatch = useAppDispatch();

  const [otp, setOtp] = useState<string>("");
  const [reloadTime, setReloadTime] = useState<Undefinable<number>>();

  const user = useAppSelector((state) => state.user.current);
  const userBonuses = useAppSelector((state) => state.user.current?.bonuses);
  const authUserData = useAppSelector((state) => state.user.current);

  const useBonuses = useAppSelector((state) => state.ordering.useBonuses);
  const paymentMethod = useAppSelector((state) => state.ordering.paymentMethod);
  const isCodeSended = useAppSelector((state) => state.ordering.isCodeSended);
  const bankCardId = useAppSelector((state) => state.ordering.bankCardId);
  const price = useAppSelector((state) => state.ordering.price);
  const orderindUser = useAppSelector((state) => state.ordering.user);
  const orderingData = useAppSelector((state) => state.ordering);

  const [
    login,
    {
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      isLoading: isLoginLoading,
      data: userData,
    },
  ] = useLoginUserMutation();
  const [
    sendCode,
    {
      isSuccess: isSendCodeSuccess,
      isError: isSendCodeError,
      isLoading: isSendCodeLoading,
    },
  ] = useSendCodeMutation();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    middleName: string;
    phone: string;
    code: string;
  }>({
    defaultValues: {
      firstName: orderindUser.firstName || authUserData?.first_name,
      lastName: orderindUser.lastName || authUserData?.last_name,
      middleName: orderindUser.middleName || authUserData?.middle_name,
      phone: orderindUser.phone || authUserData?.phone,
    },
  });

  const currentPhone = useWatch({ control, name: "phone" });

  useEffect(() => {
    setValue("code", otp);
  }, [otp, setValue]);

  useEffect(() => {
    if (isSendCodeSuccess) {
      setReloadTime(60000);
      dispatch(setIsCodeSended(true));
    }
  }, [isSendCodeSuccess]);

  useEffect(() => {
    if (isLoginSuccess) {
      setReloadTime(undefined);
      localStorage.removeItem("code_sended_time");
    }
  }, [isLoginSuccess]);

  useEffect(() => {
    let timer: Undefinable<TimeoutId | undefined> = undefined;
    if (reloadTime) {
      if (reloadTime < 0) {
        setReloadTime(undefined);
        localStorage.removeItem("code_sended_time");
        clearInterval(timer);
      } else {
        timer = setInterval(() => setReloadTime(reloadTime - 1000), 1000);
      }
    }
    return () => clearInterval(timer);
  }, [reloadTime]);

  useEffect(() => {
    const codeSendedStr = localStorage.getItem("code_sended_time");
    if (codeSendedStr) {
      const reloadDate = new Date(+new Date() - +new Date(codeSendedStr));

      if (reloadDate) {
        setReloadTime(60000 - +reloadDate);
      }
    }
  }, []);

  useEffect(() => {
    register("phone", {
      required: { value: true, message: "Данное поле обязательно" },
      validate: (value) => phoneStrTransform(value).length === 11,
    });
    return () => {
      setIsChangingPaymentMethod(false);
    };
  }, []);

  useEffect(() => {
    if (isLoginSuccess && userData) {
      dispatch(setUser(userData));
    }
  }, [isLoginSuccess]);

  const handleFirstNameChange = (text: string) => {
    dispatch(setFirstName(text));
  };

  const handleLastNameChange = (text: string) => {
    dispatch(setLastName(text));
  };
  const handleMiddleNameChange = (text: string) => {
    dispatch(setMiddleName(text));
  };

  const handlePhoneChange = (phone: string) => {
    dispatch(setPhone(phone));
  };

  const handleUseBonuses = () => {
    dispatch(setUseBonuses(!useBonuses));
  };

  const onSubmit = handleSubmit(async (data) => {
    handleFirstNameChange(data.firstName);
    handleLastNameChange(data.lastName);
    handleMiddleNameChange(data.middleName);
    handlePhoneChange(data.phone);

    if (!Object.getOwnPropertyNames(errors).length) {
      if (!user?.id) {
        if (isSendCodeSuccess) {
          login({
            phone: phoneStrTransform(data.phone),
            code: otp,
            first_name: data.firstName,
            last_name: data.lastName,
            middle_name: data.middleName || undefined,
          });
        } else {
          sendCode({ phone: phoneStrTransform(data.phone) });
        }
      } else {
        createOrder({ ...orderingData, user: { ...data } });
      }
    }
  });

  const finalOrderPrice =
    (price || 0) - (useBonuses ? (userBonuses ? userBonuses : 0) : 0);

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <Input
        {...register("firstName", {
          required: { value: true, message: "Данное поле обязательно" },
          onChange: (e) => {
            handleFirstNameChange(e.target.value);
          },
        })}
        invalid={!!errors.firstName}
        invalidMessage={errors.firstName?.message}
        className="w-full"
        label="Имя"
        placeholder="Введите имя"
      />
      <Input
        {...register("lastName", {
          required: { value: true, message: "Данное поле обязательно" },
          onChange: (e) => {
            handleLastNameChange(e.target.value);
          },
        })}
        invalid={!!errors.lastName}
        invalidMessage={errors.lastName?.message}
        className="w-full"
        label="Фамилия"
        placeholder="Введите фамилию"
      />
      <Input
        {...register("middleName", {
          onChange: (e) => {
            handleMiddleNameChange(e.target.value);
          },
        })}
        invalid={!!errors.middleName}
        invalidMessage={errors.middleName?.message}
        className="w-full"
        label="Отчество"
        placeholder="Введите отчество"
      />
      <MaskedInput
        onChange={(e) => {
          handlePhoneChange(phoneStrTransform(e.target.value));
          setValue("phone", phoneStrTransform(e.target.value));
        }}
        invalid={!!errors.phone}
        invalidMessage={errors.phone?.message}
        label="Телефон"
        className="w-full"
        defaultValue={currentPhone}
        type="tel"
        mask="+7 (999) 999 99-99"
        placeholder="Введите телефон"
      />
      {currentCompany?.category.id === COMPANY_CATEGORY.CUSTOMER ? (
        <CustomerCompanyPayment
          isCreatinginvoice={isCreatingInvoice}
          setIsCreatingInvoice={setIsCreatingInvoice}
        />
      ) : (
        user?.id && (
          <>
            <Title>Способ оплаты</Title>
            <IconButton
              className="h-[55px] justify-between"
              iconPlacement="right"
              type="button"
              icon={
                !!bankCardId ? (
                  <SvgCreditCard className="h-[20px] w-[25px]" />
                ) : (
                  <SvgCash className="h-[20px] w-[25px]" />
                )
              }
              onClick={() =>
                setIsChangingPaymentMethod(!isChangingPaymentMethod)
              }
            >
              {!!bankCardId ? "Оплата картой" : "Оплата наличными"}
            </IconButton>
            {userBonuses !== 0 && (
              <label className="mb-[10px] flex items-center justify-between text-sm">
                Использовать {userBonuses} бонусов
                <Switch onChange={handleUseBonuses} checked={useBonuses} />
              </label>
            )}
          </>
        )
      )}
      {isCodeSended && !user?.id ? (
        <div className="mt-[25px] flex items-center">
          <div className="w-full">
            <p className="text-center text-sm text-lightGray">
              Чтобы заврешить создание заказа вам необходимо потдвержить номер
              телефон
            </p>
            <div className="mb-[15px] grid gap-[15px]">
              <PhoneVerificationInput
                {...register("code", { required: true, minLength: 4 })}
                value={otp}
                containerStyle="w-full justify-around my-[10px]"
                inputStyle={{
                  width: 55,
                  height: 55,
                  backgroundColor: "#F9F9F9",
                  borderRadius: 5,
                  fontSize: 18,
                }}
                isInputNum
                isInputSecure
                onChange={(number: string) => setOtp(number)}
                numInputs={4}
              />
              {(!!errors.code || isLoginError) && (
                <div className="text-center text-xs text-red">
                  Код введён неверно
                </div>
              )}
            </div>
            {reloadTime && reloadTime > 0 && (
              <p className="mb-[10px] flex justify-center text-center text-sm text-lightGray">
                Запросите код повторно через{" "}
                <span className="ml-[3px] block w-[25px] text-primary">
                  {timeFormatter({
                    time: new Date(reloadTime),
                    mode: "mm:ss",
                  })}
                </span>
                .
              </p>
            )}

            <div className="grid gap-[15px]">
              <Button
                className="w-full"
                mode="darkBorder"
                disabled={
                  phoneStrTransform(currentPhone || "").length !== 11 ||
                  isSendCodeLoading ||
                  !!reloadTime
                }
                onClick={() => sendCode({ phone: currentPhone })}
                type="button"
              >
                Получить новый код
              </Button>
              <Button
                className="w-full"
                type="submit"
                disabled={otp?.length !== 4 || isLoginLoading}
              >
                Подтвердить
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 items-center gap-[15px] ">
          {finalOrderPrice > 0 && (
            <Title className="text-xl">{`${finalOrderPrice} руб.`}</Title>
          )}
          {user?.id ? (
            <Button
              type="submit"
              disabled={
                (currentCompany?.category.id === COMPANY_CATEGORY.CUSTOMER &&
                  currentCompany.balance < (finalOrderPrice || 0)) ||
                (!bankCardId && paymentMethod === "card") ||
                isLoading
              }
            >
              Оформить
            </Button>
          ) : (
            <>
              <Button type="submit" disabled={isSendCodeLoading}>
                Продолжить
              </Button>
              {isSendCodeError && (
                <div className="mt-[10px] text-center text-xs text-red">
                  При отправке кода верификации произошла ошибка, повторите
                  попытку позже
                </div>
              )}
            </>
          )}
        </div>
      )}
    </form>
  );
};
