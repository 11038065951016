import { FC, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../../common/components/Button/Button";
import { VehicleCharacteristics } from "../../../common/components/Vehicle/VehicleCharacteristics";
import { SvgSuccess } from "../../../assets/icons/SvgSuccess";
import { VehicleFormValues } from "../../../common/models/forms/VehicleFormValues";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import {
  useDeleteVehicleMutation,
  useGetCompanyVehicleByIdQuery,
  useUpdateVehicleMutation,
} from "../../../services/vehicle/vehiclesApi";
import { VehicleDriverEdit } from "./VehicleDriverEdit";
import { SvgPlus } from "../../../assets/icons/SvgPlus";
import { WideIconButton } from "../../../common/components/Button/WideIconButton";
import { VehicleCurrentDrivers } from "./VehicleCurrentDrivers";
import { useUploadSignleImageMutation } from "../../../services/fileApi";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "../../../common/utils/consts";
import { getErrorMessage } from "../../../common/utils/helpers";
import { IVehicleInputs } from "common/models/api/IVehicleInputs";
import { IVehicleEditProps } from "common/models/components/vehicle/IVehicleEditProps";
import { useAppDispatch } from "app/hooks";
import { setActiveVehicle } from "./vehiclesSlice";

export const VehicleEdit: FC<IVehicleEditProps> = ({
  vehicleId,
  companyId,
}) => {
  const dispatch = useAppDispatch();

  const handleRemoveActiveVehicle = () => {
    dispatch(setActiveVehicle({ id: undefined }));
  };

  const {
    data: vehicle,
    isLoading,
    isFetching,
    error,
  } = useGetCompanyVehicleByIdQuery({ companyId, vehicleId });

  const [uploadSts, { error: uploadingImageError, reset: resetStsUpload }] =
    useUploadSignleImageMutation();

  const [
    deleteVehicle,
    {
      error: deleteVehicleError,
      isLoading: isDeleteVehicleLoading,
      isSuccess: isDeletingSuccess,
    },
  ] = useDeleteVehicleMutation();

  const [
    updateVehicle,
    {
      isSuccess: isUpdatingSuccess,
      isLoading: isUpdatingLoading,
      error: updateError,
      reset: resetUpdate,
    },
  ] = useUpdateVehicleMutation();

  const methods = useForm<VehicleFormValues>({
    mode: "all",
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  const [isAddingSuccess, setIsAddingSuccess] = useState<boolean>(false);

  const [isEditDriver, setIsEditDriver] = useState<boolean>(false);

  const { showModal } = useGlobalModalContext();

  const handlerSetIsEditingDriver = () => setIsEditDriver((prev) => !prev);

  const showErrorModal = (title: string, message?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: title,
      subTitle:
        message ||
        "При обновлении техники возникла ошибка, повторите попытку позже",
    });
  };

  const showConfirmationModal = () => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      title: "Удаление техники",
      subTitle: "Вы уверены чот хотите удалить данный транспорт?",
      onConfirm: () => {
        deleteVehicle({ vehicleId, companyId });
      },
    });
  };

  useEffect(() => {
    if (uploadingImageError) {
      showErrorModal(
        "Ошибка при загрузке фото СТС",
        getErrorMessage(uploadingImageError)?.data.message
      );
    }
    if (updateError) {
      showErrorModal(
        "Ошибка при обновлении",
        getErrorMessage(updateError)?.data.message
      );
    }
    if (deleteVehicleError) {
      showErrorModal(
        "Ошибка удалении транспорта",
        getErrorMessage(deleteVehicleError)?.data.message
      );
    }
  }, [uploadingImageError, updateError, deleteVehicleError]);

  useEffect(() => {
    setIsEditDriver(false);
    setIsAddingSuccess(false);
    resetStsUpload();
    resetUpdate();
  }, [vehicleId]);

  useEffect(() => {
    if (isDeletingSuccess) {
      handleRemoveActiveVehicle();
    }
  }, [isDeletingSuccess]);

  useEffect(() => {
    if (isUpdatingSuccess) {
      setIsAddingSuccess(true);
    }
  }, [isUpdatingSuccess]);

  const onSubmit = handleSubmit(async (data) => {
    const vehicleFormValues: IVehicleInputs = {
      company_id: companyId,
      vehicle_id: vehicleId,
      chassis_model_id: data.main.chassisModel?.value || -1,
      kmu_model_id: data.main.kmuModel.value,
      search_radius_id: data.main.coverageRadius,
      vehicle_number: data.main.governmentNumber,
      sts_number: data.main.vrcNumber,
      location_address: data.main.location,
      arrow_carrying_capacity: data.specifications.boomCapacity,
      arrow_length: data.specifications.boomLength,
      board_carrying_capacity: data.specifications.sideLoadCapacity,
      vehicle_length: data.specifications.dimensions.length,
      vehicle_width: data.specifications.dimensions.width,
      vehicle_height: data.specifications.dimensions.height,
      options: data.specifications.optionalEquipment
        .filter((el) => el)
        .map((el) => +el),
    };

    if (
      typeof data.main.vrcPhoto === "object" &&
      data.main.vrcPhoto.length > 0
    ) {
      const formData = new FormData();
      formData.append("file_type_id", "7");
      formData.append("file", data.main.vrcPhoto[0]);
      const image = await uploadSts(formData).unwrap();
      updateVehicle({ ...vehicleFormValues, sts_photo_id: image.id });
    } else {
      updateVehicle(vehicleFormValues);
    }
    resetStsUpload();
    resetUpdate();
  });

  return (
    <div className="mt-[40px]">
      {isEditDriver && vehicle ? (
        <VehicleDriverEdit
          currentDrivers={vehicle.drivers}
          companyId={companyId}
          vehicleId={vehicleId}
          setEditDrivers={handlerSetIsEditingDriver}
        />
      ) : (
        <QueryLayout
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
        >
          {vehicle && vehicle.drivers.length ? (
            <VehicleCurrentDrivers
              drivers={vehicle.drivers}
              setEditDrivers={handlerSetIsEditingDriver}
            />
          ) : (
            !isEditDriver && (
              <WideIconButton
                className="mb-[10px] w-full text-sm font-medium text-gray"
                icon={<SvgPlus className="h-[16px] w-[16px] text-primary" />}
                onClick={() => handlerSetIsEditingDriver()}
              >
                Водитель ещё не назначен
              </WideIconButton>
            )
          )}
          {isAddingSuccess ? (
            <div className="flex min-h-full w-full flex-col items-center justify-center text-primary">
              <SvgSuccess className="mb-[17px] h-[57px] w-[57px]" />
              <p className="text-base font-semibold">
                Ваша техника успешно изменена
              </p>
            </div>
          ) : (
            <form onSubmit={onSubmit}>
              <FormProvider {...methods}>
                <VehicleCharacteristics vehicle={vehicle} />
              </FormProvider>
              <div className="grid grid-cols-2 gap-[15px]">
                <Button
                  disabled={
                    Object.keys(errors).length !== 0 ||
                    !isDirty ||
                    isUpdatingLoading
                  }
                  type="submit"
                  className="text-base font-medium tracking-wider"
                >
                  Сохранить
                </Button>
                <Button
                  className="text-base font-medium tracking-wider"
                  mode="lightBorder"
                  type="button"
                  onClick={showConfirmationModal}
                  disabled={isDeleteVehicleLoading}
                >
                  Удалить
                </Button>
              </div>
            </form>
          )}
        </QueryLayout>
      )}
    </div>
  );
};
