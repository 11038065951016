import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgListDashes } from "../../../assets/icons/SvgListDashes";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { Route, Routes } from "react-router-dom";
import { IndividualTable } from "../tables/users/customers/IndividualTable";
import { SvgLoupe } from "../../../assets/icons/SvgLoupe";
import { IconInput } from "../../../common/components/Input/IconInput";
import { CustomerCompanyTable } from "../tables/users/customers/CustomerCompanyTable";
import { setDateFrom, setDateTo, setSearchText } from "./usersSlice";
import { WorkForHimSelfTable } from "../tables/users/implementer/WorkForHimSelfTable";
import { DriverTable } from "../tables/users/implementer/DriverTable";
import { ManagerTable } from "../tables/users/implementer/ManagerTable";
import { CompanyTable } from "../tables/users/implementer/CompanyTable";

export const UsersPage: FC = () => {
  const searchText = useAppSelector(
    (state) => state.administrator.users.filters.searchText
  );
  const dateFrom = useAppSelector(
    (state) => state.administrator.users.filters.dateFrom
  );
  const dateTo = useAppSelector(
    (state) => state.administrator.users.filters.dateTo
  );

  const dispatch = useAppDispatch();

  const handleSetSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchText(event.target.value));
  };

  const handleDisputesDateFromChange = (date: Nullable<Date>) => {
    dispatch(setDateFrom(date?.toISOString() || undefined));
  };

  const handleDisputesDateToChange = (date: Nullable<Date>) => {
    dispatch(setDateTo(date?.toISOString() || undefined));
  };

  return (
    <>
      <div className="grid w-full grid-cols-[1fr,245px,100px] gap-[10px]">
        <IconInput
          className="h-[35px] w-full"
          placeholder="Поиск по пользователям"
          icon={<SvgLoupe className="h-[15px] w-[15px] text-gray" />}
          value={searchText}
          onChange={handleSetSearchText}
        />
        <div>
          <DatePicker
            className="h-[35px] w-full"
            onStartDateChange={handleDisputesDateFromChange}
            onEndDateChange={handleDisputesDateToChange}
            placeholderText="Введите промежуток дат"
            isClearable={true}
          />
        </div>
        <button
          className="flex h-[35px] items-center justify-center rounded border border-transparent bg-lightWhiteGray px-[8px] text-sm font-medium
        outline-none transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary active:text-black"
        >
          <SvgListDashes className="mr-[5px] h-[16px] w-[16px]" />
          Фильтр
        </button>
      </div>
      <div className="px-[5px]">
        <Routes>
          <Route
            path="/customers/individual"
            element={
              <IndividualTable
                query={searchText}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            }
          />
          <Route
            path="/customers/companies"
            element={
              <CustomerCompanyTable
                query={searchText}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            }
          />
          <Route
            path="/performers/self-employeed"
            element={
              <WorkForHimSelfTable
                query={searchText}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            }
          />
          <Route
            path="/performers/drivers"
            element={
              <DriverTable
                query={searchText}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            }
          />
          <Route
            path="/performers/managers"
            element={
              <ManagerTable
                query={searchText}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            }
          />
          <Route
            path="/performers/companies"
            element={
              <CompanyTable
                query={searchText}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
};
