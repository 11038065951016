import { FC, useState, useEffect } from "react";
import { SvgLogo } from "../../../assets/logo/SvgLogo";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "../../../common/components/Button/Button";
import { SvgGooglePlay } from "../../../assets/icons/SvgGooglePlay";
import { SvgAppStore } from "../../../assets/icons/SvgAppStore";
import { SvgCaretUp } from "../../../assets/icons/SvgCaretUp";

export const GuestHeader: FC = () => {
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (dropdownOpen) {
      window.addEventListener("click", handleClose, { once: true });
    }
  }, [dropdownOpen]);

  const handleClose = () => {
    setDropdownOpen(false);
  };

  const handleOpen = () => {
    setDropdownOpen(true);
  };

  return (
    <header className="flex h-[65px] items-center justify-between px-[15px] shadow-lg">
      <div>
        <NavLink
          to="/"
          className="flex w-full items-center justify-center pt-[15px] pb-[18px] text-black outline-none hover:text-primary focus:text-primary active:text-black"
        >
          <SvgLogo className="mr-[15px] h-[36px] w-[36px] transition" />
          <p className="leading-4 transition">
            Аренда манипуляторов и автокранов
          </p>
        </NavLink>
      </div>
      <nav className="grid w-full grid-cols-[1fr,auto]">
        <div className="ml-auto mr-auto h-full w-fit">
          <div className="grid h-full grid-cols-3 items-center gap-[25px]">
            <div className="relative" onMouseLeave={handleClose}>
              <NavLink
                to="/about"
                className="flex h-full items-center text-center font-bold text-black outline-none transition hover:text-primary focus:text-primary active:text-black"
                onMouseEnter={handleOpen}
                onClick={handleOpen}
                onFocus={handleOpen}
              >
                <span className="mr-[7px]">О сервисе</span>
                <SvgCaretUp className="h-[6px] w-[10px] rotate-180" />
              </NavLink>
              <ul
                className={`absolute right-0 z-20 min-w-[170px] rounded bg-white p-[10px] text-sm shadow-lg ${
                  dropdownOpen ? "block" : "hidden"
                }`}
              >
                <li>
                  <NavLink
                    to="/privacy-policy"
                    className="block py-[5px] text-left outline-none transition hover:text-primary focus:text-primary active:text-black"
                  >
                    Политика конфиденциальности
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/faq"
                    className="block py-[5px] text-left outline-none transition hover:text-primary focus:text-primary active:text-black"
                  >
                    Часто задаваемые вопросы
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/use-rules"
                    className="block py-[5px] text-left outline-none transition hover:text-primary focus:text-primary active:text-black"
                  >
                    Правила пользования
                  </NavLink>
                </li>
              </ul>
            </div>
            <NavLink
              to="/"
              className="flex h-full items-center justify-center text-center font-bold text-black outline-none transition hover:text-primary focus:text-primary active:text-black"
            >
              Клиенту
            </NavLink>
            <NavLink
              to="/"
              className="flex h-full items-center justify-center text-center font-bold text-black outline-none transition hover:text-primary focus:text-primary active:text-black"
            >
              Водителю
            </NavLink>
          </div>
        </div>

        <div className="grid grid-cols-[140px,140px,90px] items-center justify-end gap-[15px]">
          <a
            href="http://play.google.com/store/apps/details?id=com.google.android.apps.maps"
            target="_blanck"
            className="flex h-[40px] items-center rounded border border-lightWhiteGray py-[5px] px-[15px]
          outline-none transition hover:border-black focus:border-black active:border-lightWhiteGray"
          >
            <SvgGooglePlay className="mr-[15px] h-[22px] w-[20px]" />
            <div className="grid">
              <span className="text-xs font-medium text-lightGray">
                Загрузить в
              </span>
              <h4 className="text-sm font-bold text-[#000000]">Google Play</h4>
            </div>
          </a>
          <a
            href="http://appstore.com/gameloft"
            target="_blanck"
            className="flex h-[40px] items-center rounded border border-lightWhiteGray py-[5px] px-[15px]
            outline-none transition hover:border-black focus:border-black active:border-lightWhiteGray"
          >
            <SvgAppStore className="mr-[15px] h-[23px] w-[19px]" />
            <div className="grid">
              <span className="text-xs font-medium text-lightGray">
                Загрузить в
              </span>
              <h4 className="text-sm font-bold text-[#000000]">App Store</h4>
            </div>
          </a>
          <Button
            className="h-[40px] font-medium"
            onClick={() => navigate("/auth")}
          >
            Войти
          </Button>
        </div>
      </nav>
    </header>
  );
};
