import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { Container } from "../../../common/components/Container/Container";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { ProfileCard } from "../../../common/components/Profile/ProfileCard";
import { Title } from "../../../common/components/Title/Title";
import { TransactionInfo } from "../../../common/components/Transaction/TransactionInfo";
import {
  setActiveTransactionId,
  setTransactionStatus,
} from "./transactionsSlice";
import { useGetAllTransactionsQuery } from "../../../services/transactionApi";
import { NotFoundTitle } from "../../../common/components/Title/NotFoundTitle";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { Tabs } from "common/components/Tabs/Tabs";
import { timeFormatter } from "common/utils/helpers";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const TransactionsPage: FC = withErrorBoundary(() => {
  const currentBalance = useAppSelector(
    (state) => state.user.current?.account?.company?.balance
  );
  const currentCompany = useAppSelector(
    (state) => state.user.current?.account?.company
  );
  const activeTransactionId = useAppSelector(
    (state) => state.customerCompanyManager.transactions.activeTransactionId
  );
  const transactionStatus = useAppSelector(
    (state) => state.customerCompanyManager.transactions.transactionStatus
  );

  const {
    data: transactions,
    isLoading,
    isFetching,
    error,
  } = useGetAllTransactionsQuery({});

  const dispatch = useAppDispatch();

  const handlerSetActiveTransactionId = (id: number) => {
    dispatch(setActiveTransactionId(id));
  };

  const handleSetTransactionStatus = (id: number) => {
    dispatch(setTransactionStatus(id));
  };

  return (
    <Container>
      <div className="grid h-full items-baseline gap-[12px] overflow-hidden">
        <div className="h-full overflow-y-scroll">
          <Title className="mb-[15px]">Транзакции</Title>
          <QueryLayout
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
          >
            <div className="grid gap-[15px]">
              {transactions?.length ? (
                transactions.map((transaction) => (
                  <button
                    key={transaction.id}
                    className={`rounded border text-left ${
                      activeTransactionId === transaction.id
                        ? "border-primary"
                        : "border-transparent"
                    } transition hover:border-primary focus:border-primary active:border-transparent`}
                    onClick={() =>
                      handlerSetActiveTransactionId(transaction.id)
                    }
                  >
                    <div className="rounded border border-whiteGray p-[15px]">
                      <div className="mb-[10px] flex items-center justify-between">
                        <div className="text-md font-medium text-primary">
                          {`${
                            (transaction.credit || transaction.debit) > 0
                              ? "+"
                              : "-"
                          } ${transaction.credit || transaction.debit} руб.`}
                        </div>
                        <time className="text-xs text-lightGray">
                          {timeFormatter({
                            time: new Date(transaction.created_at),
                          })}
                        </time>
                      </div>
                      <p className="text-xs text-lightGray">
                        {transaction?.order?.id && (
                          <span className="mr-[5px] text-black">
                            Заказ # {transaction?.order?.id}.
                          </span>
                        )}

                        {transaction.description}
                      </p>
                    </div>
                  </button>
                ))
              ) : (
                <NotFoundTitle />
              )}
            </div>
          </QueryLayout>
        </div>
      </div>

      <div className="overflow-hidden">
        <div className="grid h-full grid-rows-[30px,1fr] gap-[15px] overflow-y-scroll">
          <div className="grid grid-cols-2 items-center">
            <Tabs
              tabs={[
                { value: 0, label: "Все" },
                { value: 1, label: "Новые" },
                { value: 2, label: "Завершённые" },
              ]}
              value={transactionStatus}
              onTabClick={(value) => handleSetTransactionStatus(value)}
            />
            <div className="ml-auto text-lg font-semibold text-primary">
              Баланс: <span>{currentBalance}</span>руб
            </div>
          </div>
          <div className="grid h-full overflow-y-scroll">
            {!activeTransactionId ? (
              <div>
                
                {currentCompany && (
                  <div className="rounded border border-whiteGray p-[12px]">
                    <ProfileCard
                      className="mb-[10px]"
                      isShortMode
                      balance={currentBalance}
                      name={currentCompany.name}
                      subname={currentCompany.category.name}
                      avatarurl={currentCompany.logotype?.path}
                    />
                    <div className="flex items-center justify-between">
                      <div className="text-md font-semibold">
                        <span className="mr-[3px]">
                          {currentCompany.balance}
                        </span>
                        руб
                      </div>
                      <Button
                        className="h-[30px] py-[8px] text-xs font-medium leading-[12px]"
                        mode="yellow"
                      >
                        Транзакции
                      </Button>
                    </div>
                  </div>
                )}
                <InformationBlock className="mt-[10px]" />
              </div>
            ) : (
              <TransactionInfo transactionId={activeTransactionId} />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
});
