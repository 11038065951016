import { FC, useMemo } from "react";
import { timeFormatter } from "../../utils/helpers";
import { IAppealCardProps } from "common/models/components/appeal/IAppealCardProps";

export const AppealCard: FC<IAppealCardProps> = ({
  id,
  time,
  status,
  appealId,
  messageCount,
  isActive,
  ...props
}) => {
  const userClasses = props.className || "";

  const appealTime = useMemo(() => timeFormatter({ time: time }), [time]);

  return (
    <div
      {...props}
      className={`flex flex-col rounded border p-[12px] ${
        isActive ? "border-primary" : "border-whiteGray"
      } bg-lightWhiteGray transition ${userClasses}`}
    >
      <div className="mb-[3px] flex items-center justify-between">
        <h4 className="text-sm font-medium">Обращение # {appealId}</h4>
        <time className="mb-[5px] text-xs text-lightGray">{appealTime}</time>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-xs leading-4 text-primary">{status}</div>
        {messageCount !== 0 && (
          <div className="flex h-[15px] w-[15px] items-center justify-center rounded-[50%] bg-primary text-xxs font-medium text-white">
            <span className="mt-[2px]">{messageCount}</span>
          </div>
        )}
      </div>
    </div>
  );
};
