import { FC, useCallback } from "react";
import { useAppSelector } from "../../../app/hooks";
import { SvgArrowInCircle } from "../../../assets/icons/SvgArrowInCircle";
import { Button } from "../Button/Button";
import { VehicleInput } from "../Vehicle/VehicleInput";
import { VehicleListItem } from "../Vehicle/VehicleListItem";
import { useGetVehicleCategoriesQuery } from "../../../services/vehicle/vehiclesApi";
import { QueryLayout } from "../Layout/QueryLayout";
import { IAdminOrderFilter } from "common/models/admin/IAdminOrderFilter";
import { useGetVehicleTypesQuery } from "services/vehicle/vehicleTypesApi";

export const AdminOrdersFilter: FC<IAdminOrderFilter> = ({
  setIsFilterOpen,
  handleFilterReset,
  handleFilterVehicleCategoryChange,
  handleFilterVehicleTypeChange,
  stateSelector,
}) => {
  const filters = useAppSelector((state) => stateSelector(state));

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isFetching: isCategoriesFetching,
    error: categoriesError,
  } = useGetVehicleCategoriesQuery();
  const {
    data: types,
    isLoading: isTypesLoading,
    isFetching: isTypesFetching,
    error: typesError,
  } = useGetVehicleTypesQuery(filters.vehicleCategory, {
    skip: !filters.vehicleCategory?.length,
  });

  const handleFilterClose = useCallback(() => {
    setIsFilterOpen(false);
  }, []);

  return (
    <div
      className={`pointer-events-auto absolute top-[160px] bottom-[15px] right-[15px] z-20 overflow-hidden overflow-y-scroll rounded p-[5px]`}
    >
      <div
        className={`top-[65px] h-fit w-[720px] overflow-y-scroll rounded bg-white p-[15px] shadow-lg
        `}
      >
        <button
          className="absolute top-[10px] right-[10px] z-10 h-[32px] w-[32px] text-black 
        outline-none transition hover:text-primary focus:text-primary active:text-black"
          onClick={handleFilterClose}
        >
          <SvgArrowInCircle className="text-inherit" />
        </button>
        <h3 className="mb-[25px] text-center text-lg font-semibold">Фильтры</h3>
        <div className="grid grid-cols-2 gap-[15px]">
          <div>
            <div className="mb-[25px]">
              <h4 className="mb-[15px] text-center text-base font-semibold">
                Тип Техники
              </h4>
              <QueryLayout
                isLoading={isCategoriesLoading}
                isFetching={isCategoriesFetching}
                error={categoriesError}
              >
                {categories && (
                  <ul className="grid gap-[15px]">
                    {categories.map((category) => (
                      <VehicleInput
                        type="checkbox"
                        key={category.id}
                        title={category.name}
                        checked={filters.vehicleCategory?.includes(category.id)}
                        onChange={() =>
                          handleFilterVehicleCategoryChange(category.id)
                        }
                        innerelement={
                          <VehicleListItem
                            name={category.name}
                            img={category.icon.path}
                            mode="short"
                          />
                        }
                      />
                    ))}
                  </ul>
                )}
              </QueryLayout>
            </div>
          </div>
          <div>
            <div className="mb-[25px]">
              <h4 className="mb-[15px] text-center text-base font-semibold">
                Тариф
              </h4>
              <QueryLayout
                isLoading={isTypesLoading}
                isFetching={isTypesFetching}
                error={typesError}
              >
                {types && filters.vehicleCategory.length ? (
                  <ul className="grid gap-[15px]">
                    {types.map((type) => (
                      <VehicleInput
                        type="checkbox"
                        key={type.id}
                        title={type.name}
                        checked={filters.vehicleType?.includes(type.id)}
                        onChange={() => handleFilterVehicleTypeChange(type.id)}
                        innerelement={
                          <VehicleListItem
                            name={type.name}
                            mode="short"
                            img={type.category.icon.path}
                          />
                        }
                      />
                    ))}
                  </ul>
                ) : (
                  <div className="text-center">Выберите тип техники</div>
                )}
              </QueryLayout>
            </div>
          </div>
        </div>
        <div className="grid gap-[15px]">
          <Button
            className="text-base font-medium"
            mode="darkBorder"
            onClick={handleFilterReset}
          >
            Сбросить
          </Button>
          <Button className="text-base font-medium" onClick={handleFilterClose}>
            Продолжить
          </Button>
        </div>
      </div>
    </div>
  );
};
