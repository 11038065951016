import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { KmuModelFormInputs } from "./KmuModelFormInputs";
import { Button } from "../../../../../common/components/Button/Button";
import { useParams } from "react-router-dom";
import { ICreateVehicleKmuModel } from "common/models/vehicles/IVehicleKmuModel";
import { Error } from "common/components/Message/Error/Error";
import { getErrorMessage } from "common/utils/helpers";
import { Success } from "common/components/Message/Success/Success";
import {
  useGetKmuModelByIdQuery,
  useUpdateKmuModelMutation,
} from "services/vehicle/kmuApi";

export const KmuModelEdit: FC = () => {
  const { id } = useParams();

  const {
    data: model,
    isLoading: isModelLoading,
    error: modelError,
  } = useGetKmuModelByIdQuery(id ? +id : -1, { skip: !id });

  const [update, { isLoading: isUpdating, error: updateError, isSuccess }] =
    useUpdateKmuModelMutation();

  const methods = useForm<ICreateVehicleKmuModel>();

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (model) {
      reset({
        ...model,
        vehicle_category_id: model.vehicle_category.id,
        kmu_brand_id: model.kmu_brand.id,
      });
    }
  }, [model]);

  const onSubmit = handleSubmit((data) => {
    update(data);
  });

  if (modelError || updateError) {
    return (
      <Error>{getErrorMessage(modelError || updateError)?.data.message}</Error>
    );
  }

  if (isSuccess) {
    return <Success>Модель успешно отредактирована!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <KmuModelFormInputs />
      </FormProvider>
      <Button
        className="w-fit"
        disabled={!isDirty || isUpdating || isModelLoading}
      >
        Изменить
      </Button>
    </form>
  );
};
