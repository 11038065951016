import { FC, useEffect, memo } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Button } from "../../../common/components/Button/Button";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { List } from "../../../common/components/List/List";
import { Title } from "../../../common/components/Title/Title";
import { getErrorMessage, timeFormatter } from "../../../common/utils/helpers";
import { setAvtiveCompanyId } from "./companyModerationSlice";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "../../../common/utils/consts";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";
import { CompanyEdit } from "common/components/Company/CompanyEdit";
import {
  useGetNotVerifiedCompaniesQuery,
  useGetCompanyByIdQuery,
  useRejectCompanyMutation,
  useVerifyCompanyMutation,
} from "services/admin/adminCompaniesApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";
import { CompanyProfileCard } from "common/components/Profile/CompanyProfileCard";
import { DetailedUserProfileCard } from "common/components/Profile/DetailedUserProfileCard";
import { ProfileCard } from "common/components/Profile/ProfileCard";

export const CompanyModerationPage: FC = withErrorBoundary(
  memo(() => {
    const activeCompanyId = useAppSelector(
      (state) => state.platformManager.companyModeration.activeCompanyId
    );

    const dispatch = useAppDispatch();

    const { showModal } = useGlobalModalContext();

    const {
      data: companies,
      isLoading: isCompaniesLoading,
      isFetching: isCompaniesfetching,
      error: companiesError,
    } = useGetNotVerifiedCompaniesQuery();

    const {
      data: company,
      isLoading: isCompanyLoading,
      error: companyError,
    } = useGetCompanyByIdQuery(activeCompanyId || -1, {
      skip: !activeCompanyId,
    });

    const [verify, { isLoading: isVerifying, error: verifyError }] =
      useVerifyCompanyMutation();

    const [reject, { isLoading: isRejecting, error: rejectError }] =
      useRejectCompanyMutation();

    useEffect(() => {
      if (rejectError || verifyError) {
        showErrorModal(
          getErrorMessage(rejectError || verifyError)?.data.message
        );
      }
    }, [verifyError, rejectError]);

    const showErrorModal = (erorrText?: string) => {
      showModal(MODAL_TYPES.ERROR_MODAL, {
        title: "Ошибка при одобрении/отказе компании",
        subTitle:
          erorrText ||
          "При одобрении/отказе компании произошла ошибка, повторите попытку позже",
      });
    };

    const showReportModal = () => {
      showModal(MODAL_TYPES.REPORT_MODAL);
    };

    const showVerifyConfirmationModal = () => {
      if (activeCompanyId) {
        showModal(MODAL_TYPES.CONFIRM_MODAL, {
          subTitle: "Вы уверены что хотите одобрить данную компанию?",
          confirmText: "Компания успешно одобрена",
          onConfirm: () => verify({ id: activeCompanyId }),
        });
      }
    };

    const showRejectConfirmationModal = () => {
      if (activeCompanyId) {
        showModal(MODAL_TYPES.CONFIRM_MODAL, {
          subTitle: "Вы уверены что хотите отклонить данную компанию?",
          confirmText: "Компания успешно отклонена",
          onConfirm: () => reject({ id: activeCompanyId }),
        });
      }
    };

    const handleSetActiveRegistrationId = (id: number) => {
      dispatch(setAvtiveCompanyId(id));
    };

    return (
      <>
        <div className="grid h-full items-baseline gap-[12px] overflow-y-scroll pb-[9px]">
          <QueryLayout isLoading={isCompaniesLoading} error={companiesError}>
            {companies?.length ? (
              <div className="pointer-events-auto grid h-full items-baseline gap-[12px] overflow-y-scroll">
                <div
                  className={`grid gap-[12px] ${
                    isCompaniesfetching ? "animate-pulse" : ""
                  }`}
                >
                  <List>
                    {companies?.map((el) => (
                      <li key={el.id}>
                        <button
                          onClick={() => handleSetActiveRegistrationId(el.id)}
                          className={`flex h-fit w-full items-center justify-between rounded border bg-lightWhiteGray p-[12px] outline-none transition
                    hover:border-primary focus:border-primary active:border-whiteGray
                    ${
                      activeCompanyId === el.id
                        ? "border-primary"
                        : "border-whiteGray"
                    }`}
                        >
                          <div className="text-left text-sm font-medium">
                            {el.name}
                          </div>
                          <time className="whitespace-nowrap text-right text-xs">
                            {timeFormatter({
                              time: new Date(el.created_at),
                              mode: "dd-Mth-yyyy hh:mm",
                            })}
                          </time>
                        </button>
                      </li>
                    ))}
                  </List>
                </div>
              </div>
            ) : (
              <NotFoundTitle />
            )}
          </QueryLayout>
        </div>
        <QueryLayout isLoading={isCompanyLoading} error={companyError}>
          {activeCompanyId && company ? (
            <>
              <div className="mb-[15px] grid grid-rows-[30px,1fr,160px] gap-[10px] overflow-y-hidden">
                <Title className="flex items-center">
                  Информация о компании
                </Title>
                <div className="overflow-y-scroll">
                  <CompanyEdit
                    companyId={company.id}
                    isModeration
                    readOnly
                    isOpen
                  />
                </div>
                <div className="grid gap-[15px]">
                  <Button
                    disabled={isVerifying || isRejecting}
                    onClick={showVerifyConfirmationModal}
                  >
                    Одобрить
                  </Button>
                  <Button
                    disabled={isVerifying || isRejecting}
                    onClick={showRejectConfirmationModal}
                  >
                    Отклонить
                  </Button>
                  <Button onClick={showReportModal} mode="darkBorder">
                    Отправить разработчику
                  </Button>
                </div>
              </div>
              <div className="mb-[15px] grid grid-rows-[30px,1fr] gap-[15px] overflow-y-hidden">
                <Title>Руководитель организации</Title>
                <div className="h-fit rounded border border-whiteGray p-[8px]">
                  <DetailedUserProfileCard
                    className="w-full"
                    status=""
                    userId={company.owner.id}
                    registrationDate={new Date(company.owner.created_at)}
                    avatarurl={company.owner.avatar}
                    name={`${company.owner.last_name} ${
                      company.owner.first_name
                    } ${company.owner.middle_name || ""}`}
                  />
                </div>
              </div>
            </>
          ) : (
            <InformationBlock className="col-span-2 w-full" />
          )}
        </QueryLayout>
      </>
    );
  })
);
