import { ICauseProps } from "common/models/components/ui/ICauseProps";
import { FC, memo } from "react";

export const Cause: FC<ICauseProps> = memo((props) => {
  const userClasses = props.className || "";

  const specClass = props.primary
    ? "bg-primary text-white"
    : "bg-lightWhiteGray text-black";

  return (
    <div
      {...props}
      className={`mr-[10px] mb-[10px] whitespace-nowrap rounded py-[8px] px-[18px] text-center text-xs font-medium transition ${specClass} ${userClasses}`}
    >
      {props.children}
    </div>
  );
});
