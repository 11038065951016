import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { List } from "../../../common/components/List/List";
import { Tabs } from "../../../common/components/Tabs/Tabs";
import {
  setAvtiveInvoiceId,
  setInvoiceDateFrom,
  setInvoiceDateTo,
  setInvoiceStatus,
} from "./invoiceSlice";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";
import {
  useGetAllInvoicesQuery,
  useGetInvoiceStatusesQuery,
} from "services/invoicesApi";
import { InvoiceCard } from "common/components/invoice/InvoiceCard";

export const InvoiceTabs: FC = () => {
  const dispatch = useAppDispatch();
  const invocieStatusId = useAppSelector(
    (state) => state.platformManager.invoice.invoiceStatus
  );
  const activeInvoiceId = useAppSelector(
    (state) => state.platformManager.invoice.currentInvoice?.invoiceId
  );
  const invoiceDateFrom = useAppSelector(
    (state) => state.platformManager.invoice.invoiceDateFrom
  );
  const invoiceDateTo = useAppSelector(
    (state) => state.platformManager.invoice.invoiceDateTo
  );

  const {
    data: invoiceStatuses,
    isLoading: isInvoiceStatusesLoading,
    isFetching: isInvoiceStatusesFetching,
    error: invoiceStatusesError,
  } = useGetInvoiceStatusesQuery();

  const {
    data: invoices,
    isLoading,
    isFetching,
    error,
  } = useGetAllInvoicesQuery({
    statusId: [invocieStatusId],
    dateFrom: invoiceDateFrom,
    dateTo: invoiceDateTo,
  });

  const invoiceStatusesArr = invoiceStatuses?.map((status) => ({
    value: status.id,
    label: status.name,
  }));

  const handleSetAppealsStatus = (status: number) => {
    dispatch(setInvoiceStatus(status));
  };

  const handleSetActiveInvoiceId = (data: {
    invoiceId: number;
    accountId: number;
    companyId: number;
  }) => {
    dispatch(setAvtiveInvoiceId(data));
  };

  const handleDisputesDateFromChange = (date: Nullable<Date>) => {
    dispatch(setInvoiceDateFrom(date?.toISOString()));
  };

  const handleDisputesDateToChange = (date: Nullable<Date>) => {
    dispatch(setInvoiceDateTo(date?.toISOString()));
  };

  return (
    <>
      {invoiceStatusesArr && (
        <Tabs
          className="whitespace-nowrap"
          tabs={invoiceStatusesArr}
          value={invocieStatusId}
          onTabClick={handleSetAppealsStatus}
        />
      )}
      <div>
        <DatePicker
          onStartDateChange={handleDisputesDateFromChange}
          onEndDateChange={handleDisputesDateToChange}
          placeholderText="Введите промежуток дат"
          isClearable={true}
        />
      </div>
      <List className="grid h-full items-baseline gap-[12px] overflow-y-scroll pt-[9px]">
        <QueryLayout
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
        >
          {invoices?.length ? (
            <div className="grid gap-[15px]">
              {invoices.map((invoice) => (
                <button
                  key={invoice.id}
                  className={`rounded border ${
                    activeInvoiceId === invoice.id
                      ? "border-primary"
                      : "border-transparent"
                  } transition hover:border-primary focus:border-primary active:border-transparent`}
                  onClick={() =>
                    handleSetActiveInvoiceId({
                      invoiceId: invoice.id,
                      companyId: invoice.company?.id || -1,
                      accountId: invoice?.creator?.id,
                    })
                  }
                >
                  <InvoiceCard
                    transactionId={invoice.id}
                    status={invoice.status.name}
                    desc={"описание"}
                    orderId={80}
                    amount={invoice.amount}
                    time={new Date(invoice.created_at)}
                  />
                </button>
              ))}
            </div>
          ) : (
            <NotFoundTitle />
          )}
        </QueryLayout>
      </List>
    </>
  );
};
