import { IDivProps } from "common/models/components/ui/IDivProps";
import { FC, memo } from "react";

export const InformationBlock: FC<IDivProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <div
      {...props}
      className={`flex h-fit justify-center rounded border border-whiteGray p-[15px] ${userClasses}`}
    >
      <p className="text-center text-sm font-medium text-gray">
        {props.children ||
          "Для того чтобы получить детальную ифнормацию необходимо выбрать один из элементов списка в левой колонке"}
      </p>
    </div>
  );
});
