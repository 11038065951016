import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Container } from "../../../common/components/Container/Container";
import { InformationBlock } from "../../../common/components/InformationBlock/InformationBlock";
import { Title } from "../../../common/components/Title/Title";
import { Chat } from "../../../common/components/Chat/Chat";
import {
  setActiveAppealId,
  setAppealStatus,
  setAppealsSearchText,
  setIsAddingAppeal,
} from "./appealSlice";
import {
  useGetAppealByIdQuery,
  useGetAppealStatusesQuery,
} from "services/appealApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { AppealAdd } from "../../../common/components/Appeal/AppealAdd";
import { AppealList } from "../../../common/components/Appeal/AppealList";
import { Tabs } from "common/components/Tabs/Tabs";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const AppealPage: FC = withErrorBoundary(() => {
  const activeAppealId = useAppSelector(
    (state) => state.customerCompanyManager.appeal.activeAppealId
  );
  const userId = useAppSelector((state) => state.user.current?.id);
  const isAddingAppeal = useAppSelector(
    (state) => state.customerCompanyManager.appeal.isAddingAppeal
  );
  const searchText = useAppSelector(
    (state) => state.customerCompanyManager.appeal.searchText
  );
  const appealStatus = useAppSelector(
    (state) => state.customerCompanyManager.appeal.appealStatus
  );

  const {
    data: appeal,
    isLoading: isAppealLoading,
    isFetching: isAppealFetching,
    error: appealError,
  } = useGetAppealByIdQuery(activeAppealId || -1, {
    skip: !activeAppealId,
  });

  const { data: appealStatuses } = useGetAppealStatusesQuery();

  const dispatch = useAppDispatch();

  const handlerSetActiveTransactionId = (id: number) => {
    dispatch(setActiveAppealId(id));
  };

  const handlerSetIsAddingAppeal = () => {
    dispatch(setIsAddingAppeal(!isAddingAppeal));
  };

  const handleSearchText = (text: string) => {
    dispatch(setAppealsSearchText(text));
  };

  const handleSetAppealStatus = (id: number) => {
    dispatch(setAppealStatus(id));
  };

  const appealStatusesArr = appealStatuses?.map((status) => ({
    value: status.id,
    label: status.name,
  }));

  return (
    <Container>
      <div className="overflow-y-scroll">
        <AppealList
          onItemClick={handlerSetActiveTransactionId}
          onSearch={handleSearchText}
          onAdd={handlerSetIsAddingAppeal}
          statusId={appealStatus}
          activeItemId={activeAppealId}
          userId={userId}
          searchText={searchText}
        />
      </div>
      <div className="grid h-full grid-rows-[30px,1fr] gap-[15px] overflow-y-scroll">
        <div className="grid grid-cols-2 items-center">
          {appealStatusesArr && (
            <Tabs
              className="whitespace-nowrap"
              tabs={[{ value: 0, label: "Все" }, ...appealStatusesArr]}
              value={appealStatus}
              onTabClick={(value) => handleSetAppealStatus(value)}
            />
          )}
        </div>
        {isAddingAppeal && <AppealAdd />}
        {activeAppealId && (
          <QueryLayout
            isLoading={isAppealLoading}
            isFetching={isAppealFetching}
            error={appealError}
          >
            {appeal && (
              <div className="grid grid-rows-[40px,minmax(400px,1fr),75px] gap-[10px] overflow-y-hidden">
                <Title className="flex items-center">Чат с пользователем</Title>
                <Chat appealId={appeal.id} />
              </div>
            )}
          </QueryLayout>
        )}
        {!isAddingAppeal && !activeAppealId && <InformationBlock />}
      </div>
    </Container>
  );
});
