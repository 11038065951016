import { FC, useState } from "react";
import { Tabs } from "../../../common/components/Tabs/Tabs";
import { List } from "../../../common/components/List/List";
import { OrderItemCard } from "../Order/OrderItemCard";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { MODAL_TYPES, ORDER_STATUS } from "../../../common/utils/consts";
import { useAppSelector } from "../../../app/hooks";
import { useGetAllOrdersQuery } from "../../../services/orderApi";
import { NotFoundTitle } from "../../../common/components/Title/NotFoundTitle";

export const UserOrders: FC<{ userId: number }> = ({ userId }) => {
  const activeAppealId = useAppSelector(
    (state) => state.platformManager.desktop.activeAppealId
  );

  const [value, setActiveTab] = useState<"active" | "finished" | "rejected">(
    "active"
  );

  const {
    data: orders,
    isLoading,
    isFetching,
    error,
  } = useGetAllOrdersQuery({
    statusId: value === "rejected" ? [ORDER_STATUS.REJECTED] : undefined,
    userId,
    isActive: Number(value === "active"),
  });

  const { showModal } = useGlobalModalContext();

  const showOrderDetailsModal = (orderId: number) => {
    showModal(MODAL_TYPES.ORDER_DETAILS_MODAL, { orderId });
  };

  return (
    <div>
      <Tabs
        className="mb-[12px]"
        tabs={[
          { value: "active", label: "Активные" },
          { value: "finished", label: "Завершённые" },
          { value: "rejected", label: "Отменённые" },
        ]}
        value={value}
        onTabClick={setActiveTab}
      />
      <QueryLayout isLoading={isLoading} isFetching={isFetching} error={error}>
        {orders?.length ? (
          <List>
            {orders.map((order) => (
              <li key={order.id}>
                <button
                  className="w-full"
                  onClick={() => showOrderDetailsModal(order.id)}
                >
                  <OrderItemCard
                    order={order}
                    isActive={activeAppealId === order.id}
                    withAddresses
                  />
                </button>
              </li>
            ))}
          </List>
        ) : (
          <NotFoundTitle />
        )}
      </QueryLayout>
    </div>
  );
};
