import { FC, useEffect } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { Button } from "../Button/Button";
import { ShadowContainer } from "../Container/ShadowContainer";
import { Error } from "../Message/Error/Error";
import { MaskedInput } from "../Input/MaskedInput";
import { useGlobalModalContext } from "../Modal/GlobalModal";
import { CustomSelect, UserTypeSingleValue } from "../Select/CustomSelect";
import { Spinner } from "../Spinner/Spinner";
import { Title } from "../Title/Title";
import { MODAL_TYPES } from "../../utils/consts";
import { getErrorMessage, phoneStrTransform } from "../../utils/helpers";
import {
  useCreateEmployeeMutation,
  useGetEmployeeRolesQuery,
} from "../../../services/employeeApi";
import { EmployeePermissionsList } from "./EmployeePermissionsList";
import { IEmployeeAddProps } from "common/models/components/employee/IEmployeeAddProps";
import { EmployeeValues } from "common/models/forms/EmployeeValues";
import { SvgSuccess } from "assets/icons/SvgSuccess";

export const EmployeeAdd: FC<IEmployeeAddProps> = ({
  companyId,
  companyCategoryId,
  applicationId,
}) => {
  const {
    data: roles,
    isSuccess: isRolesSuccess,
    error: rolesError,
  } = useGetEmployeeRolesQuery({ applicationId });

  const [
    createEmployee,
    {
      isLoading: isCreating,
      isSuccess: isCreatingSuccess,
      error,
      reset: resetQuery,
    },
  ] = useCreateEmployeeMutation();

  const methods = useForm<EmployeeValues>({
    defaultValues: { permissions: [] },
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset: resetForm,
    formState: { errors },
  } = methods;

  const currentRole = useWatch({
    control,
    name: "role",
  });

  const onSubmit = handleSubmit((data) => {
    const userInvite = {
      company_id: companyId,
      role_id: data.role,
      phone: +phoneStrTransform(data.phone),
      permissions: data.permissions
        .map((permission) => +permission)
        .filter((permission) => permission),
    };
    createEmployee(userInvite);
  });

  useEffect(() => {
    if (isCreatingSuccess) {
      showSuccessModal();
    }
  }, [isCreatingSuccess]);

  useEffect(() => {
    if (error) {
      showErrorModal(getErrorMessage(error)?.data.message);
    }
  }, [error]);

  useEffect(() => {
    setValue("permissions", []);
  }, [currentRole]);

  const { showModal } = useGlobalModalContext();

  const showErrorModal = (erorrText?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: erorrText || "Ошибка при отправке инвайта",
      subTitle: "Пользователя нет в системе ему будет отправлнеа СМС",
    });
  };

  const showSuccessModal = () => {
    showModal(MODAL_TYPES.SUCCESS_MODAL, {
      title: "Пользователю выслан инвайт",
      subTitle: "Ожидайте ответа пользователя",
    });
  };

  const handleResetCreating = () => {
    resetForm();
    resetQuery();
  };

  if (rolesError) {
    return <Error>Ошибка при загрузке ролей</Error>;
  }

  if (!roles?.length) {
    return <Spinner />;
  }

  const options = roles.map((role) => ({ value: role.id, label: role.name }));

  return (
    <ShadowContainer className="mt-[32px]">
      <Title className="mb-[25px]">Добавить сотрудника</Title>
      {isCreatingSuccess && (
        <div className="grid grid-rows-[1fr,45px]">
          <div className="flex h-[200px] flex-col items-center justify-center text-primary">
            <SvgSuccess className="mb-[15px] h-[50px] w-[50px]" />
            <Title className="text-center">
              Компания успешно отредактированна
            </Title>
          </div>
          <Button onClick={handleResetCreating}>Продолжить</Button>
        </div>
      )}
      {!isCreatingSuccess && (
        <form onSubmit={onSubmit}>
          <div className="mb-[40px] grid grid-cols-2 items-end gap-[15px]">
            <MaskedInput
              {...register("phone", {
                required: true,
                validate: (value) => phoneStrTransform(value).length === 11,
              })}
              invalid={!!errors.phone}
              className="w-full"
              placeholder="Номер телефона"
              label="Номер телефона *"
              type="tel"
              mask="+7 (999) 999 99-99"
            />
            <Controller
              control={control}
              name="role"
              rules={{ required: true }}
              render={(props) => {
                return (
                  <CustomSelect
                    reference={props.field.ref}
                    options={options}
                    invalid={!!errors.role}
                    label="Должность *"
                    placeholder="Выберите опцию..."
                    components={{ SingleValue: UserTypeSingleValue }}
                    value={options.find((c) => c.value === props.field.value)}
                    onChange={(val) => props.field.onChange(val?.value)}
                    isSearchable={false}
                  />
                );
              }}
            />
          </div>
          {currentRole && (
            <div>
              <Title className="mb-[25px]">Права</Title>

              <FormProvider {...methods}>
                <EmployeePermissionsList
                  roleId={currentRole}
                  companyCategoryId={companyCategoryId}
                />
              </FormProvider>
            </div>
          )}
          <Button type="submit" className="px-[90px]" disabled={isCreating}>
            Добавить
          </Button>
        </form>
      )}
    </ShadowContainer>
  );
};
