import { FC, useEffect } from "react";
import { IOrder } from "../../models/order/IOrder";
import { SubTitle } from "../Title/SubTitle";
import { OrderItemCard } from "./OrderItemCard";
import { timeFormatter } from "../../utils/helpers";
import { IOrderListProps } from "common/models/components/order/IOrderListProps";
import { useGetAllOrdersQuery } from "services/orderApi";
import { QueryLayout } from "../Layout/QueryLayout";
import { NotFoundTitle } from "../Title/NotFoundTitle";

import "swiper/css";

export const orderListItemGenerator = (
  order: IOrder[],
  currentOrder: IOrder,
  index: number,
  activeOrderId?: number,
  withAddresses: boolean = false,
  withDetails: boolean = false,
  withTracking: boolean = false,
  onItemClick?: (id: IOrder) => void,
  onVehicleAssign?: (orderId: number, vehicleId: number) => void
) => {
  const currentOrderDate = new Date(
    currentOrder.datetime || currentOrder.created_at
  );
  const currentOrderTime = timeFormatter({
    time: currentOrderDate,
    mode: "dd-Mth-yyyy",
  });
  const currentDate = new Date();
  const isCurrentDateDay =
    currentDate.getDate() === currentOrderDate.getDate() &&
    currentDate.getMonth() === currentOrderDate.getMonth();

  const currentOrderElement = onItemClick ? (
    <button
      className="w-full"
      key={"order_" + currentOrder.id}
      onClick={() => onItemClick(currentOrder)}
    >
      <OrderItemCard
        withTracking={withTracking}
        isActive={activeOrderId === currentOrder.id}
        withDetails={withDetails}
        withAddresses={withAddresses}
        order={currentOrder}
        onVehicleAssign={onVehicleAssign}
      />
    </button>
  ) : (
    <OrderItemCard
      withTracking={withTracking}
      key={"order_" + currentOrder.id}
      isActive={activeOrderId === currentOrder.id}
      withDetails={withDetails}
      withAddresses={withAddresses}
      order={currentOrder}
      onVehicleAssign={onVehicleAssign}
    />
  );

  if (index > 0) {
    const previousOrderDate = new Date(order[index - 1].datetime);
    if (currentOrderDate.getDate() !== previousOrderDate.getDate()) {
      return (
        <div key={"orderWithTitle_" + currentOrder.id}>
          <SubTitle className="mb-[12px]">
            <time>{currentOrderTime}</time>
          </SubTitle>
          {currentOrderElement}
        </div>
      );
    } else {
      return currentOrderElement;
    }
  }
  return (
    <div key={"orderWithTitle_" + currentOrder.id}>
      <SubTitle className="mb-[12px]">
        {isCurrentDateDay ? "Здесь и сейчас" : <time>{currentOrderTime}</time>}
      </SubTitle>
      {currentOrderElement}
    </div>
  );
};

export const OrderList: FC<IOrderListProps> = ({
  activeOrderId,
  withTracking = false,
  withAddresses = false,
  withDetails = false,
  onItemClick,
  onVehicleAssign,
  filter,
}) => {
  const {
    data: orderList,
    isLoading: isOrderListLoading,
    isFetching: isOrderListFetching,
    error: orderListError,
    refetch: refetchOrderList,
  } = useGetAllOrdersQuery(filter);

  useEffect(() => {
    refetchOrderList();
  }, [filter?.isActive]);

  return (
    <QueryLayout isLoading={isOrderListLoading} error={orderListError}>
      {orderList?.length ? (
        <div className="pointer-events-auto grid h-full items-baseline gap-[12px] overflow-y-scroll pt-[9px]">
          <div
            className={`grid gap-[12px] ${
              isOrderListFetching ? "animate-pulse" : ""
            }`}
          >
            {orderList.map((order: IOrder, i) =>
              orderListItemGenerator(
                orderList,
                order,
                i,
                activeOrderId,
                withAddresses,
                withDetails,
                withTracking,
                onItemClick,
                onVehicleAssign
              )
            )}
          </div>
        </div>
      ) : (
        <NotFoundTitle />
      )}
    </QueryLayout>
  );
};
