import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgCaretUp } from "../../../assets/icons/SvgCaretUp";
import { Button } from "../../../common/components/Button/Button";
import { CustomInput } from "../../../common/components/Input/CustomInput";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { List } from "../../../common/components/List/List";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { VehicleListItem } from "../../../common/components/Vehicle/VehicleListItem";
import { MODAL_TYPES } from "../../../common/utils/consts";
import { useGetVehicleCategoriesQuery } from "../../../services/vehicle/vehiclesApi";
import {
  resetAssignment,
  setAssignedVehicleCategoryId,
} from "./chooseVehicleSlice";
import { ManipulatorsList } from "./ManipulatorsList";
import { useUpdateOrderMutation } from "../../../services/orderApi";
import { getErrorMessage } from "../../../common/utils/helpers";

export const VehicleAssignment: FC = () => {
  const [updateOrder, { isLoading: isOrderUpdateLoading }] =
    useUpdateOrderMutation();
  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isFetching: isCategoriesFetching,
    error: vehicleCategoryError,
  } = useGetVehicleCategoriesQuery();

  const dispatch = useAppDispatch();

  const assignedVehicleCategoryId = useAppSelector(
    (state) =>
      state.platformManager.chooseVehicle.vehicleAssignment
        .assignedVehicleCategoryId
  );
  const assignedCityId = useAppSelector(
    (state) =>
      state.platformManager.chooseVehicle.vehicleAssignment.assignedCityId
  );
  const assignedVehicleTypeId = useAppSelector(
    (state) =>
      state.platformManager.chooseVehicle.vehicleAssignment
        .assignedVehicleTypeId
  );
  const activeOrderId = useAppSelector(
    (state) => state.platformManager.chooseVehicle.activeOrderId
  );

  const { showModal } = useGlobalModalContext();

  const showVehicleSelectionModal = () => {
    showModal(MODAL_TYPES.VEHICLE_SELECTION_MODAL, {
      vehicleCategoryId: assignedVehicleCategoryId,
      confirmText: "Техника успешно назначена",
      orderId: activeOrderId,
      cityId: assignedCityId,
      onConfirm: () => handleAssignmentReset(),
    });
  };

  const showSuccessModal = () => {
    showModal(MODAL_TYPES.SUCCESS_MODAL, {
      title: "Техника успешно назначена",
    });
  };

  const showErrorModal = (message?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Ошибка при назначении типа техники",
      subTitle: message,
    });
  };

  const handleOrderUpdate = async () => {
    if (activeOrderId && assignedVehicleTypeId) {
      await updateOrder({
        orderId: activeOrderId,
        vehicleTypeId: assignedVehicleTypeId,
      })
        .unwrap()
        .then(() => {
          showSuccessModal();
        })
        .catch((err) => {
          showErrorModal(getErrorMessage(err)?.data.message);
        });
      handleAssignmentReset();
    } else {
      showErrorModal("Отсутствуют необходимые поля");
    }
  };

  const handleAssignmentReset = () => {
    dispatch(resetAssignment());
  };

  const handleVehicleCategoryChange = (id: number) => {
    dispatch(setAssignedVehicleCategoryId(id));
  };

  if (assignedVehicleCategoryId)
    return (
      <div className="grid gap-[15px]">
        <ManipulatorsList categoryId={assignedVehicleCategoryId} />
        <div className="grid grid-rows-2 gap-[15px]">
          <Button mode="darkBorder" onClick={showVehicleSelectionModal}>
            Подобрать вручную
          </Button>
          <Button
            disabled={!assignedVehicleTypeId || isOrderUpdateLoading}
            onClick={() => {
              handleOrderUpdate();
            }}
          >
            Назначить
          </Button>
        </div>
      </div>
    );

  return (
    <div>
      <p className="mb-[15px] text-sm text-lightGray">Необходимо тип техники</p>
      <QueryLayout
        isLoading={isCategoriesLoading}
        isFetching={isCategoriesFetching}
        error={vehicleCategoryError}
      >
        {/* <div className="mb-[15px]">
          {cities && (
            <CustomSelect
              className="w-full"
              label="Выберите город *"
              options={cities.map((city) => ({
                value: city.id,
                label: city.name,
              }))}
              placeholder="Выберите опцию..."
              value={cities
                .map((city) => ({ value: city.id, label: city.name }))
                .find((c) => c.value === assignedCityId)}
              onChange={(val) => handleCityChange(val?.value)}
            />
          )}
        </div> */}
        <List>
          {categories?.map((category) => {
            const isChecked = assignedVehicleCategoryId === category.id;
            return (
              <li key={category.id} className="relative">
                <CustomInput
                  value={category.id}
                  checked={isChecked}
                  onChange={() => handleVehicleCategoryChange(category.id)}
                  type="checkbox"
                  innerelement={
                    <VehicleListItem
                      mode="short"
                      name={category.name}
                      img={category.icon.path}
                    />
                  }
                />
                {!isChecked && (
                  <SvgCaretUp className="absolute right-[20px] top-[50%] h-[5px] w-[10px] translate-y-[-50%] rotate-[90deg]" />
                )}
              </li>
            );
          })}
        </List>
      </QueryLayout>
    </div>
  );
};
