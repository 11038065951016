import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ShadowContainer } from "../../../common/components/Container/ShadowContainer";
import { OrderItemFullDetails } from "../../../common/components/Order/OrderItemFullDetails";
import { AssignmentVehicle } from "../../../common/components/VehicleAssignment/AssignmentVehicle";
import {
  orderVehicleAssignmentSelector,
  setAssigningOrderId,
  setAssigningDriverId,
  setAssigningVehicleId,
  resetAssignment,
  setAssigningVehicleTypeId,
} from "./ordersSlice";
import { Dropdown } from "../../../common/components/Dropdown/Dropdown";
import { IOrder } from "../../../common/models/order/IOrder";
import { ORDER_STATUS } from "../../../common/utils/consts";
import { ILongLatAddress } from "common/models/commonModels/ILongLatAddress";
import { setRoute } from "../map/mapSlice";
import { Waybill } from "common/components/Order/Waybill";
import { OrderDescription } from "common/components/Order/OrderDescription";

export const OrderInfo: FC<{ order: IOrder }> = ({ order }) => {
  const vehicleAssignmentOrderId = useAppSelector(
    (state) =>
      state.implementerCompanyManager.orders.vehicleAssignment.activeOrderId
  );
  const currentCompanyId = useAppSelector(
    (state) => state.user.current?.account?.company?.id
  );

  const dispatch = useAppDispatch();

  const handleSetAssignmentVehicleType = (id: Undefinable<number>) => {
    dispatch(setAssigningVehicleTypeId(id));
  };

  const handleSetAssigningOrderId = (id: number) => {
    dispatch(setAssigningOrderId(id));
  };

  const handleSetRoute = (addresses: ILongLatAddress[]) => {
    dispatch(setRoute(addresses));
  };

  const isOrderComplete = order.status.id === ORDER_STATUS.FINISHED;

  return (
    <div className="grid h-full overflow-hidden">
      <ShadowContainer className="overflow-y-scroll">
        <Dropdown title="Детали заказа" isOpen>
          <OrderItemFullDetails
            orderId={order.id}
            setAssigningOrderId={handleSetAssigningOrderId}
            onVehicleAssign={handleSetAssignmentVehicleType}
            onRouteShow={handleSetRoute}
          />
        </Dropdown>
      </ShadowContainer>
      {vehicleAssignmentOrderId && currentCompanyId && (
        <ShadowContainer className="overflow-y-scroll ">
          <Dropdown title="Назначить технику" isOpen>
            <AssignmentVehicle
              companyId={currentCompanyId}
              assignmentStateSelector={orderVehicleAssignmentSelector}
              handleAssignDriver={setAssigningDriverId}
              handleAssignVehicles={setAssigningVehicleId}
              handleAssignReset={resetAssignment}
            />
          </Dropdown>
        </ShadowContainer>
      )}
      {isOrderComplete && (
        <ShadowContainer className="overflow-y-scroll">
          <Dropdown title="Путевой лист">
            <Waybill orderId={order.id} defaultPhotos={order.photos} />
          </Dropdown>
        </ShadowContainer>
      )}
      <ShadowContainer className="overflow-y-scroll">
        <Dropdown title="Фото с комментариями">
          <OrderDescription
            description={order.description}
            photos={order.photos}
          />
        </Dropdown>
      </ShadowContainer>
    </div>
  );
};
