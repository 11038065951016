import { IApiMessage } from "common/models/api/IApiMessage";
import { IInvoice } from "./../common/models/invoices/IInvoice";
import { api } from "./api";
import { getFilterParameters } from "common/utils/helpers";

const invoicesApi = api
  .enhanceEndpoints({ addTagTypes: ["Invoice"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllInvoices: build.query<
        IInvoice[],
        { statusId?: number[]; dateFrom?: string; dateTo?: string }
      >({
        query({ statusId, dateFrom, dateTo }) {
          return {
            url: `/invoices/${getFilterParameters([
              { paramName: "invoice_status_id[]", params: statusId },
            ])}`,
            params: {
              date_from: dateFrom,
              date_to: dateTo,
            },
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Invoice", id } as const)),
                { type: "Invoice", id: "LIST" },
                "User",
              ]
            : [{ type: "Invoice", id: "LIST" }],
        transformResponse: (result: { invoices: IInvoice[] }) =>
          result.invoices,
      }),
      getInvoiceById: build.query<IInvoice, { id: number }>({
        query({ id }) {
          return {
            url: `/invoices/${id}`,
          };
        },
        transformResponse: (result: { invoice: IInvoice }) => result.invoice,
      }),
      getInvoiceStatuses: build.query<{ id: number; name: string }[], void>({
        query() {
          return {
            url: `/invoice_statuses`,
          };
        },
        transformResponse: (result: {
          items: { id: number; name: string }[];
        }) => result.items,
      }),
      createRefill: build.mutation<IInvoice, { amount: number; email: string }>(
        {
          query(body) {
            return {
              url: `/invoices`,
              method: "POST",
              body,
            };
          },
          invalidatesTags: (result, error, arg) =>
            !error?.status ? [{ type: "Invoice", id: "LIST" }] : [],
          transformResponse: (result: { invoice: IInvoice }) => result.invoice,
        }
      ),
      deleteInvoice: build.mutation<IApiMessage, number>({
        query(id) {
          return {
            url: `/invoices/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Invoice", id: "LIST" }] : [],
      }),
      createWithDrawals: build.mutation<
        IInvoice,
        { amount: number; email: string }
      >({
        query(body) {
          return {
            url: `/payment`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Invoice", id: "LIST" }] : [],
        transformResponse: (result: { invoice: IInvoice }) => result.invoice,
      }),
      acceptInvoice: build.mutation<IInvoice, { invoiceId: number }>({
        query({ invoiceId }) {
          return {
            url: `/invoices/${invoiceId}/accept`,
            method: "POST",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Invoice", id: arg.invoiceId }] : [],
        transformResponse: (result: { invoice: IInvoice }) => result.invoice,
      }),
      rejectInvoice: build.mutation<IInvoice, { invoiceId: number }>({
        query({ invoiceId }) {
          return {
            url: `/invoices/${invoiceId}/reject`,
            method: "POST",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Invoice", id: arg.invoiceId }] : [],
        transformResponse: (result: { invoice: IInvoice }) => result.invoice,
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetInvoiceByIdQuery,
  useGetInvoiceStatusesQuery,
  useGetAllInvoicesQuery,
  useCreateRefillMutation,
  useCreateWithDrawalsMutation,
  useDeleteInvoiceMutation,
  useAcceptInvoiceMutation,
  useRejectInvoiceMutation,
} = invoicesApi;
