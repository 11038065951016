import { useEffect } from "react";
import { IInputProps } from "common/models/components/input/IInputProps";
import { FC, forwardRef, useState, memo } from "react";

export const Switch: FC<IInputProps> = memo(
  forwardRef<HTMLInputElement, IInputProps>(
    ({ type = "text", className = "", placeholder, ...props }, ref) => {
      const [isActive, setIsActive] = useState<boolean>(!!props.checked);

      useEffect(() => {
        if (!props.readOnly) {
          setIsActive(!!props.checked);
        }
      }, [props.checked]);

      return (
        <div className="relative flex h-[26px] w-[50px] items-center">
          <i
            className={`pointer-events-none absolute top-[3px] h-[20px] w-[20px] rounded-[50%] bg-white transition ${
              isActive ? "left-[27px]" : "left-[3px]"
            }`}
          />
          <input
            {...props}
            ref={ref}
            className={`h-[100%] w-[100%] cursor-pointer appearance-none rounded-[50px]  transition ${
              isActive ? "bg-primary" : "bg-lightGray"
            } focus:outline-none ${className}`}
            type="checkbox"
            role="switch"
          />
        </div>
      );
    }
  )
);
