import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Alert } from "../../../common/components/Alert/Alert";
import { Avatar } from "../../../common/components/Avatar/Avatar";
import { Button } from "../../../common/components/Button/Button";
import { ShadowContainer } from "../../../common/components/Container/ShadowContainer";
import { Error } from "../Message/Error/Error";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { useGlobalModalContext } from "../../../common/components/Modal/GlobalModal";
import { Title } from "../../../common/components/Title/Title";
import { MODAL_TYPES, USER_ROLE } from "../../../common/utils/consts";
import { getErrorMessage } from "../../../common/utils/helpers";
import {
  useDeleteEmployeeMutation,
  useGetEmployeeByIdQuery,
  useUpdateEmployeeMutation,
} from "../../../services/employeeApi";
import { EmployeePermissionsList } from "./EmployeePermissionsList";
import { IEmployeeEditProps } from "common/models/components/employee/IEmployeeEditProps";
import { useAppSelector } from "app/hooks";

export const EmployeeEdit: FC<IEmployeeEditProps> = ({
  employeeId,
  companyId,
  companyCategoryId,
  handleClose,
}) => {
  const roleId = useAppSelector(
    (state) => state.user.current?.account?.role.id
  );

  const isAdmin = roleId === USER_ROLE.ADMINISTRATOR;

  const {
    data: employee,
    isLoading: isEmployeeLoading,
    isFetching: isEmployeeFetching,
    error: employeeError,
  } = useGetEmployeeByIdQuery({ employeeId, companyId });

  const [
    updateEmployee,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdateEmployeeMutation();

  const [deleteEmployee, { isLoading: isDeletingLoading, error: deleteError }] =
    useDeleteEmployeeMutation();

  const methods = useForm<{ permissions: string[] }>({
    defaultValues: { permissions: [] },
  });

  const {
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    const userEdit = {
      companyId,
      employeeId,
      permissions: data.permissions
        .map((permission) => +permission)
        .filter((permission) => permission),
    };

    updateEmployee(userEdit);
  });

  const onDelete = () => {
    const deleteUserData = {
      companyId,
      employeeId,
    };

    deleteEmployee(deleteUserData);
    handleClose();
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      showSuccessModal("Пользователь успешно отредактирован");
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (updateError || deleteError) {
      showErrorModal(getErrorMessage(updateError || deleteError)?.data.message);
    }
  }, [updateError, deleteError]);

  useEffect(() => {
    if (employee) {
      setValue(
        "permissions",
        employee.permissions.map((perm) => String(perm.id))
      );
    }
  }, [employee]);

  const { showModal } = useGlobalModalContext();

  const showConfirmationModal = () => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      subTitle: "Вы уверены что хотите удалить данного сотрудника?",
      confirmText: "Пользователь успешно удалён",
      onConfirm: () => onDelete(),
    });
  };

  const showErrorModal = (erorrText?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: erorrText || "Ошибка при редактировании",
      subTitle: "Повторите попытку позже",
    });
  };

  const showSuccessModal = (label: string) => {
    showModal(MODAL_TYPES.SUCCESS_MODAL, {
      title: label,
    });
  };

  return (
    <ShadowContainer className="mt-[32px]">
      <Title className="mb-[25px]">Редактировать сотрудника</Title>
      <QueryLayout
        isLoading={isEmployeeLoading}
        isFetching={isEmployeeFetching}
        error={employeeError}
      >
        {!employee ? (
          <Error>Не удалось найти сотрудника</Error>
        ) : (
          <form onSubmit={onSubmit}>
            <div className="mb-[25px]">
              <Avatar primary={true} className="m-auto mb-[10px]" alt="user" />
              <div className="mb-[15px] text-center text-base font-semibold text-lightGray">
                {!!employee.user
                  ? `${employee.user.last_name} ${employee.user.first_name}`
                  : `${employee.invite?.phone}`}
              </div>
              {!!employee.user && (
                <div className="mb-[25px] text-center text-md font-semibold">
                  {employee.user.phone}
                </div>
              )}
            </div>
            {!employee.user ? (
              <Alert className="mb-[30px] text-xs" mode="error">
                Сотрудник ещё не подтвердил учётную запись
              </Alert>
            ) : (
              !employee.invite?.confirmed_at && (
                <Alert className="mb-[30px] text-xs" mode="warning">
                  Сотрудник ещё не подтвердил ваш запрос
                </Alert>
              )
            )}
            <FormProvider {...methods}>
              <EmployeePermissionsList
                readOnly={!isAdmin}
                roleId={employee.role.id}
                companyCategoryId={companyCategoryId}
              />
            </FormProvider>
            {isAdmin && (
              <div className="grid grid-cols-2 gap-[15px]">
                <Button type="submit" disabled={isUpdateLoading || !isDirty}>
                  Редактировать
                </Button>
                <Button
                  type="button"
                  onClick={showConfirmationModal}
                  mode="darkBorder"
                  disabled={isDeletingLoading}
                >
                  Удалить
                </Button>
              </div>
            )}
          </form>
        )}
      </QueryLayout>
    </ShadowContainer>
  );
};
