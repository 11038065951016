import { FC, useState, useEffect, useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { timeFormatter } from "../../../common/utils/helpers";
import { ProfileButton } from "../../../common/components/Button/ProfileButton";
import { UserProfileDropdown } from "common/components/Dropdown/UserProfileDropdown";

export const ImplementerCompanyManagerHeader: FC = () => {
  const currentAccountId = useAppSelector(
    (state) => state.user.current?.account?.id
  );
  const firstName = useAppSelector((state) => state.user.current?.first_name);
  const lastName = useAppSelector((state) => state.user.current?.last_name);
  const currentCompanyBalance = useAppSelector(
    (state) => state.user.current?.account?.company?.balance
  );
  const companyLogotype = useAppSelector(
    (state) => state.user.current?.account.company?.logotype?.path
  );
  const userAvatar = useAppSelector((state) => state.user.current?.avatar);
  const userId = useAppSelector((state) => state.user.current?.id);
  const companyName = useAppSelector(
    (state) => state.user.current?.account?.company?.name
  );
  const currentRaiting = useAppSelector((state) => state.user.current?.rate);

  const fullName = lastName + " " + firstName;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 800);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      window.addEventListener("click", handleClose, { once: true });
    }
  }, [dropdownOpen]);

  const handleClose = () => {
    setDropdownOpen(false);
  };

  const currentTimeString = useMemo(
    () => timeFormatter({ time: currentTime, mode: "dd-Mth hh:mm:ss" }),
    [currentTime]
  );

  return (
    <header className="flex h-[90px] items-center justify-between px-[15px] shadow-md">
      <div>
        <h1 className="text-lg font-semibold">
          {companyName || "Выберите компанию"}
        </h1>
        <time className="block w-[120px] text-base text-graySecond">
          {currentTimeString}
        </time>
      </div>
      <div className="relative">
        <ProfileButton
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen((prev) => !prev);
          }}
          name={companyName || fullName}
          rating={currentRaiting}
          balance={currentCompanyBalance}
          avatarurl={companyLogotype}
        />
        {dropdownOpen && userId && (
          <UserProfileDropdown
            userAvatar={userAvatar?.path}
            userId={userId}
            currentAccountId={currentAccountId}
            fullName={fullName}
            handleClose={handleClose}
          />
        )}
      </div>
    </header>
  );
};
