import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../Button/Button";
import { CompanyFormInputs } from "./CompanyFormInputs";
import { Dropdown } from "../Dropdown/Dropdown";
import { EditCompanyValues } from "../../models/forms/EditCompanyValues";
import { getErrorMessage } from "../../utils/helpers";
import { useCreateCompanyMutation } from "../../../services/companiesApi";
import { SvgSuccess } from "assets/icons/SvgSuccess";
import { Title } from "../Title/Title";
import { useUploadImagesMutation } from "services/fileApi";

export const CompanyAdd: FC = () => {
  const methods = useForm<EditCompanyValues>();

  const [
    createCompany,
    { isSuccess, isLoading, error: updatingError, reset: resetQuery },
  ] = useCreateCompanyMutation();

  const [
    uploadImages,
    { isLoading: isUploadingImagesLoading, error: uploadingImagesError },
  ] = useUploadImagesMutation();

  const {
    handleSubmit,
    reset: resetForm,
    formState: { isDirty },
  } = methods;

  const onSubmit = handleSubmit(async (company) => {
    let logotype;
    let documents;

    if (company.logotype) {
      const formData = new FormData();
      formData.append(`files[0][file_type_id]`, "3");
      formData.append(`files[0][file]`, company.logotype[0]);
      logotype = await uploadImages(formData).unwrap();
    }

    if (company.documents) {
      const formData = new FormData();
      for (let x = 0; x < company.documents.length; x++) {
        formData.append(`files[${x}][file_type_id]`, "4");
        formData.append(`files[${x}][file]`, company.documents[x]);
      }
      documents = await uploadImages(formData).unwrap();
    }
    createCompany({
      ...company,
      logo_id: logotype ? logotype[0].id : undefined,
      documents: documents?.map((image) => String(image.id)),
      post_address: company.post_address || undefined,
    });
  });

  const handleResetCreating = () => {
    resetForm();
    resetQuery();
  };

  return (
    <Dropdown title="Добавить компанию" isOpen>
      {isSuccess ? (
        <div className="grid grid-rows-[1fr,45px]">
          <div className="flex h-[200px] flex-col items-center justify-center text-primary">
            <SvgSuccess className="mb-[15px] h-[50px] w-[50px]" />
            <Title className="text-center">Компания успешно создана</Title>
          </div>
          <Button onClick={handleResetCreating}>Продолжить</Button>
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <CompanyFormInputs isCreating />
            <Button
              disabled={!isDirty || isLoading || isUploadingImagesLoading}
              type="submit"
              className="w-2/4 text-base font-medium tracking-wider"
            >
              Создать компанию
            </Button>
            <div className="mt-[15px] text-center">
              {updatingError && (
                <span
                  className={`m-auto mb-[12px] block text-sm font-semibold text-red`}
                >
                  {getErrorMessage(updatingError)?.data?.message}
                </span>
              )}
              {isSuccess && (
                <span
                  className={`m-auto mb-[12px] block text-sm font-semibold text-primary`}
                >
                  Успешно создано
                </span>
              )}
            </div>
          </form>
        </FormProvider>
      )}
    </Dropdown>
  );
};
