import { OrderingStepTypes } from "../models/types/OrderingStepTypes";

export const API_URL = process.env.REACT_APP_API_URL;

export const MAX_ORDER_WAITING_COMPANY_TIME = 60;

export const PERIOD = {
  TODAY: 24 * 60 * 60 * 1000,
  WEEK: 24 * 60 * 60 * 1000 * 7,
  MONTH: 24 * 60 * 60 * 1000 * 30,
  QUARTER: 24 * 60 * 60 * 1000 * 90,
  YEAR: 24 * 60 * 60 * 1000 * 365,
};

export const APPLICATIONS = {
  CUSTOMER: 3,
  IMPLEMENTER: 4,
  ADMINISTRATOR: 5,
};

export const COMPANY_CATEGORY = {
  CUSTOMER: 1,
  IMPLEMENTER: 2,
  PLATFORM: 3,
};

export const VEHICLE_CATEGORY = {
  MANIPULATOR: 1,
  CRAN: 3,
  AERIAL_PLATFORM: 2,
};

export const PAYMENT_METHOD = {
  CARD: 1,
  CASH: 2,
  COMPANY_BALANCE: 3,
};

export const EMPLOYEE_ROLE = {
  MANAGER: 2,
  DRIVER: 3,
};

export const EMPLOYEE_PERMISSIONS = {
  ORDERING: 1,
  BALANCE_VIEW: 2,
  BALANCE_TOPUP: 3,
  EMPLOYEES_EDITING: 4,
  ORDER_HISTORY_VIEW: 5,
  COMPANY_EDITING: 6,
  WITHDRAWALS: 7,
  ORDER_ACCEPTION: 8,
  DELAYED_ORDER_ACCEPTION: 9,
};

export const FILE_TYPE = {
  WAYBILL: 9,
  INVOICE: 8,
  STS_PHOTO: 7,
  CARGO_PHOTO: 6,
  APP_ICON: 5,
  COMPANY_DOCUMENT: 4,
  COMPANY_LOGOTYPE: 3,
  ONBOARDING: 2,
  AVATAR: 1,
};

export const USER_ROLE = {
  INDIVIDUAL: 1,
  MANAGER: 2,
  DRIVER: 3,
  ADMINISTRATOR: 4,
};

export const ORDER_STATUS = {
  REJECTED: 9,
  FINISHED: 8,
  GOING: 7,
  WAITING: 6,
  DRIVER_CHOOSING: 5,
  PAYMENT_ERROR: 4,
  PAYMENT_WAITING: 3,
  IMPLEMENTING_COMPANY_SELECTION: 2,
  PROCESSING: 1,
};

export const MODAL_TYPES = {
  CONFIRM_MODAL: "CONFIRM_MODAL",
  SUCCESS_MODAL: "SUCCESS_MODAL",
  ERROR_MODAL: "ERROR_MODAL",
  ORDER_DETAILS_MODAL: "ORDER_DETAILS_MODAL",
  BAN_MODAL: "BAN_MODAL",
  BONUSES_MODAL: "BONUSES_MODAL",
  VEHICLE_SELECTION_MODAL: "VEHICLE_SELECTION_MODAL",
  REPORT_MODAL: "REPORT_MODAL",
  ORDER_CANCELATION_MODAL: "ORDER_CANCELATION_MODAL",
  DISPUTE_MODAL: "DISPUTE_MODAL",
  ORDER_PAUSE_MODAL: "ORDER_PAUSE_MODAL",
  PAYMENT_MODAL: "PAYMENT_MODAL",
  IMAGES_SLIDER_MODAL: "IMAGES_SLIDER_MODAL",
};

export const SOCKET_EVENT = {
  ORDER_CREATED: "App\\Events\\Order\\Created",
  ORDER_UPDATED: "App\\Events\\Order\\Updated",
  ORDER_DELETED: "App\\Events\\Order\\Deleted",
  ORDER_ITEM_UPDATED: "App\\Events\\OrderStatusUpdated",
  USER_UPDATED: "App\\Events\\User\\Updated",
};

export const ORDER_STEP = {
  VEHICLE_CATEGORY_CHOOSE: {
    value: "VEHICLE_CATEGORY_CHOOSE" as OrderingStepTypes,
    label: "Выберите тип техники",
  },
  VEHICLE_TYPE_CHOOSE: {
    value: "VEHICLE_TYPE_CHOOSE" as OrderingStepTypes,
    label: "Параметры техники и груза",
  },
  DESCRIPTION: {
    value: "DESCRIPTION" as OrderingStepTypes,
    label: "Описание и фото",
  },
  ADDRESS_CHOOSE: {
    value: "ADDRESS_CHOOSE" as OrderingStepTypes,
    label: "Адрес и время проведения работ",
  },
  CONTACT_INFORMATION: {
    value: "CONTACT_INFORMATION" as OrderingStepTypes,
    label: "Контактная информация",
  },
  ORDER_CREATED: {
    value: "ORDER_CREATED" as OrderingStepTypes,
    label: "",
  },
};
