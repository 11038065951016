import { FC, useEffect } from "react";
import { Button } from "../../../../common/components/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { TariffsFormInputs } from "./TariffsFormInputs";
import { useParams } from "react-router-dom";
import {
  useGetVehicleTypeApiByIdQuery,
  useUpdateVehicleTypeMutation,
} from "services/vehicle/vehicleTypesApi";
import { Error } from "common/components/Message/Error/Error";
import { getErrorMessage } from "common/utils/helpers";
import { Success } from "common/components/Message/Success/Success";
import { VehicleTypeFormValues } from "common/models/forms/VehicleTypeFormValues";

export const TariffsEdit: FC = () => {
  const { id } = useParams();

  const {
    data: type,
    isLoading: isTypeLoading,
    error: typeError,
  } = useGetVehicleTypeApiByIdQuery(id ? +id : -1, { skip: !id });

  const [update, { isLoading: isUpdating, error: updateError, isSuccess }] =
    useUpdateVehicleTypeMutation();

  const methods = useForm<VehicleTypeFormValues>();

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (type) {
      reset({
        ...type,
        vehicle_category_id: type.category.id,
        city_id: type.city?.id || undefined,
        board_carrying_capacity: type.board_carrying_capacity || undefined,
        arrow_carrying_capacity: type.arrow_carrying_capacity || undefined,
        lifting_height: type.lifting_height || undefined,
      });
    }
  }, [type]);

  const onSubmit = handleSubmit((data) => {
    update(data);
  });

  if (typeError || updateError) {
    return (
      <Error>{getErrorMessage(typeError || updateError)?.data.message}</Error>
    );
  }

  if (isSuccess) {
    return <Success>Модель успешно отредактирована!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <TariffsFormInputs />
      </FormProvider>
      <Button
        className="w-fit"
        disabled={!isDirty || isUpdating || isTypeLoading}
      >
        Изменить
      </Button>
    </form>
  );
};
