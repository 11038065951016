import { FC, memo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { EditCompanyValues } from "../../models/forms/EditCompanyValues";
import { Switch } from "../Checkbox/Switch";
import { Input } from "../Input/Input";
import { Title } from "../Title/Title";
import { CustomSelect } from "../Select/CustomSelect";
import {
  useGetCompanyCategoriesQuery,
  useSearchBankMutation,
  useSearchCompanyMutation,
} from "services/companiesApi";
import { useSearchAddressesMutation } from "services/orderApi";
import { SearchInput } from "../Input/SearchInput";
import { COMPANY_CATEGORY } from "common/utils/consts";
import { SvgPlus } from "assets/icons/SvgPlus";
import { SvgTrash } from "assets/icons/SvgTrash";
import { Slider } from "../Slider/Slider";

export const CompanyFormInputs: FC<{
  readOnly?: boolean;
  isCreating?: boolean;
  isModeration?: boolean;
}> = memo(({ readOnly = false, isCreating = false, isModeration = false }) => {
  const { data: categories } = useGetCompanyCategoriesQuery(undefined, {
    skip: !isCreating && !isModeration,
  });

  const [
    searchBanks,
    { isLoading: isBanksLoading, data: banks, reset: resetBanksQuery },
  ] = useSearchBankMutation();

  const [
    searchCompanies,
    {
      isLoading: isCompaniesLoading,
      data: companies,
      reset: resetCompaniesQuery,
    },
  ] = useSearchCompanyMutation();

  const [searchAddress, { data: addresses, isLoading: isAddressesLoading }] =
    useSearchAddressesMutation();

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<EditCompanyValues>();

  const isWorkHimSelf = useWatch({
    control,
    name: "is_works_for_himself",
  });

  const companyCategoryId = useWatch({
    control,
    name: "company_category_id",
  });

  const currentLogotype = useWatch({
    control,
    name: "logotype",
  });

  const currentDocuments = useWatch({
    control,
    name: "documents",
  });

  const setNewBank = (name: string) => {
    const currentBank = banks?.find((bank) => bank.name === name);

    if (currentBank) {
      setValue("kpp", currentBank.kpp);
      setValue("bik", currentBank.bik);
      setValue("bank_name", currentBank.name);
    }
    resetBanksQuery();
  };

  const setNewCompany = (name: string) => {
    const currentCompany = companies?.find((company) => company.name === name);
    if (currentCompany) {
      setValue("name", currentCompany.name);
      setValue("orgn", currentCompany.orgn);
      setValue("inn", currentCompany.inn);
      setValue("legal_address", currentCompany.legal_address);
      setValue("post_address", currentCompany.legal_address);
    }
    resetCompaniesQuery();
  };

  const companyCategoryOptions = categories?.map((category) => ({
    label: category.name,
    value: category.id,
  }));

  return (
    <div>
      {true && (
        <div className="grid grid-cols-1 xl:grid-cols-2">
          <div>
            <Title
              className={`block ${
                !!errors.logotype ? "text-red" : "text-gray"
              } mb-[15px]`}
            >
              Логотип
            </Title>
            {isModeration && !currentLogotype ? (
              "Нет"
            ) : (
              <div className="mb-[30px]">
                {!isModeration && !readOnly && (
                  <span className={`mb-[12px] block text-sm text-lightGray`}>
                    Перетяните фото для загрузки
                  </span>
                )}

                <div
                  className={`relative w-fit rounded border-[2px] border-dashed bg-whiteGray transition focus-within:border-primary ${
                    !!errors.logotype ? "border-red" : "border-grablacky"
                  }`}
                >
                  <Controller
                    control={control}
                    name="logotype"
                    render={(props) => {
                      return (
                        <input
                          {...props}
                          className={`h-[222px] w-[230px] cursor-pointer rounded bg-lightWhiteGray px-[20px] 
              py-[15px] text-[13px] leading-[16px] tracking-wider opacity-0
              outline-none transition placeholder:text-[13px]`}
                          type="file"
                          accept="image/*"
                          disabled={readOnly || isModeration}
                          onChange={(e) => {
                            props.field.onChange(e.target.files);
                            setValue("logotype", e.target.files || undefined);
                          }}
                        />
                      );
                    }}
                  />

                  {currentLogotype ? (
                    <img
                      className="pointer-events-none absolute top-0 left-0 right-0 bottom-0 h-full w-full object-cover"
                      src={
                        currentLogotype
                          ? typeof currentLogotype[0] === "object"
                            ? URL.createObjectURL(currentLogotype[0])
                            : typeof currentLogotype === "string"
                            ? currentLogotype
                            : undefined
                          : undefined
                      }
                      alt="Preview"
                    />
                  ) : !isModeration && !readOnly ? (
                    <SvgPlus className="pointer-events-none absolute left-[50%] top-[50%] h-[30px] w-[30px] translate-x-[-50%] translate-y-[-50%] text-primary" />
                  ) : (
                    <span className="pointer-events-none absolute left-[50%] top-[50%] h-[30px] w-[30px] translate-x-[-50%] translate-y-[-50%]">
                      Нет
                    </span>
                  )}
                  {currentLogotype && !isModeration && !readOnly && (
                    <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                      <button
                        type="button"
                        className="rounded bg-primary p-[8px] text-white transition
                        hover:bg-white hover:text-red focus:bg-white focus:text-red active:bg-primary active:text-white"
                        onClick={() => {
                          setValue("logotype", undefined);
                        }}
                      >
                        <SvgTrash className="h-[30px] w-[30px]" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="relative mb-[30px]">
            <Title className="mb-[15px] text-gray">
              Подтверждающие документы
            </Title>
            {isModeration && !currentLogotype ? (
              "Нет"
            ) : (
              <>
                {!isModeration && !readOnly && (
                  <span className={`mb-[12px] block text-sm text-lightGray`}>
                    Перетяните фото для загрузки
                  </span>
                )}
                <div
                  className={`relative w-fit rounded border-[2px] border-dashed bg-whiteGray transition focus-within:border-primary ${
                    !!errors.documents ? "border-red" : "border-grablacky"
                  }`}
                >
                  <Controller
                    control={control}
                    name="documents"
                    rules={{ required: true }}
                    render={(props) => {
                      return (
                        <input
                          {...props}
                          className={`h-[222px] w-[230px] cursor-pointer rounded bg-lightWhiteGray px-[20px] 
              py-[15px] text-[13px] leading-[16px] tracking-wider opacity-0
              outline-none transition placeholder:text-[13px]`}
                          type="file"
                          accept="image/*"
                          multiple
                          disabled={readOnly || isModeration}
                          onChange={(e) => {
                            props.field.onChange(e.target.files);
                            setValue("documents", e.target.files || undefined);
                          }}
                        />
                      );
                    }}
                  />
                  {currentDocuments && (
                    <div className="absolute bottom-0 top-0 right-0 left-0 overflow-hidden">
                      <Slider
                        className="multiple rounded"
                        expandable
                        images={(Array.isArray(currentDocuments)
                          ? currentDocuments
                          : Array.from(currentDocuments)
                        )?.map((doc) =>
                          typeof doc === "string"
                            ? doc
                            : URL.createObjectURL(doc)
                        )}
                      />
                    </div>
                  )}
                  {currentDocuments && !isModeration && !readOnly && (
                    <div className="absolute left-[50%] top-[50%] z-10 translate-x-[-50%] translate-y-[-50%]">
                      <button
                        type="button"
                        className="rounded bg-primary p-[8px] text-white transition
                        hover:bg-white hover:text-red focus:bg-white focus:text-red active:bg-primary active:text-white"
                        onClick={() => {
                          setValue("documents", undefined);
                        }}
                      >
                        <SvgTrash className="h-[30px] w-[30px]" />
                      </button>
                    </div>
                  )}
                  {!isModeration && !readOnly && !currentDocuments?.length && (
                    <SvgPlus className="pointer-events-none absolute left-[50%] top-[50%] h-[30px] w-[30px] translate-x-[-50%] translate-y-[-50%] text-primary" />
                  )}
                  {(isModeration || readOnly) && !currentDocuments?.length && (
                    <span className="pointer-events-none absolute left-[50%] top-[50%] h-[30px] w-[30px] translate-x-[-50%] translate-y-[-50%]">
                      Нет
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <Title className="mb-[15px] text-gray">Юридические реквизиты</Title>
      <div
        className={`mb-[30px] grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-x-[15px] gap-y-[25px]`}
      >
        <div className="grid  gap-y-[25px]">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Данное поле обязательно" },
              minLength: {
                value: 5,
                message: "Минимум 5 символов",
              },
              maxLength: {
                value: 1024,
                message: "Максимум 1024 символов",
              },
            }}
            name="name"
            render={(inputProps) => {
              return (
                <SearchInput
                  menuIsOpen={readOnly ? false : undefined}
                  reference={inputProps.field.ref}
                  label="Название *"
                  placeholder="Введите название..."
                  queryField="name"
                  filterOption={() => true}
                  value={inputProps.field.value}
                  invalid={!!errors.name}
                  invalidMessage={errors.name?.message}
                  inputValue={inputProps.field.value}
                  data={companies}
                  isLoading={isCompaniesLoading}
                  onSearch={searchCompanies}
                  onChange={setNewCompany}
                  onInputChange={(inputStr, action) => {
                    if (action.action === "input-change" && !readOnly)
                      inputProps.field.onChange(inputStr);
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Данное поле обязательно" },
              minLength: {
                value: 5,
                message: "Минимум 5 символов",
              },
            }}
            name="inn"
            render={(inputProps) => {
              return (
                <SearchInput
                  menuIsOpen={readOnly ? false : undefined}
                  reference={inputProps.field.ref}
                  label="ИНН *"
                  placeholder="Введите ИНН..."
                  queryField="inn"
                  filterOption={() => true}
                  value={inputProps.field.value}
                  invalid={!!errors.inn}
                  invalidMessage={errors.inn?.message}
                  inputValue={inputProps.field.value}
                  data={companies}
                  isLoading={isCompaniesLoading}
                  onSearch={searchCompanies}
                  onChange={setNewCompany}
                  onInputChange={(inputStr, action) => {
                    if (action.action === "input-change" && !readOnly)
                      inputProps.field.onChange(inputStr);
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Данное поле обязательно" },
              minLength: {
                value: 5,
                message: "Минимум 5 символов",
              },
            }}
            name="orgn"
            render={(inputProps) => {
              return (
                <SearchInput
                  menuIsOpen={readOnly ? false : undefined}
                  reference={inputProps.field.ref}
                  label="ОГРН *"
                  placeholder="Введите ОГРН..."
                  queryField="ogrn"
                  filterOption={() => true}
                  value={inputProps.field.value}
                  invalidMessage={errors.orgn?.message}
                  invalid={!!errors.orgn}
                  inputValue={inputProps.field.value}
                  data={companies}
                  isLoading={isCompaniesLoading}
                  onSearch={searchCompanies}
                  onChange={setNewCompany}
                  onInputChange={(inputStr, action) => {
                    if (action.action === "input-change" && !readOnly)
                      inputProps.field.onChange(inputStr);
                  }}
                />
              );
            }}
          />
        </div>
        <div className="grid h-fit gap-y-[25px]">
          {(isCreating || isModeration) && companyCategoryOptions && (
            <Controller
              control={control}
              rules={{
                required: { value: true, message: "Данное поле обязательно" },
              }}
              name="company_category_id"
              render={(inputProps) => {
                return (
                  <CustomSelect
                    reference={inputProps.field.ref}
                    invalid={!!errors.company_category_id}
                    invalidMessage={errors.company_category_id?.message}
                    options={companyCategoryOptions}
                    label="Категория компании *"
                    placeholder="Выберите категорию..."
                    value={inputProps.field.value}
                    filterOption={() => true}
                    inputValue={
                      companyCategoryOptions.find(
                        (item) => item.value === inputProps.field.value
                      )?.label
                    }
                    menuIsOpen={readOnly ? false : undefined}
                    onChange={(val) => inputProps.field.onChange(val.value)}
                  />
                );
              }}
            />
          )}
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Данное поле обязательно" },
              minLength: {
                value: 5,
                message: "Минимум 5 символов",
              },
              maxLength: {
                value: 1024,
                message: "Максимум 1024 символов",
              },
            }}
            name="legal_address"
            render={(inputProps) => {
              return (
                <SearchInput
                  menuIsOpen={readOnly ? false : undefined}
                  reference={inputProps.field.ref}
                  label="Юридический адрес *"
                  placeholder="Введите адрес..."
                  queryField="name"
                  filterOption={() => true}
                  value={inputProps.field.value}
                  invalid={!!errors.legal_address}
                  invalidMessage={errors.legal_address?.message}
                  inputValue={inputProps.field.value}
                  data={addresses}
                  isLoading={isAddressesLoading}
                  onSearch={searchAddress}
                  onChange={(val) => inputProps.field.onChange(val)}
                  onInputChange={(inputStr, action) => {
                    if (action.action === "input-change" && !readOnly)
                      inputProps.field.onChange(inputStr);
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Данное поле обязательно" },
              minLength: {
                value: 5,
                message: "Минимум 5 символов",
              },
              maxLength: {
                value: 1024,
                message: "Максимум 1024 символов",
              },
            }}
            name="post_address"
            render={(inputProps) => {
              return (
                <SearchInput
                  menuIsOpen={readOnly ? false : undefined}
                  reference={inputProps.field.ref}
                  label="Почтовый адрес"
                  placeholder="Введите адрес..."
                  queryField="name"
                  filterOption={() => true}
                  value={inputProps.field.value || ""}
                  invalid={!!errors.post_address}
                  invalidMessage={errors.post_address?.message}
                  inputValue={inputProps.field.value || ""}
                  data={addresses}
                  isLoading={isAddressesLoading}
                  onSearch={searchAddress}
                  onChange={(val) => inputProps.field.onChange(val)}
                  onInputChange={(inputStr, action) => {
                    if (action.action === "input-change" && !readOnly)
                      inputProps.field.onChange(inputStr);
                  }}
                />
              );
            }}
          />
        </div>
      </div>
      <Title className="mb-[15px] text-gray">Банковские реквизиты</Title>
      <div className="mb-[30px] grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-y-[25px] gap-x-[15px]">
        <div className="grid h-fit gap-y-[25px]">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Данное поле обязательно" },
              minLength: {
                value: 5,
                message: "Минимум 5 цифр",
              },
            }}
            name="bank_name"
            render={(inputProps) => {
              return (
                <SearchInput
                  menuIsOpen={readOnly ? false : undefined}
                  reference={inputProps.field.ref}
                  label="Название банка *"
                  placeholder="Введите банк..."
                  queryField="name"
                  filterOption={() => true}
                  value={inputProps.field.value}
                  invalid={!!errors.bank_name}
                  invalidMessage={errors.bank_name?.message}
                  inputValue={inputProps.field.value}
                  data={banks}
                  isLoading={isBanksLoading}
                  onSearch={searchBanks}
                  onChange={setNewBank}
                  onInputChange={(inputStr, action) => {
                    if (action.action === "input-change" && !readOnly) {
                      inputProps.field.onChange(inputStr);
                    }
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Данное поле обязательно" },
              minLength: {
                value: 5,
                message: "Минимум 5 цифр",
              },
            }}
            name="kpp"
            render={(inputProps) => {
              return (
                <SearchInput
                  menuIsOpen={readOnly ? false : undefined}
                  reference={inputProps.field.ref}
                  label="КПП *"
                  placeholder="Введите КПП..."
                  queryField="name"
                  filterOption={() => true}
                  value={inputProps.field.value}
                  invalid={!!errors.kpp}
                  inputValue={inputProps.field.value}
                  invalidMessage={errors.kpp?.message}
                  data={banks}
                  isLoading={isBanksLoading}
                  onSearch={searchBanks}
                  onChange={setNewBank}
                  onInputChange={(inputStr, action) => {
                    if (action.action === "input-change" && !readOnly) {
                      inputProps.field.onChange(inputStr);
                    }
                  }}
                />
              );
            }}
          />
        </div>
        <div className="grid h-fit gap-y-[25px]">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Данное поле обязательно" },
              minLength: {
                value: 5,
                message: "Минимум 5 цифр",
              },
            }}
            name="bik"
            render={(inputProps) => {
              return (
                <SearchInput
                  menuIsOpen={readOnly ? false : undefined}
                  reference={inputProps.field.ref}
                  label="БИК *"
                  placeholder="Введите БИК..."
                  queryField="name"
                  filterOption={() => true}
                  value={inputProps.field.value}
                  invalid={!!errors.bik}
                  invalidMessage={errors.bik?.message}
                  inputValue={inputProps.field.value}
                  data={banks}
                  isLoading={isBanksLoading}
                  onSearch={searchBanks}
                  onChange={setNewBank}
                  onInputChange={(inputStr, action) => {
                    if (action.action === "input-change" && !readOnly) {
                      inputProps.field.onChange(inputStr);
                    }
                  }}
                />
              );
            }}
          />
          <Input
            {...register("payment_account", {
              required: { value: true, message: "Данное поле обязательно" },
              minLength: {
                value: 20,
                message: "Данное поле должно состоять из 20 цифр",
              },
              maxLength: {
                value: 20,
                message: "Данное поле должно состоять из 20 цифр",
              },
            })}
            placeholder="Введите расчётный счёт..."
            readOnly={readOnly}
            invalid={!!errors.payment_account}
            invalidMessage={errors.payment_account?.message}
            type="number"
            className="w-full"
            label="Расчётный счёт *"
          />
        </div>
      </div>

      {((!readOnly && companyCategoryId === COMPANY_CATEGORY.IMPLEMENTER) ||
        isModeration) && (
        <div className="mb-[30px]">
          <label className="mb-[12px] flex items-center justify-between text-sm font-semibold">
            Работаю сам на себя
            <Switch
              {...register("is_works_for_himself")}
              readOnly={readOnly}
              value={1}
              checked={!!isWorkHimSelf}
            />
          </label>
          <p className="mb-[15px] text-sm text-lightGray">
            Если вы выбираете работать самому на себя то у вас будет возможность
            принимать заказы только на своей технике в аккаунте водителя!
          </p>
        </div>
      )}
    </div>
  );
});
