import { FC } from "react";
import { NavLink } from "react-router-dom";
import { SvgLogo } from "../../../assets/logo/SvgLogo";
import { implementerCompanyManagerRoutesLinks } from "../../../pages/roles/ImplementerCompanyManagerPage";
import { useAppSelector } from "../../../app/hooks";
import { SidebarLinkItem } from "common/components/Navigation/LinkItem";

export const ImplementerCompanyManagerSidebar: FC = () => {
  const currentCompany = useAppSelector(
    (state) => state.user.current?.account?.company
  );

  return (
    <aside className="z-10 flex w-[90px] flex-col items-center bg-lightWhiteGray">
      <NavLink
        to="/"
        className="flex w-full items-center justify-center pt-[15px] pb-[18px] text-primary hover:text-black focus:text-black active:text-primary"
      >
        <SvgLogo className="h-[34px] w-[34px] transition" />
      </NavLink>
      {implementerCompanyManagerRoutesLinks.map((route) =>
        !currentCompany && route.isCompanyRequired ? null : (
          <SidebarLinkItem
            key={route.path}
            to={route.path}
            label={route.label}
            icon={route.Icon}
          />
        )
      )}
    </aside>
  );
};
