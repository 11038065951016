import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgListDashes } from "../../../assets/icons/SvgListDashes";
import { Container } from "../../../common/components/Container/Container";
import { OrdersFilter } from "../../../common/components/Filter/OrdersFilter";
import { OrderList } from "../../../common/components/Order/OrderList";
import { AssignmentVehicle } from "../../../common/components/VehicleAssignment/AssignmentVehicle";
import {
  orderFiltersSelector,
  requestVehicleAssignmentSelector,
  resetAssignment,
  resetFilter,
  setAssigningDriverId,
  setAssigningVehicleId,
  setAssigningVehicleTypeId,
  setAssignmentRequestId,
  setFilterPeriod,
  setFilterTimeAt,
  setFilterTimeTo,
  setFilterVehicles,
} from "./requestsSlice";
import { withErrorBoundary } from "common/utils/hoc/withErrorBoundary";

export const RequestsPage: FC = withErrorBoundary(() => {
  const dispatch = useAppDispatch();

  const assignedVehicleTypeId = useAppSelector(
    (state) =>
      state.implementerCompanyManager.requests.vehicleAssignment
        .assignedVehicleTypeId
  );
  const userId = useAppSelector((state) => state.user.current?.id);
  const requestFilters = useAppSelector(
    (state) => state.implementerCompanyManager.requests.filters
  );
  const activeOrderId = useAppSelector(
    (state) =>
      state.implementerCompanyManager.requests.vehicleAssignment.activeOrderId
  );
  const currentCompanyId = useAppSelector(
    (state) => state.user.current?.account?.company?.id
  );

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const handleSetAssignmentVehicleType = (id: number) => {
    dispatch(setAssigningVehicleTypeId(id));
  };

  const handleSetAssignmentRequestId = (id: number) => {
    dispatch(setAssignmentRequestId(id));
  };

  const handleVehicleAssign = (orderId: number, vehicleTypeId: number) => {
    handleSetAssignmentRequestId(orderId);
    handleSetAssignmentVehicleType(vehicleTypeId);
  };

  return (
    <Container className="pointer-events-none bg-transparent">
      <button
        onClick={() => setIsFilterOpen((prev) => !prev)}
        className="pointer-events-auto absolute top-[16px] right-[16px] flex h-[44px] w-[44px] items-center justify-center rounded border border-transparent bg-lightWhiteGray shadow-md 
        outline-none transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary active:text-black"
      >
        <SvgListDashes className="h-[26px] w-[26px]" />
      </button>
      {isFilterOpen && (
        <OrdersFilter
          stateSelector={orderFiltersSelector}
          handleFilterReset={resetFilter}
          handleFilterVehiclesChange={setFilterVehicles}
          handlePeriodSet={setFilterPeriod}
          handleTimeAtSet={setFilterTimeAt}
          handleTimeToSet={setFilterTimeTo}
          setIsFilterOpen={setIsFilterOpen}
          initialOrderStatuses={requestFilters.period}
        />
      )}
      <OrderList
        activeOrderId={activeOrderId}
        withAddresses
        withDetails
        filter={{
          statusId: requestFilters.period,
          vehicleCategoryId: requestFilters.vehicles,
          dateFrom: requestFilters.timeAt,
          dateTo: requestFilters.timeTo,
          executorCompanyId: currentCompanyId,
        }}
        onVehicleAssign={handleVehicleAssign}
      />
      {assignedVehicleTypeId && currentCompanyId && (
        <div className="pointer-events-auto h-full w-[515px] overflow-y-hidden">
          <div className="h-full overflow-y-scroll rounded bg-white p-[15px]">
            <AssignmentVehicle
              assignmentStateSelector={requestVehicleAssignmentSelector}
              companyId={currentCompanyId}
              handleAssignDriver={setAssigningDriverId}
              handleAssignVehicles={setAssigningVehicleId}
              handleAssignReset={resetAssignment}
            />
          </div>
        </div>
      )}
    </Container>
  );
});
