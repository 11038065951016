import { FC } from "react";
import { Button } from "../../../../common/components/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { TariffsFormInputs } from "./TariffsFormInputs";
import { useCreateVehicleTypeMutation } from "services/vehicle/vehicleTypesApi";
import { VehicleTypeFormValues } from "common/models/forms/VehicleTypeFormValues";
import { getErrorMessage } from "common/utils/helpers";
import { Error } from "common/components/Message/Error/Error";
import { Success } from "common/components/Message/Success/Success";

export const TariffsAdd: FC = () => {
  const methods = useForm<VehicleTypeFormValues>();

  const [create, { isLoading, error, isSuccess }] =
    useCreateVehicleTypeMutation();

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((data) => {
    create(data);
  });

  if (error) {
    return <Error>{getErrorMessage(error)?.data.message}</Error>;
  }

  if (isSuccess) {
    return <Success>Модель успешно создана!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <TariffsFormInputs />
      </FormProvider>
      <Button className="w-fit" disabled={isLoading}>
        Сохранить
      </Button>
    </form>
  );
};
