import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { SvgSuccess } from "assets/icons/SvgSuccess";
import { ShadowContainer } from "common/components/Container/ShadowContainer";
import { Title } from "common/components/Title/Title";
import { Button } from "common/components/Button/Button";
import { Input } from "common/components/Input/Input";
import { TextArea } from "common/components/TextArea/TextArea";
import { useCreateAppealMutation } from "services/appealApi";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { getErrorMessage } from "common/utils/helpers";

export const AppealAdd: FC = () => {
  const methods = useForm<{ title: string; text: string }>();

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = methods;

  const [createAppeal, { isLoading, isSuccess, error, reset: resetQuery }] =
    useCreateAppealMutation();

  const { showModal } = useGlobalModalContext();

  const showErrorModal = (erorrText?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Ошибка при отправке обращения",
      subTitle:
        erorrText ||
        "При отправке обращения произошла ошибка, повторите попытку позже",
    });
  };

  useEffect(() => {
    if (error) {
      showErrorModal(getErrorMessage(error)?.data.message);
    }
  }, [error]);

  const onSubmit = handleSubmit((data) => {
    createAppeal(data);
  });

  const handleResetCreating = () => {
    resetForm();
    resetQuery();
  };

  return (
    <ShadowContainer>
      <Title className="mb-[25px]">Добавить обращение</Title>
      {isSuccess && (
        <div className="grid grid-rows-[1fr,45px]">
          <div className="flex h-[200px] flex-col items-center justify-center text-primary">
            <SvgSuccess className="mb-[15px] h-[50px] w-[50px]" />
            <Title className="text-center">Обращение успешно отправлено</Title>
          </div>
          <Button onClick={handleResetCreating}>Продолжить</Button>
        </div>
      )}
      {!isSuccess && (
        <form onSubmit={onSubmit}>
          <div className="mb-[40px] grid items-end gap-[15px]">
            <Input
              {...register("title", {
                required: { value: true, message: "Данное поле обязательно" },
                minLength: { value: 5, message: "Минимум 5 символов" },
              })}
              placeholder="Введите тему обращения"
              invalid={!!errors.title}
              invalidMessage={errors.title?.message}
              className="w-[50%]"
              label="Тема обращения"
            />
            <TextArea
              {...register("text", {
                required: { value: true, message: "Данное поле обязательно" },
                minLength: { value: 20, message: "Минимум 20 символов" },
              })}
              placeholder="Введите детали обращения"
              invalid={!!errors.text}
              invalidMessage={errors.text?.message}
              className="min-h-[200px] resize-none"
              label="Текст обращения"
            />
          </div>
          <Button disabled={isLoading} type="submit" className="px-[90px]">
            Отправить
          </Button>
        </form>
      )}
    </ShadowContainer>
  );
};
