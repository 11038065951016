import { FC, forwardRef } from "react";
import { SvgCheck } from "../../../assets/icons/SvgCheck";
import { CustomInputProps } from "../../models/components/input/ICustomInputProps";

export const VehicleInput: FC<CustomInputProps> = forwardRef<
  HTMLInputElement,
  CustomInputProps
>(({ className = "", ...props }, ref) => {
  const isActive = props.checked;

  const isInnerString = typeof props.innerelement === "string";

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <label
      className={`grid ${
        isActive ? "grid-cols-[0,_1fr,30px]" : "grid-cols-[0px,_1fr,0]"
      } cursor-pointer items-center rounded p-[15px]
      ${
        isActive ? "border-primary" : "border-lightWhiteGray"
      } border-[1px] outline-none
      transition focus-within:border-primary hover:border-primary ${className}`}
    >
      <input
        ref={ref}
        {...props}
        className="appearance-none focus:outline-none"
        onChange={handleOnInputChange}
      />
      {isInnerString ? (
        <span className="text-base font-semibold text-gray">
          {props.innerelement}
        </span>
      ) : (
        props.innerelement
      )}
      {isActive && (
        <SvgCheck
          className={`ml-auto h-[9px] w-[12px] text-primary transition`}
        />
      )}
    </label>
  );
});
