import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdministratorHeader } from "../../features/administrator/header/AdministratorHeader";
import { AdministratorSidebar } from "../../features/administrator/sidebar/AdministratorSidebar";
import { OrdersPage } from "../../features/administrator/orders/OrdersPage";
import { UsersPage } from "../../features/administrator/users/UsersPage";
import { VehiclesPage } from "../../features/administrator/vehicles/VehiclesPage";
import { FinancePage } from "../../features/administrator/finance/FinancePage";
import { AdminWorkPage } from "../../features/administrator/adminWork/AdminWorkPage";
import { AttendancePage } from "../../features/administrator/attendance/AttendancePage";
import { DirectoryPage } from "../../features/administrator/directory/DirectoryPage";

export const AdministratorPage: FC = () => {
  return (
    <>
      <AdministratorSidebar />
      <div className="grid w-full grid-rows-[105px,calc(100vh-105px)]">
        <AdministratorHeader />
        <main className="grid h-full grid-rows-[minmax(0,35px),1fr] gap-[10px] overflow-y-scroll p-[15px]">
          <Routes>
            <Route path="/orders/*" element={<OrdersPage />} />
            <Route path="/users/*" element={<UsersPage />} />
            <Route path="/vehicles/*" element={<VehiclesPage />} />
            <Route path="/finance/*" element={<FinancePage />} />
            <Route path="/admin-work/*" element={<AdminWorkPage />} />
            <Route path="/attendance/*" element={<AttendancePage />} />
            <Route path="/directory/*" element={<DirectoryPage />} />
            <Route path="/*" element={<Navigate to="/orders/completed" />} />
          </Routes>
        </main>
      </div>
    </>
  );
};
