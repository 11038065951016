import { FC, memo } from "react";
import { NavLink } from "react-router-dom";
import { SvgLogo } from "../../../assets/logo/SvgLogo";
import { SidebarLinkItem } from "common/components/Navigation/LinkItem";

export const PlatformManagerSidebar: FC = memo(() => {
  return (
    <aside className="z-10 flex w-[90px] flex-col items-center bg-lightWhiteGray">
      <NavLink
        to="/"
        state="Рабочий стол"
        className="flex w-full items-center justify-center pt-[15px] pb-[18px] text-primary hover:text-black focus:text-black active:text-primary"
      >
        <SvgLogo className="h-[34px] w-[34px] transition " />
      </NavLink>
      {homeRoutesLinks.map((route) => (
        <SidebarLinkItem
          className="px-[15px] py-[15px]"
          key={route.path}
          to={route.path}
          state={route.label}
          label={route.label}
        />
      ))}
    </aside>
  );
});

export const homeRoutesLinks = [
  {
    label: "Помощь в подборе техники",
    path: "/",
  },
  {
    label: "Споры",
    path: "/dispute",
  },
  {
    label: "Отменённые заказы",
    path: "/canceld-orders",
  },
  // {
  //   label: "Гостевые заказы",
  //   path: "/guest-orders",
  // },
  // {
  //   label: 'Бухгалтерия',
  //   path: '/accounting'
  // },
  {
    label: "Счета",
    path: "/invoice",
  },
  {
    label: "Модерация компаний",
    path: "/companies-moderation",
  },
  {
    label: "Модерация техники",
    path: "/vehicle-moderation",
  },
  {
    label: "Модерация завершённых заказов",
    path: "/finished-order-moderation",
  },
  {
    label: "Рабочий стол",
    path: "/desktop",
  },
];
