import { FC } from "react";
import { Button } from "../../../../../common/components/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { OptionFormInputs } from "./OptionFormInputs";
import { useCreateVehicleOptionMutation } from "services/vehicle/optionsApi";
import { Error } from "common/components/Message/Error/Error";
import { Success } from "common/components/Message/Success/Success";
import { getErrorMessage } from "common/utils/helpers";

export const OptionAdd: FC<{ vehicleCategoryId: number }> = ({
  vehicleCategoryId,
}) => {
  const [create, { isLoading, error, isSuccess }] =
    useCreateVehicleOptionMutation();

  const methods = useForm<{
    name: string;
    price: number;
  }>();

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((data) => {
    create({ ...data, vehicle_category_id: vehicleCategoryId });
  });

  if (error) {
    return <Error>{getErrorMessage(error)?.data.message}</Error>;
  }

  if (isSuccess) {
    return <Success>Марка успешно создана!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <OptionFormInputs />
      </FormProvider>
      <Button className="w-fit" disabled={isLoading}>
        Сохранить
      </Button>
    </form>
  );
};
