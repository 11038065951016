import { FC, useEffect } from "react";
import { SvgMapPin } from "../../../assets/icons/SvgMapPin";
import { Input } from "../Input/Input";
import { TextArea } from "../TextArea/TextArea";
import { Button } from "../Button/Button";
import { useGlobalModalContext } from "../Modal/GlobalModal";
import { OrderItemCard } from "./OrderItemCard";
import { SubTitle } from "../Title/SubTitle";
import { VehicleListItem } from "../Vehicle/VehicleListItem";
import { WideIconButton } from "../Button/WideIconButton";
import { SvgPlus } from "../../../assets/icons/SvgPlus";
import { useAppSelector } from "../../../app/hooks";
import {
  useDetachOrderCompanyMutation,
  useGetOrderByIdQuery,
} from "../../../services/orderApi";
import { Spinner } from "../Spinner/Spinner";
import { QueryLayout } from "../Layout/QueryLayout";
import {
  COMPANY_CATEGORY,
  FILE_TYPE,
  MODAL_TYPES,
  ORDER_STATUS,
  USER_ROLE,
} from "../../utils/consts";
import { Alert } from "../Alert/Alert";
import { IOrderItemFullDetailsProps } from "common/models/components/order/IOrderItemFullDetailsProps";
import { ProfileCard } from "../Profile/ProfileCard";
import { Slider } from "../Slider/Slider";
import { NotFoundTitle } from "../Title/NotFoundTitle";
import { MaskedInput } from "../Input/MaskedInput";
import { useNavigate } from "react-router-dom";
import { OrderMessage } from "../OrderMessage/OrderMessage";

import "swiper/css";
import { getErrorMessage } from "common/utils/helpers";
import { IAddress } from "common/models/order/IAddress";

export const OrderItemFullDetails: FC<IOrderItemFullDetailsProps> = ({
  orderId,
  readOnly,
  withTracking,
  setActiveOrderId,
  setAssigningOrderId,
  onVehicleAssign,
  onRouteShow,
}) => {
  const {
    data: order,
    isLoading: isOrderLoading,
    error: orderError,
  } = useGetOrderByIdQuery({ id: orderId });

  const [detachOrder, { isLoading: isDetaching, error: detachError }] =
    useDetachOrderCompanyMutation();

  const userCompanyCategoryId = useAppSelector(
    (state) => state.user.current?.account.company?.category.id
  );
  const userRoleId = useAppSelector(
    (state) => state.user.current?.account.role.id
  );
  const currentCompanyId = useAppSelector(
    (state) => state.user.current?.account.company?.id
  );
  const currentUserId = useAppSelector(
    (state) => state.user.current?.account?.id
  );

  const { showModal } = useGlobalModalContext();

  const navigate = useNavigate();

  const handleSetActiveOrder = (id?: number) => {
    if (setActiveOrderId) setActiveOrderId(id);
  };

  const handleSetAssigningOrderId = (orderId: number) => {
    if (setAssigningOrderId) setAssigningOrderId(orderId);
  };

  const handleShowOnTheMap = (addresses: IAddress[]) => {
    if (onRouteShow) {
      onRouteShow(
        addresses.map((address) => ({
          longitude: address.longitude,
          latitude: address.latitude,
        }))
      );
      navigate("/");
    }
  };

  const handleOnAssign = (orderData: { id: number; vehicleTypeId: number }) => {
    handleSetAssigningOrderId(orderData.id);
    if (onVehicleAssign) {
      onVehicleAssign(orderData.vehicleTypeId);
    }
  };

  const showOrderDeleteModal = () => {
    showModal(MODAL_TYPES.ORDER_CANCELATION_MODAL, {
      orderId,
    });
  };

  const showErrorModal = (message: Undefinable<string>) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Ошибка при откреплении заказа от компании",
      subTitle:
        message ||
        "При откреплении заказа от компании возникла ошибка, повторите попытку позже",
    });
  };

  const showDetachConfirmationModal = () => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      title: "Открепление заказа от компании",
      subTitle: "Вы уверены что хотите открепить данный заказ от компании?",
      onConfirm: () => {
        detachOrder(orderId);
        handleSetActiveOrder(undefined);
      },
    });
  };

  useEffect(() => {
    if (detachError) {
      showErrorModal(getErrorMessage(detachError)?.data.message);
    }
  }, [detachError]);

  if (!order) return <Spinner />;

  const isOrderEnded = !![
    ORDER_STATUS.FINISHED,
    ORDER_STATUS.REJECTED,
  ].includes(order?.status?.id);

  const isOrderComplete = !![ORDER_STATUS.FINISHED].includes(order?.status?.id);

  const isDriverChoosing = !![ORDER_STATUS.DRIVER_CHOOSING].includes(
    order?.status?.id
  );

  const canAssignVehicle =
    (userRoleId === USER_ROLE.ADMINISTRATOR &&
      [COMPANY_CATEGORY.IMPLEMENTER, COMPANY_CATEGORY.PLATFORM].includes(
        userCompanyCategoryId || -1
      )) ||
    (userRoleId === USER_ROLE.MANAGER &&
      userCompanyCategoryId === COMPANY_CATEGORY.IMPLEMENTER);

  const orderPhotos = order.photos.filter(
    (photo) => photo.type.id === FILE_TYPE.CARGO_PHOTO
  );

  const canDetachOrder =
    [COMPANY_CATEGORY.IMPLEMENTER, COMPANY_CATEGORY.PLATFORM].includes(
      userCompanyCategoryId || -1
    ) && order?.executor_company?.id === currentCompanyId;

  const canDeleteOrder =
    [COMPANY_CATEGORY.PLATFORM].includes(userCompanyCategoryId || -1) ||
    order?.account?.id === currentUserId;

  return (
    <QueryLayout isLoading={isOrderLoading} error={orderError}>
      {order && (
        <div>
          <div className="mb-[25px]">
            <OrderItemCard isActive={false} order={order} />
          </div>
          {withTracking && (
            <div className="mb-[15px]">
              <OrderMessage order={order} />
            </div>
          )}
          {order.vehicle && order.driver && (
            <div className="mb-[25px] grid grid-cols-2 gap-[25px]">
              <div>
                <SubTitle className="mb-[10px]">Назаченная техника</SubTitle>
                <div className="rounded border border-lightWhiteGray p-[15px]">
                  <VehicleListItem
                    mode="medium"
                    name={order.vehicle_category.name}
                    img={order.vehicle_category.icon.path}
                    registrationNumber={order.vehicle.vehicle_number}
                    companyName="OOO Рога и копыта"
                    desc={order.vehicle_type.name}
                  />
                </div>
              </div>
              <div>
                <SubTitle className="mb-[10px]">Водитель</SubTitle>
                <div className="rounded border border-lightWhiteGray p-[15px]">
                  <ProfileCard
                    className="w-full"
                    name={
                      order.driver?.last_name
                        ? `${order.driver.last_name} ${order.driver.first_name}`
                        : order.driver.phone
                    }
                    primary
                    rating={order.driver.rate}
                    avatarurl={order.driver.avatar?.path}
                    subname="Частное лицо (старт)"
                  />
                </div>
              </div>
            </div>
          )}

          {!isOrderComplete &&
            !readOnly &&
            order.vehicle &&
            order.driver &&
            canAssignVehicle && (
              <div className="mb-[25px]">
                {order.confirmed_by_driver_at ? (
                  <Alert className="mb-[25px] text-sm">
                    Водитель подтвердил
                  </Alert>
                ) : (
                  <Alert className="mb-[25px] text-sm" mode="error">
                    Водитель ещё не подтвердил
                  </Alert>
                )}
                <WideIconButton
                  className="h-[45px] w-full text-center text-sm font-medium text-gray"
                  icon={
                    <SvgPlus className="h-[20px] w-[20px] rotate-45 text-primary" />
                  }
                  onClick={() =>
                    handleOnAssign({
                      id: order.id,
                      vehicleTypeId: order.vehicle_type.id,
                    })
                  }
                >
                  Назначить другого водителя
                </WideIconButton>
              </div>
            )}
          <div className="mb-[10px]">
            <h4 className="mb-[10px] text-sm font-semibold text-gray">
              Адреса
            </h4>
            <ul className="mb-[15px] grid gap-[7px]">
              {order.addresses.map((address) => (
                <li key={address.id}>
                  <button className="flex items-center text-black outline-none transition hover:text-primary focus:text-primary active:text-gray">
                    <SvgMapPin className="mr-[5px] h-[12px] w-[10px]" />
                    <address className="text-sm not-italic">
                      {address.name}
                    </address>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-[10px]">
            <h4 className="mb-[10px] text-sm font-semibold text-gray">
              Дополнительное оборудование
            </h4>
            <ul className="mb-[15px] grid items-center gap-[7px]">
              {order.options.length ? (
                order.options.map((equipment) => (
                  <li key={equipment.id} className="flex items-center">
                    <i className="mr-[12px] inline-block h-[14px] w-[14px] rounded-[50%] border-[2px] border-primary" />
                    <span className="text-sm">{equipment.name}</span>
                  </li>
                ))
              ) : (
                <h5 className="text-sm">Нет</h5>
              )}
            </ul>
          </div>
          <div className="mb-[10px] grid gap-[15px]">
            <div>
              <TextArea
                label="Опсиание заказа"
                readOnly
                className="w-full resize-none tracking-normal text-black"
                value={order.description || "Нет"}
              />
            </div>
            <div>
              <Input
                label="Личный данные"
                readOnly
                className="w-full tracking-normal text-black"
                value={`${order.last_name} ${order.first_name} ${
                  order.middle_name || ""
                }`}
              />
            </div>
            <div>
              <MaskedInput
                label="Телефон"
                readOnly
                className="w-full tracking-normal text-black"
                defaultValue={order.phone}
                type="tel"
                mask="+7 (999) 999 99-99"
              />
            </div>
          </div>
          <div className="mb-[10px] grid gap-[15px]">
            <h4 className="mb-[10px] text-sm font-semibold text-gray">Фото</h4>
            {orderPhotos?.length ? (
              <Slider
                className="multiple overflow-y-scroll"
                images={orderPhotos}
                expandable
              />
            ) : (
              <NotFoundTitle />
            )}
          </div>
          {!readOnly && (
            <div className="grid grid-cols-2 gap-[15px]">
              {isOrderComplete && (
                <>
                  <Button className="tracking-wider">
                    Загрузить путевой лист
                  </Button>

                  <Button
                    className="tracking-wider"
                    disabled={!order.photos.length}
                  >
                    Загрузить фото
                  </Button>
                </>
              )}
              {!isOrderEnded && !isDriverChoosing && (
                <>
                  <Button
                    onClick={() => handleShowOnTheMap(order.addresses)}
                    className="tracking-wider"
                  >
                    Посмотреть на карте
                  </Button>
                  {canDetachOrder && (
                    <Button
                      className="tracking-wider"
                      mode="darkBorder"
                      onClick={showDetachConfirmationModal}
                      disabled={isDetaching}
                    >
                      Снять заказ с компании
                    </Button>
                  )}
                  {canDeleteOrder && (
                    <Button
                      className="tracking-wider"
                      mode="darkBorder"
                      onClick={showOrderDeleteModal}
                    >
                      Отменить
                    </Button>
                  )}
                </>
              )}
              {isDriverChoosing && (
                <>
                  {canAssignVehicle && (
                    <Button
                      onClick={() =>
                        handleOnAssign({
                          id: order.id,
                          vehicleTypeId: order.vehicle_type.id,
                        })
                      }
                      className="tracking-wider"
                    >
                      Назначить технику
                    </Button>
                  )}

                  {canDeleteOrder && (
                    <Button
                      className="tracking-wider"
                      mode="darkBorder"
                      onClick={showOrderDeleteModal}
                    >
                      Отменить
                    </Button>
                  )}
                  {canDetachOrder && (
                    <Button
                      className="tracking-wider"
                      mode="darkBorder"
                      onClick={showDetachConfirmationModal}
                      disabled={isDetaching}
                    >
                      Снять заказ с компании
                    </Button>
                  )}
                  <Button
                    onClick={() => handleShowOnTheMap(order.addresses)}
                    className="tracking-wider"
                  >
                    Посмотреть на карте
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </QueryLayout>
  );
};
