import { FC } from "react";
import { useAppSelector } from "app/hooks";
import { Title } from "common/components/Title/Title";
import { List } from "common/components/List/List";

export const LoadedImages: FC = () => {
  const photos = useAppSelector((state) => state.ordering.photos);

  const photoArr =
    photos && Array.from(photos).map((file) => URL.createObjectURL(file));

  return (
    <div className="pointer-events-auto flex h-fit max-h-[100%] max-w-[400px] flex-col overflow-y-auto rounded bg-white p-[15px]">
      <Title className="mb-[15px] text-center">Загруженные фото</Title>
      <List>
        {photoArr?.map((image) => (
          <div key={image} className="h-[222px]">
            <img
              className="h-full w-full rounded object-cover"
              src={image}
              alt="current"
            />
          </div>
        ))}
      </List>
    </div>
  );
};
