import { FC, useState } from "react";
import { Button } from "common/components/Button/Button";
import { useGlobalModalContext } from "common/components/Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { SubTitle } from "common/components/Title/SubTitle";
import { useGetSearchRadQuery } from "services/appApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import {
  CustomSelect,
  RadiusSingleValue,
} from "common/components/Select/CustomSelect";
import { useUpdateOrderMutation } from "services/orderApi";

export const CompanyNotFound: FC<{
  currentRadiusId: number;
  orderId: number;
}> = ({ currentRadiusId, orderId }) => {
  const [updateOrder, { isLoading: updateLoading, error: updatingError }] =
    useUpdateOrderMutation();
  const {
    data: searchRad,
    isLoading: searchradLoading,
    error: searchRadError,
  } = useGetSearchRadQuery();

  const [currentSearchRadius, setCurrentSearchRadius] =
    useState<number>(currentRadiusId);

  const { showModal } = useGlobalModalContext();

  const showOrderCancelationModal = () => {
    showModal(MODAL_TYPES.ORDER_CANCELATION_MODAL, { orderId });
  };

  const searchRadOptions = searchRad?.map((rad) => ({
    value: rad.id,
    label: rad.name,
  }));

  return (
    <div>
      <SubTitle className="mb-[10px] text-center">
        Не удалось найти исполнителя
      </SubTitle>
      <span className={`mb-[15px] block text-sm text-gray`}>
        Вы можете раширить радиус поиска с перерасчетом стоимости или продолжить
        ждать, пока найдётся компания
      </span>
      <QueryLayout
        isLoading={searchradLoading}
        error={searchRadError || updatingError}
      >
        <CustomSelect
          className="mb-[15px] w-full"
          options={searchRadOptions}
          placeholder="Выберите опцию..."
          components={{ SingleValue: RadiusSingleValue }}
          value={searchRadOptions?.find((c) => c.value === currentSearchRadius)}
          onChange={(val) => {
            setCurrentSearchRadius(val.value);
          }}
          isSearchable={false}
        />
        <div className="grid grid-cols-2 gap-[10px]">
          <Button onClick={showOrderCancelationModal} mode="darkBorder">
            Отменить
          </Button>
          <Button
            disabled={currentRadiusId === currentSearchRadius || updateLoading}
            onClick={() =>
              updateOrder({ orderId, searchRadiusId: currentSearchRadius })
            }
          >
            Подтвердить
          </Button>
        </div>
      </QueryLayout>
    </div>
  );
};
