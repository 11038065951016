import { FC, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgListDashes } from "../../../assets/icons/SvgListDashes";
import { DatePicker } from "../../../common/components/DatePicker/DatePicker";
import { Route, Routes, useLocation } from "react-router-dom";
import { VehiclesTable } from "../tables/vehicles/VehiclesTable";
import { IconInput } from "../../../common/components/Input/IconInput";
import { SvgLoupe } from "../../../assets/icons/SvgLoupe";
import { AdminVehicleFilter } from "common/components/Filter/AdminVehicleFilter";
import {
  resetFilter,
  setDateFrom,
  setDateTo,
  setFilterVehicleType,
  setSearchText,
  setVehicleCategoryId,
} from "./vehiclesSlice";
import { VEHICLE_CATEGORY } from "common/utils/consts";

export const VehiclesPage: FC = () => {
  const location = useLocation();

  const vehicleTypeId = useAppSelector(
    (state) => state.administrator.vehicle.filters.vehicleTypeId
  );
  const vehicleCategoryId = useAppSelector(
    (state) => state.administrator.vehicle.filters.vehicleCategoryId
  );
  const searchText = useAppSelector(
    (state) => state.administrator.vehicle.filters.searchText
  );
  const dateFrom = useAppSelector(
    (state) => state.administrator.vehicle.filters.dateFrom
  );
  const dateTo = useAppSelector(
    (state) => state.administrator.vehicle.filters.dateTo
  );

  const dispatch = useAppDispatch();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const handleFilterVehicleTypeChange = (id: number) => {
    dispatch(setFilterVehicleType(id));
  };

  const handleVehicleCategoryIdChange = (id: number) => {
    dispatch(setVehicleCategoryId(id));
  };

  const handleSetSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchText(event.target.value));
  };

  const handleDisputesDateFromChange = (date: Nullable<Date>) => {
    dispatch(setDateFrom(date?.toISOString() || undefined));
  };

  const handleDisputesDateToChange = (date: Nullable<Date>) => {
    dispatch(setDateTo(date?.toISOString() || undefined));
  };

  const handleResetFilter = () => {
    dispatch(resetFilter());
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/vehicles/manipulators":
        handleVehicleCategoryIdChange(VEHICLE_CATEGORY.MANIPULATOR);
        break;
      case "/vehicles/crans":
        handleVehicleCategoryIdChange(VEHICLE_CATEGORY.CRAN);
        break;
      case "/vehicles/aerial-platforms":
        handleVehicleCategoryIdChange(VEHICLE_CATEGORY.AERIAL_PLATFORM);
        break;
      default:
        handleVehicleCategoryIdChange(VEHICLE_CATEGORY.MANIPULATOR);
        break;
    }
  }, [location.pathname]);

  return (
    <>
      {isFilterOpen && (
        <AdminVehicleFilter
          vehicleCategory={vehicleCategoryId}
          vehicleTypeId={vehicleTypeId}
          setIsFilterOpen={setIsFilterOpen}
          handleFilterVehicleTypeChange={handleFilterVehicleTypeChange}
          handleFilterReset={handleResetFilter}
        />
      )}
      <div className="grid w-full grid-cols-[1fr,245px,100px] gap-[10px]">
        <IconInput
          className="h-[35px] w-full"
          placeholder="Поиск по технике"
          icon={<SvgLoupe className="h-[15px] w-[15px] text-gray" />}
          value={searchText}
          onChange={handleSetSearchText}
        />
        <div>
          <DatePicker
            className="h-[35px] w-full"
            onStartDateChange={handleDisputesDateFromChange}
            onEndDateChange={handleDisputesDateToChange}
            placeholderText="Введите промежуток дат"
            isClearable={true}
          />
        </div>
        <button
          className="flex h-[35px] items-center justify-center rounded border border-transparent bg-lightWhiteGray px-[8px] text-sm font-medium
        outline-none transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary active:text-black"
          onClick={() => setIsFilterOpen((prev) => !prev)}
        >
          <SvgListDashes className="mr-[5px] h-[16px] w-[16px]" />
          Фильтр
        </button>
      </div>
      <div className="px-[5px]">
        <Routes>
          <Route
            path="/*"
            element={
              <VehiclesTable
                vehicleTypeId={vehicleTypeId}
                searchText={searchText}
                dateFrom={dateFrom}
                dateTo={dateTo}
                categoryId={vehicleCategoryId}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
};
